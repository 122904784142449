<template>
  <v-row justify="center">
    <v-col class="d-flex flex-column justify-center align-center pt-0 pb-6">
      <h4 class="text-h5 mb-2">
        {{ $t("private.peoplefinder.profile") }}
      </h4>

      <AvatarComponent :values="avatarValues" class="my-4" />
      <h2 class="text-h4 text-center mt-2 mb-5 px-4">
        {{ petName }}
      </h2>
      <label class="text-body-2">
        {{ $t("private.createaccount.pageTexts.gender") }}
      </label>
      <p>{{ getPetSex }}</p>

      <label class="text-body-2">
        {{ $t("private.createaccount.pageTexts.user") }}
      </label>
      <p class="text-subtitle-1">
        {{ getUserFullName }}
      </p>
    </v-col>
  </v-row>
</template>

<script>
import api from "@/services";
import { mapGetters } from "vuex";
import AvatarComponent from "@/components/avatar/AvatarComponent";

export default {
  name: "KinshipProfile",
  components: {
    AvatarComponent,
  },

  data() {
    return {
      idUser: null,
      id: null,
      userValues: null,
      avatarValues: null,
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },

  computed: {
    ...mapGetters(["getToken"]),

    petName() {
      const {
        userValues: { petName },
      } = this;
      return petName;
    },

    getUserFullName() {
      const {
        userValues: { firstName, lastName },
      } = this;
      return `${firstName} ${lastName}`;
    },

    getPetSex() {
      const {
        userValues: { sex },
      } = this;
      const keyCapitalized = sex.charAt(0).toUpperCase() + sex.slice(1);
      const composedKey = `private.createaccount.pageTexts.gender${keyCapitalized}`;
      return this.$i18n.t(composedKey);
    },
  },

  methods: {
    init() {
      const {
        $route: {
          params: { idUser },
        },
      } = this;

      this.idUser = idUser;
      this.getNewUserData(idUser);
    },

    async getNewUserData(userAccountId) {
      const { getToken: token } = this;

      try {
        const response = await api.getUserData(userAccountId, token);
        const { data } = response;
        if (data) {
          this.setUservalues(data);
        }
      } catch (e) {
        console.log(e);
      }
    },

    setUservalues(data) {
      //const {firstName, lastName, country} = data

      /* const { firstName, lastName, petName, profilePic, country,  sex } = data;*/
      this.userValues = data;
      this.setAvatarValues(data);
    },
    setAvatarValues(data) {
      const { petName, profilePic: image } = data;
      const imageExists = Boolean(image);
      const initials = petName.trim().slice(0, 1).toUpperCase();
      const size = "150";
      this.avatarValues = { size, initials, imageExists, image };
    },
  },
};
</script>
