<template>
  <svg style="display: none">
    <symbol id="breeds" viewBox="0 0 24 24" fill="currentColor">
      <path
        d="m19.7 2.1-4.4 4.4 3.3 3.3 1.1-1.1 1.1 1.1L23 7.6l-3.3-3.3V2.1M2.1 6.5 1 7.6l3.3 3.3v3.3l-1.1 1.1v6.6h2.2v-3.3l2.2-3.3h7.7v6.6h2.2v-11l-3.3-3.3-1.1 1.1H4.3L2.1 6.5Z"
      />
    </symbol>
    <symbol id="health" viewBox="0 0 24 24" fill="currentColor">
      <path
        d="M7.02 3.775C3.736 3.775 1 6.511 1 9.795c0 .547.11 1.094.219 1.642h4.487L7.13 7.715c.329-.875 1.642-.985 2.08 0l2.299 5.91.656-1.532c.11-.328.547-.656.985-.656h9.632c.11-.548.22-1.095.22-1.642 0-3.284-2.737-6.02-6.02-6.02-2.08 0-3.832.985-4.926 2.517C10.85 4.76 9.1 3.775 7.02 3.775m-4.925 9.304c-.657 0 8.756 8.209 8.756 8.209.766.656 1.423.656 2.189 0 1.094-.986 9.413-8.21 8.756-8.21h-8.318l-.985 2.518c-.438 1.094-1.642.985-2.08 0L8.114 9.795 7.02 12.312c-.11.438-.547.767-.985.767h-3.94Z"
      />
    </symbol>

    <symbol id="traits" viewBox="0 0 24 24" fill="currentColor">
      <path
        d="M18 4c-1.7 0-2.8.3-3.4.6C13.8 4.2 13 4 12 4s-1.9.2-2.6.6C8.8 4.3 7.8 4 6 4c-3 0-5 8-5 10 0 .8 1.3 1.6 3.1 1.9.6 2.2 3.7 4 7.4 4.1v-4.3c-.6-.4-1.5-1-1.5-1.7 0-1 2-1 2-1s2 0 2 1c0 .7-.9 1.4-1.5 1.7V20c3.7-.1 6.7-1.9 7.4-4.1 1.8-.3 3.1-1.1 3.1-1.9 0-2-2-10-5-10M4.2 13.9c-.5-.1-.9-.3-1.1-.4.2-2.8 2.2-7.1 3.1-7.5.5 0 .9.1 1.3.1-2.2 2.3-3.1 5.9-3.3 7.8M9 12c-.6 0-1-.5-1-1s.4-1 1-1 1 .5 1 1-.4 1-1 1m6 0c-.5 0-1-.5-1-1s.5-1 1-1 1 .5 1 1-.4 1-1 1m4.9 1.9c-.3-1.8-1.1-5.4-3.2-7.8.4-.1.8-.1 1.3-.1.9.4 2.8 4.7 3 7.5-.2.1-.6.3-1.1.4Z"
      />
    </symbol>
    <symbol id="wave" viewBox="0 0 2571 274" fill="currentColor">
      <path
        d="M1468.67 185.175c-77.03-.074-114.29-40.862-152.53-79.521C1262.8 51.73 1208.51 0 1101.54 0c-107 0-161.3 51.764-214.47 105.712-13.93 14.131-27.7 28.553-43.32 41.168-27.99 21.69-61.55 37.907-110.54 37.766v.516c-75.59-.801-114.44-41.077-153.61-79.299C524.88 52.482 469.93 1.521 367.2 1.521c-102.84 0-157.28 51.018-211.6 104.386-39.16 38.476-78.07 78.961-155.34 78.739L0 272.716c106.9.308 163.16-50.764 217.33-103.989 39.01-38.335 76-79.135 149.87-79.135 74 0 111.48 40.858 150.9 79.312 54.55 53.225 111.11 104.342 217.41 104.342 72.92 0 121.33-24.095 161.63-56.55 18.93-14.766 36.14-31.261 53.14-47.969 39.02-38.335 76-79.135 149.88-79.135 74 0 111.47 40.858 150.89 79.312 54.56 53.225 111.11 104.342 217.41 104.342l.21-.001.2.001c107.37 0 162.09-51.289 215.62-104.683 38.52-38.416 75.85-78.971 152.69-78.971v-1.504c74.89.938 111.5 41.286 149.3 79.503 53.32 53.901 107.851 105.655 215.346 105.655 107.366 0 162.083-51.289 215.621-104.683 38.521-38.416 75.85-78.971 152.683-78.971V1.521c-106.834 0-161.312 51.265-214.874 104.682-38.544 38.44-76.134 78.972-153.43 78.972-77.168 0-114.456-40.827-152.732-79.521C1995.75 51.73 1941.46 0 1834.5 0c-107.01 0-161.31 51.764-214.48 105.712-38.05 38.606-74.95 79.389-151.35 79.463Z"
      />
    </symbol>
    <symbol id="arrow" viewBox="0 0 24 24" fill="currentColor">
      <path
        d="M14.08 19.92h-4v-9l-3.5 3.5L4.16 12l7.92-7.92L20 12l-2.42 2.42-3.5-3.5v9Z"
      />
    </symbol>
  </svg>
</template>

<script>
export default {
  name: "IconsSvg",
};
</script>
