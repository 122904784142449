<template>
  <div class="contenedor-acceso-mykoko">
    <div
      v-if="$t('private.component.label.language.text').startsWith('private')"
      class="loader"
    />
    <div class="loading">
      <p style="font-weight: bold">
        {{ message }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import api from "@/services";

export default {
  name: "TokenComponent",
  data() {
    return {
      message: "Loading ...",
      tokenFromURl: null,
      token: null,
      code: null,
      language: null,
      userAccount: null,
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
  watch: {
    getLoginErrorMessage: function (before) {
      console.log(before);
      this.message = this.getLoginErrorMessage;
    },
    isLoggedIn: {
      handler: async function (now, before) {
        console.log(before);
        if (now) {
          await this.setUserLanguage();
          return this.$router.push("/");
        }
      },
      deep: true,
    },
  },
  async created() {
    this.tokenFromURl = this.$route.query.token;
    this.code = this.$route.query.code;
    this.language =
      this.$route.query.lang === undefined
        ? null
        : this.$route.query.lang.toLowerCase();

    if (this.tokenFromURl && this.code) {
      await this.loginWithToken();
    } else {
      if (!this.code) this.message = "Code missing";
      if (!this.tokenFromURl) this.message = "Token missing";
    }
  },
  methods: {
    async loginWithToken() {
      const { code, tokenFromURl } = this;
      const response = await api.loginFromToken(tokenFromURl, code);
      const { status } = response;
      if (status === 200) {
        this.$store.commit("SET_LOGIN_WITH_TOKEN");
        this.$store.commit("SET_TOKEN", response.headers.authorization);
        this.token = response.headers.authorization;
        await this.getUserKitData();
      }
    },
    async setUserLanguage() {
      let value = "en";
      if (this.language) {
        value = this.language;
      }
      this.$store.commit("SET_UI_LANGUAGE", value);
      await this.$store.dispatch("changeLanguage", { userLanguage: value });
      this.$vuetify.lang.current = value;
    },
    async getUserKitData() {
      const { token } = this;
      try {
        const response = await api.getUserKitData(token);
        const { status, data } = response;
        if (status === 200) {
          this.$store.commit("SET_USER_KIT_DATA", data);
          const { user } = data;
          this.userAccount = user;
          await this.getUserData();
        }
      } catch (e) {
        this.showErrors();
      }
    },
    async getUserData() {
      const { userAccount, token } = this;
      const { account_id } = userAccount;
      try {
        const response = await api.getUserData(account_id, token);
        const { status, data } = response;
        if (status === 200) {
          this.$store.commit("SET_USER_DATA", data);
          this.$store.commit("SET_LOGIN_SUCCESS", true);
          await this.setUserLanguage();
          await this.$router.push("/");
        }
      } catch (e) {
        console.log(e);
        this.showErrors();
      }
    },
  },
};
</script>
<style lang="scss">
.loading {
  min-height: 65vh;
  display: flex;
  justify-content: center;
  margin-top: 5%;
}
</style>
