<template>
  <v-container class="pb-8 pb-lg-12">
    <v-row justify="center">
      <v-col cols="12" class="pa-0">
        <KokoBreadcrumbs :items="items" />
      </v-col>
    </v-row>
    <v-row justify="center" @click="resetErrors" v-if="!showConfirm">
      <v-col cols="10" sm="6" md="5" lg="4">
        <h1 class="text-h5 mt-6 mb-3 line--1-1">
          {{ $t("private.pageforgot.title") }}
        </h1>
        <p class="text-caption line--1-2 mb-6">
          {{ $t("private.pageforgot.description") }}
        </p>
        <v-form
          :ref="formRef"
          @submit.prevent="submit"
          class="koko-form"
          lazy-validation
        >
          <TextField
            id="email"
            label="private.createaccount.pageTexts.email"
            :set-value="setValue"
            :rules="[requiredRules, emailRules]"
            v-model="email"
          />
          <aside v-if="loginError" class="mt-n2 mb-4">
            <p v-for="error in errors" :key="error" class="red--text pb-2">
              {{ $t(error) }}
            </p>
          </aside>

          <v-btn
            :loading="loading"
            :disabled="!completeForm"
            color="primary"
            depressed
            small
            class="mt-2 py-3 px-4"
            @click="sendEmail()"
          >
            {{ $t("private.pageforgot.button.resetpassword") }}
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
    <v-row justify="center" @click="resetErrors" v-if="showConfirm">
      <v-col cols="10" sm="6" v-if="!error" class="mb-2">
        <h4 class="mb-1 text-h6 line--1-2">
          {{ $t("private.pageforgot.emailsent") }}
        </h4>
        <h5 class="text-subtitle-1 font-weight-medium mb-5 line--1-2">
          {{ $t("private.pageforgot.checkyouremail") }}
        </h5>

        <i18n
          tag="p"
          class="font-weight-medium text-subtitle-1 line--1-3 hyphens--none mb-4"
          :path="emailDescriptionKey"
        >
          <template #email>
            <strong class="font-weight-bold">{{ email }}</strong>
          </template>
        </i18n>
        <p v-for="e in 3" :key="e" class="line--1-2 hyphens--none mb-2">
          {{ $t(`private.pageforgot.resultdescription.msg${e}`) }}
        </p>
      </v-col>
      <v-col cols="12" v-if="error" class="red--text my-6">
        <h1 class="text-h6">
          {{ $t("private.pageforgot.errortitle") }}
        </h1>
        <p v-for="(err, index) in errors" :key="index" class="mb-1">
          {{ $t(err) }}
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/services";
import { mapGetters } from "vuex";
import TextField from "@/components/form/TextField";
import KokoBreadcrumbs from "@/components/breadcrumbs/KokoBreadcrumbs";

export default {
  name: "ForgotForm",
  components: {
    TextField,
    KokoBreadcrumbs,
  },

  data() {
    return {
      emailDescriptionKey: "private.pageforgot.resultdescription.title",
      formRef: "forgotPasswordForm",
      token: null,
      userAccount: null,
      userData: null,
      loading: false,
      loginError: false,
      email: "",
      password: "",
      errors: [],
      showPassword: false,
      linkToResendActivation: false,
      submitted: false,
      showConfirm: false,
      completeForm: false,
      error: false,
    };
  },
  watch: {
    email(newValue) {
      const filledFields = newValue !== "";
      this.completeForm = filledFields && true;
    },
    getUILanguage() {
      const { formRef } = this;
      this.$refs[formRef].resetValidation();
    },
  },
  created() {
    if (this.isLoggedIn) {
      this.$router.push("/");
    }
  },
  computed: {
    ...mapGetters(["isLoggedIn", "getUILanguage"]),

    emailDescription() {
      return "private.pageforgot.resultdescription.title";
    },

    errorMail() {
      return this.$i18n.t("private.error.email");
    },
    errorRequired() {
      return this.$i18n.t("private.createaccount.pageTexts.requiredField");
    },
    emailRules() {
      return (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        this.errorMail;
    },
    requiredRules() {
      return (value) => !!value || this.errorRequired;
    },
    items() {
      const items = [
        {
          text: this.$i18n.t("private.pagehome.menus.init"),
          disabled: false,
          to: "/",
          id: "koko-breadcrumbs_item--1",
        },
        {
          text: this.$i18n.t("private.pageforgot.title"),
          disabled: true,
          id: "koko-breadcrumbs_item--2",
        },
      ];
      return items;
    },
  },
  methods: {
    setValue(value, id) {
      this[id] = value;
    },
    resetErrors() {
      this.errors = [];
    },
    async sendEmail() {
      const { formRef } = this;
      const form = this.$refs[formRef].$el;
      this.loading = true;
      const hasErrors = Boolean(
        [...form.querySelectorAll(".error--text")].length
      );

      if (hasErrors) {
        return this.showErrors();
      }

      try {
        const response = await api.forgotPassword(this.email);
        const { status } = response;
        if (status === 200) {
          this.showConfirm = true;
        }
      } catch (e) {
        this.showConfirm = true;
        this.errors.push("private.error.generic");
        this.error = true;
      }
    },
    showErrors() {
      this.loginError = true;
      this.errors = [this.$i18n.t("private.error.invalidcredentials")];
      this.loading = false;
    },
  },
};
</script>
