<template>
  <aside class="koko-footer__section-column">
    <section v-for="(section, s) in content" :key="`section_${s}`">
      <h4 class="koko-footer__section-title">
        {{ $t(section.sectionTitle) }}
      </h4>

      <article
        v-for="(item, i) in section.list"
        :key="`section_${s}-item_${i}`"
        class="px-2"
      >
        <FoterLinkItem :item="item" />

        <ul v-if="item.list" class="pl-2">
          <li
            v-for="(subitem, b) in item.list"
            :key="`section_${s}-item_${i}-subitem-${b}`"
            class="koko-footer__subsection"
          >
            <FoterLinkItem :item="subitem" />
          </li>
        </ul>
      </article>
    </section>
  </aside>
</template>
<script>
import FoterLinkItem from "./FoterLinkItem";

export default {
  name: "FooterColumn",
  components: {
    FoterLinkItem,
  },
  props: {
    content: {
      type: Array,
      default: () => null,
    },
  },
};
</script>
