<template>
  <article :class="className">
    <p class="koko-dna-connect__chat-text mb-1">{{ text }}</p>
    <p class="koko-dna-connect__chat-date mb-0">{{ date }}</p>
  </article>
</template>

<script>
export default {
  name: "DNAConnectChatItem",
  props: {
    message: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    userName() {
      const {
        message: { userName },
      } = this;
      return userName;
    },
    text() {
      const {
        message: { text },
      } = this;
      return text;
    },
    date() {
      const {
        message: { createdAt: date },
      } = this;

      const utcDate = new Date(date);
      return utcDate.toLocaleString();
    },
    className() {
      const {
        message: { incoming },
      } = this;

      const baseClass = "koko-dna-connect__chat-msg";
      return incoming ? `${baseClass} ${baseClass}--incoming` : baseClass;
    },
  },
};
</script>

<style>
.koko-dna-connect__chat-msg {
  color: hsl(235, 30%, 50%);
  padding: 1.1rem 0.2rem 0.9rem;
  text-align: end;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background-color: #f2f3ff;
}

.koko-dna-connect__chat-msg--incoming {
  text-align: start;
}

.koko-dna-connect__chat-msg:last-of-type {
  border-bottom-width: 0;
}
.koko-dna-connect__chat-text {
  font-size: 1.05rem;
}
.koko-dna-connect__chat-date {
  font-size: 0.8rem;
  opacity: 0.7;
  user-select: none;
}
</style>
