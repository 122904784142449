<template>
  <v-container class="text-center pt-8 pt-md-12">
    <ReActivateAccount />
    <v-btn depressed color="grey" class="ma-2 white--text px-8" :href="'/'">
      {{ $t("private.pagelogin.goBackKokogenetics") }}
    </v-btn>
  </v-container>
</template>
<script>
import ReActivateAccount from "@/components/reactivate/ReActivateAccount.vue";

export default {
  name: "ReActivateAccountView",
  components: {
    ReActivateAccount,
  },
};
</script>
