<template>
  <v-sheet class="tmg-o-report__sheet deep-purple lighten-5">
    <v-simple-table
      dense
      fixed-header
      class="tmg-o-report__table deep-purple lighten-5 deep-purple--text text--darken-1"
    >
      <template #default>
        <thead>
          <tr>
            <th class="py-1 font-weight-regular deep-purple--text" width="20%">
              {{ $t("private.common.gene") }}
            </th>
            <th class="py-1 font-weight-regular deep-purple--text" width="20%">
              {{ $t("private.common.chromosome") }}
            </th>
            <th class="py-1 font-weight-regular deep-purple--text" width="20%">
              {{ $t("private.common.position") }}
            </th>
            <th class="py-1 font-weight-regular deep-purple--text">
              {{ $t("private.common.interpretation") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(elem, i) in info" :key="`${i}_item.monogenicId`">
            <td class="py-2 font-weight-medium">
              {{ elem.genOrRegion }}
            </td>
            <td class="py-2 font-weight-medium">
              {{ elem.chromosome }}
            </td>
            <td class="py-2 font-weight-medium">
              {{ elem.position }}
            </td>
            <td class="py-2 font-weight-medium">
              {{ $t(techName(elem)) }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-sheet>
</template>
<script>
export default {
  name: "TableTechReport",
  props: {
    info: {
      type: Array,
      default: () => null,
    },
    type: {
      type: String,
      default: () => null,
    },
    idKey: {
      type: String,
      default: () => null,
    },
  },

  data() {
    return {};
  },

  methods: {
    techName(elem) {
      const { type, idKey } = this;
      const { idReport, name } = elem;
      const itemId = elem[idKey];
      const keyToTranslate = `technicalreport.${type}.${itemId}.${idReport}.${name}.result`;
      return this.$i18n.t(keyToTranslate);
    },
  },
};
</script>
<style lang="scss">
.tmg-o-report__table .v-data-table__wrapper {
  overflow-x: hidden;
  max-height: 420px;
}

.tmg-o-report__table.v-data-table--dense .v-data-table__wrapper {
  max-height: 253px;
}

.theme--light.v-data-table.v-data-table--fixed-header.tmg-o-report__table
  thead
  th {
  background: #ede7f6;
  box-shadow: inset 0 -1px 0 hsla(260, 54%, 45%, 0.2);
}

.v-data-table--fixed-header.tmg-o-report__table > .v-data-table__wrapper {
  scrollbar-color: #5e35b1 hsl(264, 45.5%, 85%);
  scrollbar-width: thin;
}

.v-data-table--fixed-header.tmg-o-report__table
  > .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background-color: #5e35b1;
  border: none;
  border-radius: 3px;
}
.v-data-table--fixed-header.tmg-o-report__table
  > .v-data-table__wrapper::-webkit-scrollbar-track {
  background: hsl(264, 45.5%, 85%);
  border-radius: 3px;
}

.v-data-table--fixed-header.tmg-o-report__table
  > .v-data-table__wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
</style>
