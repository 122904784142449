import Vue from "vue";
import store from "@/store";
import VueRouter from "vue-router";
import PrincipalLayout from "@/layouts/PrincipalLayout";
import LoginLayout from "@/layouts/LoginLayout";
import LoginView from "@/views/login/LoginView";
import HomeView from "@/views/home/HomeView";
import RegisterView from "@/views/register/RegisterView";
import ForgotView from "@/views/forgotpassword/ForgotView";
import ResetPasswordView from "@/views/reset/ResetPasswordView";
import SettingsView from "@/views/settings/SettingsView";
import UpdatePasswordView from "@/views/update/UpdatePasswordView";
import UpdateEmailView from "@/views/update/UpdateEmailView";
import UpdateProfileView from "@/views/update/UpdateProfileView";
import ActivateView from "@/views/activateaccount/ActivateView.vue";
import HealthView from "@/views/health/HealthView.vue";
import HealthViewDetail from "@/views/health/HealthViewDetail.vue";
import TraitsView from "@/views/traits/TraitsView.vue";
import TraitsViewDetail from "@/views/traits/TraitsViewDetail.vue";
import BreedView from "@/views/breed/BreedView.vue";
import ProfileView from "@/views/profile/ProfileView.vue";
import KinshipProfileView from "@/views/profile/KinshipProfileView.vue";
import WelcomeView from "@/views/welcome/WelcomeView.vue";
import Token from "@/components/token/Token.vue";
import ReActivateAccountView from "@/views/reactivate/ReActivateAccountView.vue";
import DNAConnectView from "@/views/dnaconnect/DNAConnectView.vue";
import DeleteAccountView from "@/views/delete/DeleteAccountView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: PrincipalLayout,
    children: [
      {
        path: "",
        name: "home",
        component: HomeView,
      },
    ],
    meta: { title: "Home", requiresAuth: true },
  },
  {
    path: "/login",
    component: LoginLayout,
    children: [
      {
        path: "",
        name: "login",
        component: LoginView,
      },
    ],
    meta: { title: "Login Account" },
  },
  {
    path: "/resend-activate-email",
    component: LoginLayout,
    children: [
      {
        path: "",
        name: "ReActivateAccount",
        component: ReActivateAccountView,
      },
    ],
    meta: { title: "Login Account" },
  },
  {
    path: "/dna-connect",
    component: PrincipalLayout,
    children: [
      {
        path: "",
        name: "dna-connect",
        component: DNAConnectView,
      },
    ],
    meta: { title: "DNA Connect" },
  },
  {
    path: "/welcome",
    component: LoginLayout,
    children: [
      {
        path: "",
        name: "welcome",
        component: WelcomeView,
      },
    ],
    meta: { title: "Welcome page" },
  },
  {
    path: "/new-account",
    component: LoginLayout,
    children: [
      {
        path: "",
        name: "register",
        component: RegisterView,
      },
    ],
    meta: { title: "Create Account" },
  },
  {
    path: "/new-account/welcome",
    component: LoginLayout,
    children: [
      {
        path: "",
        name: "register-welcome",
        component: RegisterView,
      },
    ],
    meta: { title: "Create Account" },
  },
  {
    path: "/activate-account/:token",
    component: LoginLayout,
    props: true,
    children: [
      {
        path: "",
        name: "activate",
        props: true,
        component: ActivateView,
      },
    ],
    meta: { title: "Activate Account" },
  },
  {
    path: "/forgot-password",
    component: LoginLayout,
    children: [
      {
        path: "",
        name: "forgotPassword",
        component: ForgotView,
      },
    ],
    meta: { title: "Forgot Password" },
  },
  {
    path: "/update-password",
    component: PrincipalLayout,
    props: true,
    children: [
      {
        path: "",
        name: "updatePassword",
        props: true,
        component: UpdatePasswordView,
      },
    ],
    meta: { title: "Change your password", requiresAuth: true },
  },
  {
    path: "/delete-account",
    component: PrincipalLayout,
    props: true,
    children: [
      {
        path: "",
        name: "deleteAccount",
        props: true,
        component: DeleteAccountView,
      },
    ],
    meta: { title: "Change your password", requiresAuth: true },
  },
  {
    path: "/update-email",
    component: PrincipalLayout,
    props: true,
    children: [
      {
        path: "",
        name: "updateEmail",
        props: true,
        component: UpdateEmailView,
      },
    ],
    meta: { title: "Change your email", requiresAuth: true },
  },

  {
    path: "/update-profile",
    component: PrincipalLayout,
    props: true,
    children: [
      {
        path: "",
        name: "updateProfile",
        props: true,
        component: UpdateProfileView,
      },
    ],
    meta: { title: "Change your profile", requiresAuth: true },
  },

  {
    path: "/update-language",
    component: PrincipalLayout,
    props: true,
    children: [
      {
        path: "",
        name: "updateLanguage",
        props: true,
        component: UpdateProfileView,
      },
    ],
    meta: { title: "Change your language", requiresAuth: true },
  },

  {
    path: "/reset-password/:passwordresetcode",
    component: LoginLayout,
    props: true,
    children: [
      {
        path: "",
        name: "resetPassword",
        props: true,
        component: ResetPasswordView,
      },
    ],
    meta: { title: "Change your password" },
  },
  {
    path: "/settings",
    component: PrincipalLayout,
    children: [
      {
        path: "",
        name: "settings",
        component: SettingsView,
      },
    ],
    meta: { title: "Settings", requiresAuth: true },
  },
  {
    path: "/profile/user/:idUser",
    component: PrincipalLayout,
    props: true,
    children: [
      {
        path: "",
        name: "kinshipProfile",
        props: true,
        component: KinshipProfileView,
      },
    ],
    meta: { title: "Profile", requiresAuth: true },
  },
  {
    path: "/profile",
    component: PrincipalLayout,
    children: [
      {
        path: "",
        name: "profile",
        props: true,
        component: ProfileView,
      },
    ],
    meta: { title: "Profile", requiresAuth: true },
  },
  {
    path: "/results",
    component: PrincipalLayout,
    redirect: "/",
  },
  {
    path: "/results/health",
    component: PrincipalLayout,
    children: [
      {
        path: "",
        name: "health",
        component: HealthView,
      },
    ],
    meta: { title: "Health results", requiresAuth: true },
  },
  {
    path: "/results/health/:idHealth",
    component: PrincipalLayout,
    props: true,
    children: [
      {
        path: "",
        name: "healthDetail",
        props: true,
        component: HealthViewDetail,
      },
    ],
    meta: { title: "Health results detail", requiresAuth: true },
  },
  {
    path: "/results/breed",
    component: PrincipalLayout,
    children: [
      {
        path: "",
        name: "breed",
        component: BreedView,
      },
    ],
    meta: { title: "Breed results", requiresAuth: true },
  },
  {
    path: "/results/traits",
    component: PrincipalLayout,
    children: [
      {
        path: "",
        name: "traits",
        component: TraitsView,
      },
    ],
    meta: { title: "Traits results", requiresAuth: true },
  },
  {
    path: "/results/traits/:idTrait",
    component: PrincipalLayout,
    props: true,
    children: [
      {
        path: "",
        name: "traitsDetail",
        props: true,
        component: TraitsViewDetail,
      },
    ],
    meta: { title: "traits results detail", requiresAuth: true },
  },
  {
    path: "/token",
    component: LoginLayout,
    children: [
      {
        path: "",
        name: "token",
        component: Token,
      },
    ],
    meta: { title: "Token" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to) => {
  const DEFAULT_TITLE = "koko genetics";
  document.title = to.meta.title || DEFAULT_TITLE;
});

router.beforeEach((to, from, next) => {
  const { getAvatarUpdated, isLoggedIn, getUserKitData } = store.getters;
  if (from.name === "updateProfile" && isLoggedIn && !getAvatarUpdated) {
    const {
      user: { profilePic },
    } = getUserKitData;

    store.commit("SET_IMAGE_AVATAR", profilePic);
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (to.name === "register" && !isLoggedIn) {
      return next();
    }
    if (to.name === "login" && !isLoggedIn) {
      return next();
    }
    if (to.name !== "login" && !isLoggedIn) {
      return next({ name: "login" });
    }
    return next();
  }
  next();
});

export default router;
