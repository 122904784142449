<template>
  <v-container class="text-center pt-8 pt-md-12">
    <ResetPasswordForm :passwordresetcode="passwordresetcode" />
    <v-btn depressed color="grey" class="ma-2 white--text px-8" :href="'/'">
      {{ $t("private.pagelogin.goBackKokogenetics") }}
    </v-btn>
  </v-container>
</template>
<script>
import ResetPasswordForm from "@/components/reset/ResetPasswordForm";

export default {
  name: "ResetPasswordView",
  props: ["passwordresetcode"],
  components: {
    ResetPasswordForm,
  },
};
</script>
