<template>
  <v-menu
    v-if="showMenu"
    left
    offset-y
    eager
    :max-width="menuWidth"
    content-class="koko-dna__menu-content"
    class="koko-dna__menu"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
        :title="menuTitle"
        class="mt-1 ml-n1"
        :id="openMenuButtonID"
      >
        <v-icon> mdi-dots-vertical </v-icon>
      </v-btn>
    </template>

    <v-list dense class="py-0">
      <v-list-item
        v-for="(menuItem, e) in items"
        :key="e"
        :id="menuItemID(menuItem)"
        class="koko-dna__menu-item pb-1 px-3"
        @click="menuItem.action"
      >
        <v-list-item-title>
          <v-icon left small :class="menuItem.iconClass">
            {{ menuItem.icon }}
          </v-icon>
          <span>{{ menuItem.title }}</span>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import api from "@/services";
import { mapGetters } from "vuex";
export default {
  name: "DNAConnectItemMenu",
  props: {
    menuProps: {
      type: Object,
      default: () => null,
    },
  },
  emits: ["update-blocked"],

  data() {
    return {};
  },

  computed: {
    ...mapGetters(["getToken", "getUserKitData"]),

    openMenuButtonID() {
      const { menuID } = this;
      const commonID = "koko-dna__item-menu-open--";
      return `${commonID}${menuID}`;
    },

    menuID() {
      const {
        menuProps: { menuID },
      } = this;
      return menuID;
    },

    showMenu() {
      const {
        menuProps: { showMenu },
      } = this;
      return showMenu;
    },

    menuTitle() {
      return this.$i18n.t("private.pagelistkit.actions");
    },
    menuWidth() {
      const {
        menuProps: {
          item: { blocked },
        },
      } = this;
      return blocked ? 120 : 150;
    },

    items() {
      const {
        showProfile,
        toggleBlockedUser,
        showChat,
        menuProps: {
          item: { blocked },
        },
      } = this;
      const profile = {
        title: this.$i18n.t("private.peoplefinder.profile"),
        icon: "mdi-account",
        action: showProfile,
      };

      const blockUser = {
        title: this.$i18n.t(`private.peoplefinder.block`),
        icon: "mdi-lock",
        iconClass: "mr-1 mt-n1",
        action: toggleBlockedUser,
      };
      const unlockUser = {
        title: this.$i18n.t(`private.form.unlock`),
        icon: "mdi-lock-open-variant",
        iconClass: "mr-1 mt-n1",
        action: toggleBlockedUser,
      };
      const sendMessage = {
        title: this.$i18n.t(`private.peoplefinder.sendmessage.name`),
        icon: "mdi-send",
        iconClass: "mr-1",
        action: showChat,
      };

      return blocked ? [unlockUser] : [profile, sendMessage, blockUser];
    },
  },

  methods: {
    menuItemID(menuItem) {
      const { menuID } = this;
      const { icon } = menuItem;
      const specificID = icon.split("-").pop();
      const commonID = "koko-dna__item-menu";
      return `${commonID}-${specificID}--${menuID}`;
    },

    showChat() {
      const {
        menuProps: { openChat },
      } = this;

      openChat(true);
    },

    showProfile() {
      const {
        menuProps: { openProfile },
      } = this;
      openProfile(true);
    },

    async toggleBlockedUser() {
      const {
        menuProps: {
          item: { id, blocked },
        },
      } = this;

      const {
        user: { account_id },
      } = this.getUserKitData;
      const token = this.getToken;
      const userId = Number(account_id);
      const blockedUser = Number(id);
      const value = !blocked;

      try {
        const response = await api.changeLockedUser({
          userId,
          blockedUser,
          value,
          token,
        });
        const { status } = response;
        if (status === 200) {
          this.$emit("update-blocked");
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
<style>
.v-list--dense .v-list-item.koko-dna__menu-item .v-list-item__title {
  line-height: 1.5;
  margin-bottom: -6px;
}

.koko-app .v-menu__content.koko-dna__menu-content {
  box-shadow: 0 0.5px 3px rgba(0, 0, 0, 0.05), 1px 2px 4px rgba(0, 0, 0, 0.1),
    3px 4px 8px rgba(0, 0, 0, 0.1);
}
</style>
