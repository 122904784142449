<template>
  <v-card class="mb-6 koko-settings__card">
    <v-card-title class="text-subtitle-1 px-5 mb-1 pt-3">
      {{ $t("private.header.menus.myAccount") }}
    </v-card-title>
    <v-card-subtitle class="px-5 pb-2 line--1-2">
      {{ userEmail }}
    </v-card-subtitle>
    <v-card-text class="px-5 py-2">
      <v-btn
        color="primary"
        depressed
        :to="`/update-email`"
        small
        class="mr-4 mb-4 overflow-ellipsis"
        id="koko-settings__btn--update-email"
      >
        {{ $t("private.account.changemail") }}
      </v-btn>

      <v-btn
        color="primary"
        depressed
        :to="`/update-password`"
        small
        class="mr-4 mb-4 overflow-ellipsis"
        id="koko-settings__btn--update-password"
      >
        {{ $t("private.pagechange.button.changepassword") }}
      </v-btn>

      <v-btn
        color="error"
        depressed
        :to="`/delete-account`"
        small
        class="mb-4 overflow-ellipsis"
        id="koko-settings__btn--delete-account"
      >
        {{ $t("private.form.deleteAccount") }}
      </v-btn>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "SettingsAccountCard",
  computed: {
    ...mapGetters(["getUserKitData"]),

    userEmail() {
      const {
        user: { email },
      } = this.getUserKitData;
      return email;
    },
  },
};
</script>
