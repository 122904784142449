<template>
  <figure :class="avatarClass" :style="avatarStyles">
    <figcaption class="koko-avatar__caption white--text" v-if="!hasImage">
      {{ getInitials }}
    </figcaption>
  </figure>
</template>

<script>
export default {
  name: "AvatarComponent",
  props: {
    values: {
      type: Object,
      default: () => ({
        size: "40",
        noShadow: false,
        imageExists: false,
        initials: "A",
        image: "",
        shadowColor: "rgba(13, 13, 63, 0.25)",
      }),
    },
  },

  data: () => ({
    defaultShadowColor: "rgba(13, 13, 63, 0.25)",
  }),

  computed: {
    hasImage() {
      const {
        values: { imageExists },
      } = this;
      return imageExists;
    },

    getInitials() {
      const {
        values: { initials },
      } = this;
      return initials;
    },

    avatarStyles() {
      const {
        defaultShadowColor,
        values: { size, image, shadowColor },
      } = this;
      const SHADOW_COLOR = shadowColor || defaultShadowColor;

      return {
        "--size": `${size}`,
        "--bg": `url(${image})`,
        "--avatar-shadow-color": `${SHADOW_COLOR}`,
      };
    },

    avatarClass() {
      const {
        hasImage: bg,
        values: { noShadow: noshadow },
      } = this;
      const defaultClass = "koko-avatar";
      const styleOptions = { bg, noshadow };
      const aditionalClasses = Object.entries(styleOptions)
        .filter((e) => e[1])
        .reduce((i, a) => [...i, `${defaultClass}--${a[0]}`], []);
      return [defaultClass, ...aditionalClasses].join(" ");
    },
  },
};
</script>
