<template>
  <v-list class="py-0">
    <v-list-item-group
      v-for="(item, m) in items"
      :key="`item_${m}`"
      :tabindex="m"
      color="primary"
    >
      <v-divider v-if="item.divider" class="mb-2" />
      <v-list-item
        v-if="hasNotSubmenu(item)"
        :to="item.to"
        :id="itemId(item)"
        exact
      >
        <v-list-item-title>
          {{ $t(item.title) }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item-group v-if="hasSubmenu(item)">
        <p class="text-subtitle-1 text--secondary px-4 py-2 mb-0">
          {{ $t(item.title) }}
        </p>
        <template v-if="hasResults">
          <v-list-item
            v-for="(submenu, s) in item.subMenu"
            :key="`sub_${s}`"
            :to="submenu.to"
            :id="itemId(submenu)"
            exact
          >
            <v-list-item-title class="px-2">
              {{ $t(submenu.title) }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list-item-group>
    <v-list-item-group>
      <v-list-item @click="logout()" id="koko-menu-mobile__item--logout">
        {{ $t("private.pagehome.menus.exit") }}
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>
<script>
import { mapGetters } from "vuex";
import menuItems from "@/data/items.json";

export default {
  name: "MobileMenu",

  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapGetters(["getUserKitData"]),

    hasResults() {
      const {
        user: { hasResults },
      } = this.getUserKitData;
      return String(hasResults) === "true";
    },
    items() {
      return this.isAdvanced
        ? menuItems["menuAdvanced"]
        : menuItems["menuStarter"];
    },
    isAdvanced() {
      const {
        user: { typeKitId, typeKitName },
      } = this.getUserKitData;
      return typeKitId === "1" && typeKitName === "Advanced";
    },
  },
  methods: {
    itemId(item) {
      const { title } = item;
      const commonID = "koko-menu-mobile__item";
      const specificID = title
        .split(".")
        .pop()
        .split(" ")
        .join("")
        .toLowerCase();
      return `${commonID}--${specificID}`;
    },
    hasSubmenu(item) {
      return item.subMenu && !item.divider && this.hasResults;
    },
    hasNotSubmenu(item) {
      return !item.subMenu && !item.divider;
    },

    getClass(item) {
      if (item.divider) {
        return "koko-item--divider";
      }
      return "koko-item";
    },
    logout() {
      this.$store.commit("SET_LOGOUT");
      this.$router.push("/login");
    },
  },
};
</script>
