<template>
  <v-card class="mb-6 koko-settings__card">
    <v-card-title class="text-subtitle-1 px-5 mb-1 pt-3">
      {{ $t("private.peoplefinder.profile") }}
    </v-card-title>
    <v-card-subtitle class="px-5 pb-2 line--1-2">
      {{ userName }}
    </v-card-subtitle>
    <v-card-text class="px-5 pt-2 pb-4">
      <v-btn
        color="primary"
        depressed
        :to="`/update-profile`"
        small
        class="mb-2 overflow-ellipsis"
        id="koko-settings__btn--edit-profile"
      >
        {{ $t("private.peoplefinder.editprofile") }}
      </v-btn>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "SettingsProfileCard",
  computed: {
    ...mapGetters(["getUserData"]),
    userName() {
      const { firstName, lastName } = this.getUserData;
      return `${firstName} ${lastName} `;
    },
  },
};
</script>
