<template>
  <v-container class="text-center">
    <ForgotForm />
  </v-container>
</template>
<script>
import ForgotForm from "@/components/forgotpassword/ForgotForm.vue";

export default {
  name: "ForgotView",
  components: {
    ForgotForm,
  },
};
</script>
