<template>
  <section class="koko-details__container">
    <details :open="open" class="koko-details mb-2">
      <summary class="koko-summary koko-summary__title">
        {{ $t(item.name) }}
      </summary>
      <section>
        <details
          v-for="(country, i) in countries"
          :key="`country${i}`"
          class="koko-details mb-2"
        >
          <summary class="koko-summary pl-8">
            {{ $t(country.name) }}
          </summary>
          <aside class="koko-c-details__content">
            <p
              v-for="(child, j) in country.data"
              :key="`section_${i}_child_${j}`"
            >
              <a v-if="child.href" :href="child.href">{{ child.name }}</a>
              <span v-if="!child.href">{{ $t(child.name) }}</span>
            </p>
          </aside>
        </details>
      </section>
    </details>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "FooterDetails",
  props: {
    item: {
      type: Object,
      default: () => null,
    },
    list: {
      type: Array,
      default: () => null,
    },
  },
  data: () => ({
    open: null,
  }),
  computed: {
    ...mapGetters(["getUILanguage"]),

    countries() {
      const {
        item: { countries },
      } = this;
      return countries;
    },
  },
  watch: {
    getUILanguage() {
      this.open = null;
    },
  },
};
</script>
