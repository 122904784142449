<template>
  <v-card class="mb-6 koko-settings__card">
    <v-card-title class="text-subtitle-1 px-5 mb-1 pt-3">
      {{ $t("private.peoplefinder.preferences.name") }}
    </v-card-title>
    <v-card-subtitle class="px-5 pb-2">
      {{ $t("private.pageeditaccount.updateLanguage") }}
    </v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="8" class="px-4 pt-4 pb-3 mb-0">
          <LanguageSelector
            id="koko-settings__select--update-language"
            :dense="false"
            :show-label="false"
            :set-value="updateLanguage"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import api from "@/services";
import { mapGetters } from "vuex";
import LanguageSelector from "@/components/language/LanguageSelector";

export default {
  name: "SettingsLanguageCard",
  components: {
    LanguageSelector,
  },
  data: () => ({
    languages: [],
    locale: "es",
  }),

  computed: {
    ...mapGetters([
      "getUserData",
      "getUserKitData",
      "getUILanguage",
      "getToken",
    ]),
  },

  methods: {
    setNewLanguage(newLanguage) {
      this.$store.commit("SET_UI_LANGUAGE", newLanguage);
      this.$store.dispatch("changeLanguage", { userLanguage: newLanguage });
      this.$vuetify.lang.current = newLanguage;
    },

    async updateLanguage(newLanguage) {
      const token = this.getToken;
      const DEMO_KIT = "KGEUNAAAAAA1";

      const {
        user: { account_id, kit },
      } = this.getUserKitData;

      const accountUserId = Number(account_id);

      if (kit === DEMO_KIT) {
        this.setNewLanguage(newLanguage);
        return false;
      }

      try {
        const response = await api.changeLanguage(
          accountUserId,
          newLanguage,
          token
        );
        const { status } = response;
        if (status === 200) {
          this.setNewLanguage(newLanguage);
        }
      } catch (e) {
        console.log(e);
        this.showErrors();
      }
      return true;
    },
  },
};
</script>
