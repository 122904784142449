<template>
  <v-container>
    <UpdatePassword />
  </v-container>
</template>
<script>
import UpdatePassword from "@/components/update/UpdatePassword.vue";

export default {
  name: "UpdatePasswordView",

  components: {
    UpdatePassword,
  },
};
</script>
