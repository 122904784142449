<template>
  <v-breadcrumbs :items="items">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        :to="item.to"
        :href="item.href"
        :disabled="item.disabled"
        :id="item.id"
      >
        {{ item.text }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>
<script>
export default {
  name: "KokoBreadcrumbs",
  props: {
    items: {
      type: Array,
      default: () => null,
    },
  },
};
</script>
