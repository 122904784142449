<template>
  <v-container class="d-none d-md-flex koko-header__container--desktop">
    <aside class="koko-header px-4 px-lg-3 pt-lg-6">
      <KokoLogo class="mr-6" />
      <section class="koko-header__menu--desktop">
        <nav class="mb-n1" v-if="mainNav">
          <v-tabs
            v-model="selectedTab"
            hide-slider
            grow
            class="koko-header__tabs--desktop"
          >
            <template v-for="item in mainNav">
              <template v-if="!item.subMenu">
                <v-tab :key="item.id" :to="item.to" :id="itemId(item)">
                  {{ $t(item.title) }}
                </v-tab>
              </template>
              <template v-if="item.subMenu">
                <v-menu
                  :key="item.id"
                  content-class="koko-header__menu"
                  open-on-hover
                  offset-y
                >
                  <template #activator="{ on, attrs }">
                    <v-tab
                      :key="item.id"
                      v-bind="attrs"
                      :to="item.to"
                      :ripple="false"
                      id="koko-menu__item--results"
                      @click.prevent.stop.capture
                      v-on="on"
                    >
                      {{ $t(item.title) }}
                    </v-tab>
                  </template>
                  <v-list nav v-if="hasResults">
                    <v-list-item-group v-model="selectedItem" color="primary">
                      <v-list-item
                        v-for="sub in item.subMenu"
                        :key="sub.id"
                        :to="sub.to"
                        :href="sub.href"
                        :target="sub.target"
                        :disabled="sub.disabled"
                        exact
                        :id="itemId(sub)"
                        @click="closeDrawer(sub.parentId)"
                      >
                        <v-list-item-title link>
                          {{ $t(sub.title) }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <v-list nav v-if="!hasResults">
                    <v-list-item disabled>
                      <v-list-item-title>
                        {{ $t(`private.pagehome.noResults.title`) }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </template>
          </v-tabs>
        </nav>
        <DesktopMenu :menu-list="secondNav" />
      </section>
    </aside>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import menuItems from "@/data/items.json";
import DesktopMenu from "@/components/menu/DesktopMenu";
import KokoLogo from "@/components/logo/KokoLogo";

export default {
  name: "DesktopHeader",
  components: {
    DesktopMenu,
    KokoLogo,
  },
  data() {
    return {
      mainNav: null,
      secondNav: null,
      userLanguage: null,
      drawer: false,
      notificationRefresh: 5000,
      path: this.$route.path,
      selectedTab: null,
      selectedItem: null,
      itemNoResults: [
        {
          id: 21,
          parentId: 2,
          title: "private.pagehome.menus.errorNoResults",
          disabled: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getUserKitData"]),

    menuList() {
      return this.isAdvanced
        ? menuItems["menuAdvanced"]
        : menuItems["menuStarter"];
    },
    isAdvanced() {
      const {
        user: { typeKitId, typeKitName },
      } = this.getUserKitData;
      return typeKitId === "1" && typeKitName === "Advanced";
    },

    hasResults() {
      const {
        user: { hasResults },
      } = this.getUserKitData;
      return String(hasResults) === "true";
    },
  },

  created() {
    const {
      user: { defaultLanguage },
    } = this.getUserKitData;
    this.$i18n.locale = defaultLanguage;
  },
  mounted() {
    this.$nextTick(() => {
      this.setNav();
    });
  },

  methods: {
    itemId(item) {
      const { title } = item;
      const commonID = "koko-menu-desktop__item--";
      const specificID = title
        .split(".")
        .pop()
        .split(" ")
        .join("")
        .toLowerCase();
      return `${commonID}${specificID}`;
    },

    setNav() {
      const list = JSON.parse(JSON.stringify(this.menuList));
      const mainNav = list.filter((e) => e.isMainNav);
      const secondNav = list
        .filter((item) => item.isMainNav === false)
        .map((e) => {
          if (e.profile) {
            e.to = `/profile`;
          }
          return e;
        });

      this.secondNav = secondNav;
      this.mainNav = mainNav;
    },

    toggleMenuItem(item) {
      return !item.subMenu;
    },

    closeDrawer(itemId) {
      this.drawer = false;
      if (itemId) this.selectedTab = itemId - 1;
    },
    openDrawer() {
      this.drawer = true;
    },
  },
};
</script>
