<template>
  <v-footer class="koko-footer mt-8">
    <SocialIcons />
    <FooterLinks />
    <v-container fluid class="blue-grey lighten-4 pb-1">
      <v-container class="pb-2">
        <v-row justify="center">
          <v-col cols="12" md="6">
            <FooterMessage />
          </v-col>
          <v-col cols="12" md="6">
            <FooterAdresses />
          </v-col>
        </v-row>
        <FooterCertificates />
      </v-container>
    </v-container>
    <v-container fluid class="blue-grey lighten-3">
      <v-row justify="center">
        <v-col class="text-center mt-6">
          <v-btn
            v-for="(icon, i) in socialLinks"
            :key="`icon_${i}`"
            class="mx-2 my-1"
            :id="itemID(icon)"
            :href="icon.url"
            icon
          >
            <svg xmlns="http://www.w3.org/2000/svg" class="tmg-c-social__icon">
              <use :xlink:href="icon.img" />
            </svg>
          </v-btn>
        </v-col>
      </v-row>

      <p class="text-caption text-center mt-4">
        {{ new Date().getFullYear() }} koko genetics &trade;
      </p>
    </v-container>
  </v-footer>
</template>
<script>
import FooterLinks from "./FooterLinks";
import FooterAdresses from "./FooterAdresses";
import FooterMessage from "./FooterMessage";
import FooterCertificates from "./FooterCertificates";
import SocialIcons from "./SocialIcons";

export default {
  name: "PageFooter",
  components: {
    FooterLinks,
    FooterAdresses,
    FooterMessage,
    FooterCertificates,
    SocialIcons,
  },
  data: () => ({
    socialLinks: [
      {
        img: "#facebook",
        url: "https://www.facebook.com/kokogenetics",
      },
      {
        img: "#twitter",
        url: "https://twitter.com/kokogenetics",
      },
      {
        img: "#instagram",
        url: "https://www.instagram.com/kokogenetics/",
      },
      {
        img: "#linkedin",
        url: "https://www.linkedin.com/company/koko-genetics/",
      },
    ],
  }),
  methods: {
    itemID(item) {
      const { img } = item;
      const commonID = "koko-footer__social";
      const specificID = img.split("#").pop();
      return `${commonID}--${specificID}`;
    },
  },
};
</script>
