<template>
  <!-- <v-data-iterator
    disable-pagination
    :items="items"
    :item-key="`item-${itemKey}`"
    hide-default-footer
    class="tmg-o-imputation__iterator"
  >
    <template #default="props"> -->

  <v-row class="pa-4 mb-2" align="center" justify="center">
    <v-col
      v-for="(item, i) in itemsList"
      :key="item.itemId"
      :ripple="false"
      class="pa-0"
      cols="12"
      :id="itemID(i)"
    >
      <v-card
        :ripple="{ class: `primary--text` }"
        :class="hasSelectedItems()"
        outlined
        tile
        @click="onClick(item)"
      >
        <p :class="actionsClass">
          {{ item.name }}
        </p>
        <p v-if="hasStatus(item)" class="mb-0 mt-n3 ml-n1">
          <v-chip x-small :class="itemStatusClass(item)">
            {{ itemStatusName(item) }}
          </v-chip>
        </p>

        <p :class="itemActionsClass(item)">
          {{ $t(item.actions) }}
          <v-icon dense> mdi-chevron-right </v-icon>
        </p>
      </v-card>
    </v-col>
  </v-row>
  <!--   </template>
  </v-data-iterator> -->
</template>
<script>
export default {
  name: "MobileItemsTable",
  props: {
    items: {
      type: Array,
      default: () => null,
    },
    tableProps: {
      type: Object,
      default: () => null,
    },
    onClick: {
      type: Function,
      default: () => null,
    },
    onSelect: {
      type: Function,
      default: () => null,
    },
  },
  data: () => ({
    itemsList: null,
    expectedStatus: ["CREATED"],
    commonID: "koko-results-mobile__table--",
  }),

  computed: {
    itemKey() {
      const { itemKey } = this.tableProps;
      return itemKey;
    },
    threeCols() {
      const { cols = 2 } = this.tableProps;
      return cols === 3;
    },
    tableHeaders() {
      const { headers } = this.tableProps;
      return headers;
    },
    headerActions() {
      const { headers } = this.tableProps;
      const lastElem = [...headers].pop();
      return lastElem.name;
    },
    tableClass() {
      const { tableClass: className } = this.tableProps;
      return className;
    },
    cardClass() {
      return this.hasItemsSelected
        ? "mx-auto px-4 pt-3 mb-n1"
        : "mx-auto mb-n1";
    },
    itemClass() {
      const hasIndent = this.threeCols ? "pl-009" : "";
      return `line--1-1 text-body-2 mb-1 ${hasIndent}`;
    },
    actionsClass() {
      const hasIndent = this.threeCols ? "pl-009" : "";
      return `line--1-1 d-flex align-center text-body-2 ${hasIndent}`;
    },
    btnTextColorClass() {
      return this.itemsSelected ? "white--text" : "";
    },
  },
  watch: {
    items: function (newVal) {
      if (newVal) {
        this.setItemsList(newVal);
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.setItemsList(this.items);
    });
  },

  methods: {
    setItemsList(items) {
      // this.itemsList = items.map((e) => ({ ...e, status: "CREATED" })) // test chips ;
      this.itemsList = items;
    },

    itemID(order) {
      const { commonID } = this;
      const specificID = `item-${order + 1}`;
      return `${commonID}${specificID}`;
    },
    itemName(item) {
      const { itemKey } = this.tableProps;
      const { itemId } = item;
      return this.$i18n.t(`item.${itemKey}.${itemId}.name`);
    },

    hasSelectedItems() {
      const selectableClassName = "tmg-table__item--mobile mx-auto mb-n1";
      /*   if (this.hasItemsSelected) { */
      return `${selectableClassName} px-4 pt-3`;
      /*   }
      return item.disabled
        ? `${selectableClassName} item--has-disabled`
        : selectableClassName; */
    },

    hasStatus(item) {
      const { expectedStatus } = this;
      const { status } = item;
      return status && expectedStatus.includes(status);
    },

    itemStatusName(item) {
      const { status } = item;
      return status === "CREATED"
        ? this.$t("private.item.isNew")
        : this.$t("private.item.isUpdated");
    },

    itemStatusClass(item) {
      const { status } = item;
      const defaultClass = "green text-uppercase ma-1 pointer--none";
      const statusClass =
        status === "CREATED"
          ? "white--text font-weight-light"
          : "v-chip--outlined green--text";
      return `${defaultClass} ${statusClass}`;
    },

    itemActionsClass(item) {
      const { resalted = false } = item;
      const defClass = `line--1-1 d-flex align-center text-body-2`;
      return resalted
        ? `${defClass} pink--text font-weight-medium`
        : `${defClass} deep-purple--text`;
    },
  },
};
</script>
<style>
div[id*="koko-results-mobile__table--item"] .v-chip .v-chip__content {
  letter-spacing: 0.1em;
}
.tmg-table__item--mobile .v-icon.v-icon--dense {
  color: inherit;
}
.line--1-1 {
  line-height: 1.1 !important;
}
.line--1-2 {
  line-height: 1.2 !important;
}
.pl-009 {
  padding-left: 0.09em !important;
}
.header--text {
  font-size: 0.8rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.7);
}
</style>
