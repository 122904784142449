<template>
  <v-row justify="center">
    <v-col cols="10" sm="6" md="5" lg="4">
      <div class="bloque-formulario-acceso bloque-formulario-login">
        <h1>{{ $t("private.pageactivate.title") }}</h1>
        <div v-if="error">
          <p
            class="mt-2"
            v-for="(err, index) in errors"
            :key="index"
            style="color: red"
            v-html="$t(err)"
          />
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import api from "@/services";

export default {
  name: "ActivateAccount",
  props: ["token"],
  data() {
    return {
      error: false,
      tokenJWT: null,
      userAccount: null,
      errors: [],
    };
  },
  created() {
    this.activateUser();
  },
  methods: {
    async activateUser() {
      try {
        const response = await api.activateAccount(this.token);
        const { status, data } = response;
        if (status === 200) {
          this.$store.commit("SET_TOKEN", data.token);
          this.tokenJWT = data.token;
          await this.getUserKitData();
        }
        await this.$router.push("/login");
      } catch (e) {
        this.errors.push(e.response.data.message);
        this.error = true;
      }
    },
    async setUserLanguage() {
      let value = "en";
      if (this.language) {
        value = this.language;
      }
      this.$store.commit("SET_UI_LANGUAGE", value);
      await this.$store.dispatch("changeLanguage", { userLanguage: value });
      this.$vuetify.lang.current = value;
    },
    async getUserKitData() {
      const { tokenJWT } = this;
      try {
        const response = await api.getUserKitData(tokenJWT);
        const { status, data } = response;
        if (status === 200) {
          this.$store.commit("SET_USER_KIT_DATA", data);
          const { user } = data;
          this.userAccount = user;
          await this.getUserData();
        }
      } catch (e) {
        this.showErrors();
      }
    },
    async getUserData() {
      const { userAccount, tokenJWT } = this;
      const { account_id } = userAccount;
      try {
        const response = await api.getUserData(account_id, tokenJWT);
        const { status, data } = response;
        if (status === 200) {
          this.$store.commit("SET_USER_DATA", data);
          this.$store.commit("SET_LOGIN_SUCCESS", true);
          this.language = data.defaultLanguage;
          await this.setUserLanguage();
          await this.$router.push("/");
        }
      } catch (e) {
        console.log(e);
        this.showErrors();
      }
    },
  },
};
</script>
