<template>
  <v-container>
    <UpdateEmail />
  </v-container>
</template>
<script>
import UpdateEmail from "@/components/update/UpdateEmail.vue";

export default {
  name: "UpdateEmailView",

  components: {
    UpdateEmail,
  },
};
</script>
