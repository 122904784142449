<template>
  <v-dialog v-model="showDialog" scrollable max-width="340px" persistent>
    <v-card>
      <v-card-title class="pt-4 d-block">
        {{ $t("private.peoplefinder.peoplefinder") }}
      </v-card-title>
      <v-card-text class="content pb-3">
        <p class="mb-0 line--1-3">
          {{ $t("private.peoplefinder.banner.refuse") }}
        </p>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer />
        <v-btn text @click="closeDialog">
          {{ $t("private.form.cancel") }}
        </v-btn>
        <v-btn text color="primary" value="true" @click="refuseDnaConnect">
          {{ $t("private.form.required.accept") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "DNAConnectDialog",
  props: {
    confirm: {
      type: Function,
      default: () => null,
    },
    cancel: {
      type: Function,
      default: () => null,
    },
    show: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },
  watch: {
    show(newValue) {
      this.showDialog = newValue;
    },
  },
  computed: {
    acceptConsent() {
      const acceptElem = this.$t(
        "private.createaccount.pageTexts.acceptConsent"
      );
      return acceptElem.replace("<a ", '<a target="_blank" ');
    },
  },

  methods: {
    closeDialog() {
      this.showDialog = false;
      this.cancel();
    },
    refuseDnaConnect() {
      this.showDialog = false;
      this.confirm(false);
    },
  },
};
</script>
