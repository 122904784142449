<template>
  <v-sheet
    class="tmg-o-report__sheet mb-8 mt-4 deep-purple lighten-5 py-2 pl-2 pr-1"
  >
    <section class="tmg-o-report__section pr-1">
      <v-card
        v-for="(elem, i) in info"
        :key="`${i}_item.monogenicId`"
        class="tmg-o-report__card px-4 py-2"
      >
        <p :class="headerClassName">
          {{ $t("private.common.gene") }}
        </p>
        <p :class="elemClassName">
          {{ elem.genOrRegion }}
        </p>
        <p :class="headerClassName">
          {{ $t("private.common.chromosome") }}
        </p>
        <p :class="elemClassName">
          {{ elem.chromosome }}
        </p>
        <p :class="headerClassName">
          {{ $t("private.common.position") }}
        </p>
        <p :class="elemClassName">
          {{ elem.position }}
        </p>
        <p :class="headerClassName">
          {{ $t("private.common.interpretation") }}
        </p>
        <p :class="elemClassName">
          {{ $t(techName(elem)) }}
        </p>
      </v-card>
    </section>
  </v-sheet>
</template>
<script>
export default {
  name: "MobileTableTechReport",
  props: {
    info: {
      type: Array,
      default: () => null,
    },
    type: {
      type: String,
      default: () => null,
    },
    idKey: {
      type: String,
      default: () => null,
    },
  },

  data() {
    return {
      captionClassName:
        "deep-purple--text text--lighten-1 text-caption mb-0 mt-n1",
      headerClassName: "text-caption  mb-1 deep-purple--text  text--darken-3",
      elemClassName:
        "tmg-u__hyphens--none line--1-1 text-body-2 font-weight-medium mb-3 deep-purple--text",
    };
  },

  methods: {
    techName(elem) {
      const { type, idKey } = this;
      const { idReport, name } = elem;
      const itemId = elem[idKey];
      const keyToTranslate = `technicalreport.${type}.${itemId}.${idReport}.${name}.result`;
      return keyToTranslate;
    },
  },
};
</script>
<style>
.tmg-o-report__section {
  scrollbar-color: #5e35b1 hsl(264, 45.5%, 85%);
  scrollbar-width: thin;
}
.tmg-o-report__section::-webkit-scrollbar-thumb {
  background-color: #5e35b1;
  border: none;
  border-radius: 3px;
}
.tmg-o-report__section::-webkit-scrollbar-track {
  background: hsl(264, 45.5%, 85%);
  border-radius: 3px;
}

.tmg-o-report__section::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.tmg-o-report__sheet .tmg-o-report__section {
  max-height: 30em;
  max-height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.theme--light.v-card.tmg-o-report__card {
  box-shadow: none;
  background-color: hsla(263, 35%, 80%, 0.25);
}

.tmg-o-report__section .tmg-o-report__card {
  margin-right: 2px;
  margin-bottom: 8px;
}

.tmg-o-report__section .tmg-o-report__card:last-child {
  margin-bottom: 0;
}
</style>
