<template>
  <v-row justify="center">
    <v-col cols="12" lg="7" class="px-4 text-center">
      <p
        v-for="(item, i) in contents"
        :key="`contents--${i}`"
        :class="getItemClass(item)"
      >
        {{ $t(item.key) }}
      </p>
      <v-btn
        v-if="btnText"
        depressed
        class="mt-6 mb-4"
        color="primary"
        @click="btnFunction"
      >
        {{ $t(btnText) }}
      </v-btn>
      <section v-if="imageExists">
        <img class="koko-dna__image my-5" :src="imageExists" />
      </section>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "DNAConnectInfo",
  props: {
    msgObject: {
      type: Object,
      default: () => {},
    },
    btnFunction: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      classes: {
        title: "koko-dna__title mb-3",
        subtitle: "koko-dna__subtitle mb-3",
        msg1: "koko-dna__message mb-0",
      },
    };
  },
  computed: {
    contents() {
      const {
        msgObject: { contents },
      } = this;
      return contents;
    },

    btnText() {
      const {
        msgObject: { btnText },
      } = this;
      return btnText;
    },

    imageExists() {
      const {
        msgObject: { image = false },
      } = this;
      if (!image) {
        return false;
      }
      try {
        const imgFile = require(`@/assets/img/dnaconnect/${image}`);
        return imgFile;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
  },

  methods: {
    getItemClass(item) {
      const { classes } = this;
      const { type } = item;
      return classes[type];
    },
  },
};
</script>
<style lang="scss">
.koko-dna__image {
  display: block;
  width: 340px;
  max-width: 90%;
  margin: 0 auto;
  user-select: none;
}

.koko-dna__title {
  line-height: 1.3;
  hyphens: none;
  font-size: 1.3rem;
  font-weight: 600;
}

.koko-dna__subtitle {
  line-height: 1.3;
  hyphens: none;
  font-size: 1.1rem;
  font-weight: 500;
}

.koko-dna__message {
  line-height: 1.3;
  hyphens: none;
  font-size: 1rem;
  font-weight: 400;
}
</style>
