<template>
  <v-container fluid>
    <IconsSvg />
    <v-container class="pt-0">
      <v-row>
        <v-col
          cols="12"
          sm="4"
          md="6"
          offset-sm="4"
          offset-md="3"
          class="text-center pb-0 pb-md-4"
        >
          <h1 class="text-h5 text-center">
            {{ $t("private.menu.race") }}
          </h1>
        </v-col>
        <v-col
          v-if="pdfFileExists"
          cols="12"
          sm="4"
          md="3"
          class="text-center text-sm-right"
        >
          <v-btn
            :loading="downloadingPDF"
            color="primary"
            x-small
            depressed
            class="koko-download-pdf__button mx-3 mx-md-5"
            @click="downloadPDF"
          >
            {{ $t("private.common.downloadPDF.button") }}
            <v-icon dark x-small class="ml-1"> mdi-tray-arrow-down </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="6" v-if="!userBreeds">
          <v-skeleton-loader class="my-4" type="image" />
        </v-col>
        <v-col cols="12" sm="11" md="10" lg="8" v-if="!userBreeds">
          <p class="text-body-2 text--disabled text-center mt-2 mb-n14">
            {{ $t("private.common.loadingText") }}
          </p>
          <v-skeleton-loader class="my-4" type="image" />
        </v-col>
      </v-row>
      <v-row justify="center" v-if="userBreeds">
        <v-col cols="12" class="py-6 d-none d-md-block px-4">
          <vc-donut
            background="white"
            foreground="#eee"
            :size="200"
            unit="px"
            :thickness="17"
            has-legend
            legend-placement="right"
            :sections="userBreeds"
            :total="100"
            :start-angle="0"
            :auto-adjust-text-size="true"
          >
            <UserAvatar size="150" :no-shadow="true" />
          </vc-donut>
        </v-col>
        <v-col cols="12" class="d-block d-md-none px-4">
          <vc-donut
            background="white"
            foreground="#eee"
            :size="150"
            unit="px"
            :thickness="17"
            has-legend
            legend-placement="bottom"
            :sections="userBreeds"
            :total="100"
            :start-angle="0"
            :auto-adjust-text-size="true"
          >
            <UserAvatar size="110" :no-shadow="true" />
          </vc-donut>
        </v-col>

        <v-col cols="12" md="10" lg="8" class="px-4">
          <BreedsDetails :breeds="userBreeds" />
        </v-col>
      </v-row>
    </v-container>
    <FamilyTree />
  </v-container>
</template>

<script>
import api from "@/services";
import { mapGetters } from "vuex";
import IconsSvg from "@/components/icons/IconsSvg";
import BreedsDetails from "./BreedsDetails";
import UserAvatar from "@/components/avatar/UserAvatar";
import FamilyTree from "@/components/family/FamilyTree";
import { reportMixin } from "@/mixins/reportMixin";

export default {
  name: "BreedComponent",
  components: {
    BreedsDetails,
    UserAvatar,
    FamilyTree,
    IconsSvg,
  },
  mixins: [reportMixin],
  data() {
    return {
      sections: null,
      userBreeds: null,
      thickness: 17,
      donutSizeDesktop: 200,
      donutMobileeDesktop: 150,
      colors: [
        "#0A4D68",
        "#088395",
        "#05BFDB",
        "#ADE792",
        "#F3ECB0",
        "#F6BA6F",
        "#ff7700",
        "#f94c4c",
      ],
      downloadingPDF: false,
      pdfFileExists: true,
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.setDefaultValues();
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    });
  },

  created() {
    this.$on("downloaded-pdf", (data) => {
      this.downloadingPDF = data;
    });
  },

  computed: {
    ...mapGetters(["getToken", "getUserKitData", "getBreedsItems"]),
  },
  methods: {
    downloadPDF() {
      this.downloadingPDF = true;
      this.downloadReport(this.getBreedsItems, "BREED");
    },

    setDefaultValues() {
      const { locale } = this.$i18n;
      const {
        getBreedsItems: localeItems,
        getUserKitData: {
          user: { profilePic, petName },
        },
      } = this;

      this.profilePic = profilePic;
      this.petName = petName;

      if (!localeItems) {
        this.loadData(locale);
        return false;
      }

      const hasItemsByLang = Object.hasOwn(localeItems, locale);

      if (hasItemsByLang) {
        this.userBreeds = localeItems[locale];
        return true;
      }
      this.loadData(locale);
    },

    async loadData(locale) {
      const {
        getToken: token,
        getUserKitData: {
          user: { kit: barcode },
        },
      } = this;
      try {
        const [texts, breeds] = await Promise.all([
          api.getItemTextsInLanguage(locale),
          api.getResultsBreed(token, barcode),
        ]);
        this.$i18n.mergeLocaleMessage(locale, texts.data);
        this.setDisplayData(breeds.data);
      } catch (error) {
        this.$store.commit("SET_LOGOUT");
        this.$router.push("/login");
      }
    },

    setDisplayData(data) {
      const { locale } = this.$i18n;
      const filteredData = data.filter((e) => Boolean(e.percentage));
      const sortedData = filteredData.sort(
        (a, b) => b.percentage - a.percentage
      );

      const userBreeds = sortedData.map((e, i) => ({
        name: this.getName(e),
        label: this.getLabel(e),
        id: e.popcode,
        value: e.percentage,
        color: this.colors[i],
      }));

      const localeItems = {};
      localeItems[locale] = userBreeds;
      this.$store.commit("SET_BREEDS_ITEMS", localeItems);
      this.$i18n.mergeLocaleMessage(locale, userBreeds);
      this.userBreeds = userBreeds;
    },

    getName(elem) {
      const { popcode } = elem;
      return this.$i18n.t(`item.breed.${popcode}.name`);
    },

    getLabel(elem) {
      const { percentage } = elem;
      const name = this.getName(elem);
      return `${percentage}% ${name}`;
    },
  },
};
</script>
