<template>
  <v-form :ref="formRef" lazy-validation>
    <v-row justify="center">
      <v-col cols="12">
        <h6 class="text-caption line--1-2">
          {{ $t("private.component.dhl.subtitle.checkAddress") }}
        </h6>
      </v-col>
      <v-col cols="12" md="6">
        <CountrySelect
          :set-value="setCountry"
          :value="getUserCountry"
          :rules="[requiredRules]"
        />
      </v-col>
      <v-col cols="12" md="6" v-if="!isSelectedCountryUSA">
        <TextField
          id="koko-confirm__field--province"
          :value="values.province"
          label="private.createaccount.pageTexts.province"
          :set-value="setValue"
          :rules="[requiredRules]"
        />
      </v-col>
      <v-col cols="12" md="6" v-if="isSelectedCountryUSA">
        <SelectField
          id="koko__confirm__field--state"
          :items="getStatesList"
          :set-value="setState"
          :value="getUserCountryState"
          :loading="!getStatesList"
          :rules="[requiredRules]"
        />
      </v-col>
      <v-col cols="12" md="6">
        <TextField
          id="koko-confirm__field--city"
          :value="values.city"
          label="private.createaccount.pageTexts.city"
          :set-value="setValue"
          :rules="[requiredRules, textRules]"
        />
      </v-col>

      <v-col cols="12" md="6">
        <TextField
          id="koko-confirm__field--street"
          :value="values.street"
          label="private.createaccount.pageTexts.street"
          :set-value="setValue"
          :rules="[requiredRules]"
        />
      </v-col>
      <v-col cols="12" md="3">
        <TextFieldOptional
          id="koko-confirm__field--number"
          :value="values.number"
          label="private.createaccount.pageTexts.number"
          :set-value="setValue"
        />
      </v-col>
      <v-col cols="12" md="3">
        <TextField
          id="koko-confirm__field--postalCode"
          :value="values.postalCode"
          label="private.createaccount.pageTexts.postalCode"
          :set-value="setValue"
        />
      </v-col>
      <v-col cols="12" md="6">
        <TextField
          id="koko-confirm__field--phoneNumber"
          :value="values.phoneNumber"
          maxlength="20"
          counter
          type="tel"
          label="private.createaccount.pageTexts.phone"
          :set-value="setValue"
          :rules="[requiredRules, phoneRules]"
        />
      </v-col>
      <v-col
        id="koko-register__btn-prev--2"
        cols="12"
        class="d-flex justify-space-between"
      >
        <v-btn text :disabled="loading" @click="prevStep()">
          {{ $t("private.createaccount.pageTexts.prev") }}
        </v-btn>
        <v-btn
          id="koko-register__btn-next--3"
          color="primary"
          @click="sendValues()"
          large
          depressed
          :disabled="!completeForm"
          :loading="loading"
        >
          {{ $t("private.createaccount.pageTexts.next") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { mapGetters } from "vuex";
import CountrySelect from "@/components/form/CountrySelect";
import TextField from "@/components/form/TextField";
import TextFieldOptional from "@/components/form/TextFieldOptional";
import SelectField from "@/components/form/SelectField";

export default {
  name: "RegisterConfirmAddress",
  components: {
    TextField,
    TextFieldOptional,
    SelectField,
    CountrySelect,
  },
  props: {
    prevStep: {
      type: Function,
      default: () => null,
    },
    nextStep: {
      type: Function,
      default: () => null,
    },
    update: {
      type: Function,
      default: () => null,
    },
    values: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      USA_ID: "65",
      formRef: "registerConfirmAdress",
      states: [],
      loadingStates: false,
      loading: false,
      completeForm: true,
      defaultValues: [],
      fields: [
        { name: "city" },
        { name: "number" },
        { name: "street" },
        { name: "postalCode" },
        { name: "phoneNumber" },
        { name: "province" },
        { name: "countryId" },
      ],
      textRegEx: /^([^0-9]*)$/,
      phoneRegEx:
        /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/,
    };
  },
  watch: {
    values(newValues) {
      this.setDefaultValues(newValues);
    },

    getUserCountryState() {
      this.checkFields();
    },

    getUILanguage() {
      const { formRef } = this;
      this.$refs[formRef].resetValidation();
      this.checkFields();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setDefaultValues(this.values);
    });
  },

  computed: {
    ...mapGetters([
      "getUILanguage",
      "getUserCountry",
      "getUserCountryState",
      "getStatesList",
    ]),

    isSelectedCountryUSA() {
      const { USA_ID, getUserCountry } = this;
      if (!getUserCountry) {
        return false;
      }
      return getUserCountry.id === USA_ID;
    },

    phoneLabel() {
      return this.$i18n.t("private.createaccount.pageTexts.phone");
    },

    errorRequired() {
      const key = "private.createaccount.pageTexts.requiredField";
      return this.$te(key) ? this.$i18n.t(key) : "Error";
    },

    errorPhone() {
      return this.$i18n.t("private.error.addphonenumber");
    },

    errorNumber() {
      const key = "private.error.dhl.missingNumber";
      return this.$te(key) ? this.$t(key) : "Error";
    },

    phoneRules() {
      const { phoneRegEx } = this;
      return (v) => !v || phoneRegEx.test(v) || this.errorPhone;
    },

    textRules() {
      const { textRegEx } = this;
      return (v) => !v || textRegEx.test(v) || "Error";
    },

    requiredRules() {
      return (value) => !!value || this.errorRequired;
    },
  },

  methods: {
    setCountry(evt) {
      this.$store.commit("SET_USER_COUNTRY", evt);
      const { id } = evt;
      this.setValue(id, "countryId");
    },

    setDefaultValues(newValues) {
      const { fields } = this;
      const defaultValues = fields.reduce(
        (i, e) => [...i, { name: e.name, value: newValues[e.name] }],
        []
      );
      this.defaultValues = defaultValues;
      this.checkFields();
    },

    sendValues() {
      const { defaultValues } = this;
      this.loading = true;
      this.nextStep(defaultValues);
    },

    setState(evt) {
      this.$store.commit("SET_USER_COUNTRY_STATE", evt);
      const { value } = evt;
      this.setValue(value, "user--province");
    },

    setValue(value, id) {
      const fieldName = id.split("--").pop();
      const { defaultValues } = this;
      const values = defaultValues.filter((e) => e.name !== fieldName);
      const updatedValues = [...values, { name: fieldName, value }];
      this.defaultValues = updatedValues;
      this.update(updatedValues);
      this.checkFields();
    },

    checkFields() {
      const { defaultValues, isSelectedCountryUSA, getUserCountryState } = this;
      if (isSelectedCountryUSA && !getUserCountryState) {
        this.completeForm = false;
        return false;
      }

      const optionalFields = ["number"];
      const requiredFields = [...defaultValues]
        .reduce(
          (i, e) => [
            ...i,
            {
              name: optionalFields.find((a) => a === e.name) ? "" : e.name,
              value: e.value,
            },
          ],
          []
        )
        .filter((i) => Boolean(i.name));
      const completedAllFields = requiredFields.every((e) => Boolean(e.value));
      if (!completedAllFields) {
        this.completeForm = false;
        return false;
      }
      const allTextFieldsOK = this.checkTextFields();
      const allPhoneFieldsOK = this.checkPhoneFields();
      const allValuesOk = [allTextFieldsOK, allPhoneFieldsOK].every(Boolean);

      if (!allValuesOk) {
        this.completeForm = false;
        return false;
      }
      this.completeForm = true;
    },

    checkTextFields() {
      const { defaultValues, textRegEx } = this;
      const textFieldNames = ["city"];
      const textFields = [...defaultValues]
        .reduce(
          (i, e) => [
            ...i,
            e.name === textFieldNames.find((a) => a === e.name)
              ? e.value
              : null,
          ],
          []
        )
        .filter((i) => Boolean(i));

      return textFields.every((e) => textRegEx.test(e));
    },

    checkPhoneFields() {
      const { defaultValues, phoneRegEx } = this;
      const phoneFieldsNames = ["phoneNumber"];
      const phoneFields = defaultValues
        .reduce(
          (i, e) => [
            ...i,
            e.name === phoneFieldsNames.find((a) => a === e.name)
              ? e.value
              : null,
          ],
          []
        )
        .filter((i) => i);
      return phoneFields.every((e) => phoneRegEx.test(e));
    },
  },
};
</script>
