<template>
  <v-container>
    <h1 class="text-h5 text-center mb-3">
      {{ $t("private.header.menus.settings") }}
    </h1>
    <SettingsCardList />
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import SettingsCardList from "@/components/settings/SettingsCardList";

export default {
  name: "SettingsView",
  components: {
    SettingsCardList,
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
  created() {
    if (!this.isLoggedIn) {
      this.$router.push("/");
    }
  },
};
</script>
