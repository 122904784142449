<template>
  <v-sheet color="#0d0d3f" dark class="header-user pt-2" @click.stop>
    <v-btn
      v-if="mobile"
      class="header-user__close"
      small
      icon
      dark
      @click="closeDrawer()"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-container class="d-block fill-height text-center">
      <h4 class="header-user__name my-4">{{ userName }}</h4>
      <p class="header-user__subtitle">
        {{ barCode }}
      </p>
    </v-container>
  </v-sheet>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "HeaderUser",
  props: {
    mobile: {
      type: Boolean,
      default: false,
    },
    closeMenu: {
      type: Function,
      default: () => null,
    },
    closeDrawer: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      items: [],
      hasItems: false,
      userName: null,
      selectedAccount: null,
      barCode: null,
    };
  },

  computed: {
    ...mapGetters(["getUserData", "getUserKitData", "getUILanguage"]),
  },

  created() {
    /* this.$EventBus.$on(
      "update:professional-kits",
      this.refreshProfessionalKits
    ); */
    //console.log(this.getUserData);
  },

  mounted() {
    this.$nextTick(() => {
      this.setValues();
    });
  },

  methods: {
    setValues() {
      const {
        getUserKitData: {
          user: { kit: barCode },
        },
        getUserData: { firstName, lastName },
      } = this;

      this.userName = `${firstName} ${lastName}`;

      this.barCode = barCode;
    },

    strToBoolean(prop) {
      return String(prop === "true");
    },

    /*   getKitObj(obj) {
      const {
        kit,
        ancestryV3,
        hasResults: results,
        hasAncestry: ancestry,
        kitReceived: received,
        oldChip: old,
        showPharma: pharma,
        typeKitId,
        typeKitName,
        isFree,
        allowImputation,
        hasTraitsSelected = false,
        hasMonoSelected = false,
        hasPharmaSelected = false,
        hasComplexSelected = false,
        hasWellnessSelected = false,
      } = obj;

      const parsedObj = {
        kit,
        ancestryV3,
        hasResults: results === "true",
        hasAncestry: ancestry === "true",
        kitReceived: received === "true",
        oldChip: old === "true",
        showPharma: pharma === "true",
        typeKit: { id: Number(typeKitId), name: typeKitName },
        isFree,
        allowImputation,
        hasTraitsSelected,
        hasMonoSelected,
        hasPharmaSelected,
        hasComplexSelected,
        hasWellnessSelected,
      };
      return parsedObj;
    }, */

    getFullName(item) {
      return `${item.firstName} ${item.lastName} - ${item.barCode}`;
    },

    getBarcode(item) {
      return item.barCode;
    },
  },
};
</script>
