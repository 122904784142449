<template>
  <v-app class="koko-app">
    <v-app-bar app absolute flat color="white" height="90">
      <DesktopHeader />
      <MobileHeader :toggle-showed="toggleShowed" />
    </v-app-bar>
    <v-main class="koko-main">
      <router-view />
      <PageFooter />
    </v-main>
    <v-navigation-drawer v-model="showed" temporary app right width="340">
      <HeaderUser :mobile="true" :close-drawer="toggleShowed" />
      <MobileMenu />
    </v-navigation-drawer>
  </v-app>
</template>
<script>
import DesktopHeader from "@/components/header/DesktopHeader";
import MobileHeader from "@/components/header/MobileHeader";
import PageFooter from "@/components/footer/PageFooter";
import MobileMenu from "@/components/menu/MobileMenu";
import HeaderUser from "@/components/user/HeaderUser";

export default {
  components: {
    DesktopHeader,
    MobileHeader,
    PageFooter,
    MobileMenu,
    HeaderUser,
  },
  data() {
    return {
      showed: false,
      layout: "principal-layout",
    };
  },
  methods: {
    toggleShowed() {
      const { showed } = this;
      this.showed = !showed;
    },
  },
};
</script>
