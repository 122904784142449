<template>
  <v-form :ref="formRef" lazy-validation>
    <v-row :data-ref="formRef">
      <v-col cols="12" class="pb-0">
        <p class="text-caption line--1-2 pt-2 pl-1">
          *
          {{ $t("private.createaccount.pageTexts.registerYourKitDescription") }}
        </p>
      </v-col>
      <v-col cols="12" md="7" class="py-0">
        <TextField
          id="koko-register__field--barCode"
          label="private.createaccount.pageTexts.barcode"
          placeholder="YYXX-XXXX-XXXX"
          maxlength="14"
          :set-value="setBarcode"
          :rules="[requiredRules, barcodeRules]"
        />
      </v-col>
      <v-col cols="12" md="6" class="pb-0">
        <TextField
          id="koko-register__field--petName"
          label="private.createaccount.pageTexts.petname"
          :set-value="setPetName"
          :rules="[requiredRules, textRules]"
        />
      </v-col>
      <v-col cols="12" class="pt-0">
        <p class="mb-1">{{ $t("private.createaccount.pageTexts.gender") }}</p>

        <label class="form-label pt-2">
          <input
            id="koko-register__radio--genderMale"
            type="radio"
            v-model="sex"
            name="sex"
            value="male"
          />
          {{ $t("private.createaccount.pageTexts.genderMale") }}
        </label>
        <label class="form-label pt-2">
          <input
            id="koko-register__radio--genderFemale"
            type="radio"
            v-model="sex"
            name="sex"
            value="female"
          />
          {{ $t("private.createaccount.pageTexts.genderFemale") }}
        </label>
      </v-col>
      <v-col cols="12">
        <label class="form-label">
          <input
            id="koko-register__check--acceptDNAConnect"
            type="checkbox"
            name="acceptDNAConnect"
            v-model="acceptDNAConnect"
          />
          <span> {{ $t("private.form.acceptdnaconncet") }}</span>
        </label>
        <label class="form-label">
          <input
            id="koko-register__check--acceptUsingData"
            type="checkbox"
            name="acceptUsingData"
            v-model="acceptUsingData"
          />
          <span v-html="$t('private.createaccount.pageTexts.acceptConsent')" />
        </label>
      </v-col>

      <v-col cols="12" class="d-flex justify-space-between">
        <v-btn id="koko-register__btn-prev--1" text @click="prevStep()">
          {{ $t("private.createaccount.pageTexts.prev") }}
        </v-btn>
        <v-btn
          id="koko-register__btn-next--2"
          color="primary"
          @click="sendValues"
          :disabled="!completeForm"
        >
          {{ $t("private.createaccount.pageTexts.next") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
import { formRulesMixin } from "@/mixins/formRulesMixin";
import TextField from "@/components/form/TextField";

export default {
  name: "RegisterKit",
  components: {
    TextField,
  },
  props: {
    prevStep: {
      type: Function,
      default: () => null,
    },
    nextStep: {
      type: Function,
      default: () => null,
    },
  },
  mixins: [formRulesMixin],
  data() {
    return {
      completeForm: false,
      formRef: "registerKit",
      barCode: null,
      petName: null,
      sex: null,
      acceptUsingData: null,
      acceptDNAConnect: null,
    };
  },
  watch: {
    getUILanguage() {
      const { formRef } = this;
      this.$refs[formRef].resetValidation();
    },
    barCode() {
      this.checkCompleted();
    },
    sex() {
      this.checkCompleted();
    },
    acceptUsingData() {
      this.checkCompleted();
    },
    acceptDNAConnect() {
      this.checkCompleted();
    },
    petName() {
      this.checkCompleted();
    },
  },

  computed: {
    ...mapGetters(["getUILanguage"]),

    errorRequired() {
      const key = "private.createaccount.pageTexts.requiredField";
      return this.$te(key) ? this.$i18n.t(key) : "Error";
    },

    requiredRules() {
      return (value) => !!value || this.errorRequired;
    },
    barcodeRules() {
      return (v) =>
        !v || /^(?=.*[A-Z])(?=.*[0-9])[A-Z0-9]*$/.test(v) || this.barcodeError;
    },

    barcodeError() {
      return this.$i18n.t("private.error.barcode");
    },
  },

  methods: {
    checkCompleted() {
      const { barCode, sex, acceptUsingData, petName } = this;
      this.completeForm = [barCode, sex, acceptUsingData, petName].every(
        Boolean
      );
    },
    sendValues() {
      const { barCode, sex, acceptUsingData, petName, acceptDNAConnect } = this;
      const fields = [
        { name: "barCode", value: barCode },
        { name: "sex", value: sex },
        { name: "petName", value: petName },
        { name: "acceptUsingData", value: acceptUsingData },
        { name: "acceptDNAConnect", value: acceptDNAConnect },
      ];
      this.$root.$emit("scrollToStepEmit");
      this.nextStep(fields);
    },
    setBarcode(value) {
      this.barCode = value;
    },
    setPetName(value) {
      this.petName = value;
    },
  },
};
</script>
