<template>
  <v-select
    id="koko__select--country"
    :value="value"
    :items="countries"
    :class="className"
    :loading="loading"
    :disabled="loading"
    :rules="rules"
    :label="translatedLabel"
    :hint="hint"
    :persistent-hint="persistentHint"
    dense
    filled
    return-object
    @change="setValue($event)"
  />
</template>
<script>
import api from "@/services";
import { mapGetters } from "vuex";

export default {
  name: "CountrySelect",
  props: {
    setValue: {
      type: Function,
      default: () => null,
    },
    hint: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: "koko-form__select",
    },
    label: {
      type: String,
      default: "private.createaccount.pageTexts.country",
    },
    value: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      loading: true,
      countries: [],
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.getCountriesByLang();
    });
  },
  watch: {
    getUILanguage() {
      this.getCountriesByLang();
    },
  },

  computed: {
    ...mapGetters(["getUILanguage"]),

    persistentHint() {
      return Boolean(this.hint);
    },
    translatedLabel() {
      return this.$i18n.t(this.label);
    },
  },
  methods: {
    async getCountriesByLang() {
      const { locale } = this.$i18n;
      try {
        const response = await api.getCountriesByLang(locale);
        const { data } = response;
        this.formatCountries(data);
      } catch (e) {
        console.log(e);
      }
    },
    formatCountries(data) {
      const dataList = Object.entries(data);
      const formattedList = [...dataList].reduce(
        (i, e) => [
          ...i,
          {
            id: e[0].split(".")[2],
            value: e[0].split(".")[2],
            name: "country",
            text: e[1],
          },
        ],
        []
      );
      const orderedList = formattedList.sort((a, b) =>
        a.text > b.text ? 1 : b.text > a.text ? -1 : 0
      );
      this.countries = orderedList;
      this.loading = false;
    },
  },
};
</script>
