<template>
  <HealthComponent v-if="isAdvanced" />
</template>

<script>
import { mapGetters } from "vuex";
import HealthComponent from "@/components/health/HealthComponent";

export default {
  name: "HealthView",
  components: { HealthComponent },
  computed: {
    ...mapGetters(["getUserKitData"]),
    isAdvanced() {
      const {
        user: { typeKitId, typeKitName },
      } = this.getUserKitData;
      return typeKitId === "1" && typeKitName === "Advanced";
    },
  },
  created() {
    if (!this.isAdvanced) {
      this.$router.push("/");
    }
  },
};
</script>
