<template>
  <v-container class="pb-10">
    <v-row justify="center">
      <v-col v-if="!isCompleted" cols="12" class="pa-0">
        <KokoBreadcrumbs :items="items" ref="stepKit" />
      </v-col>
      <v-col cols="12" lg="6" v-if="fromWelcome">
        <StepsComponent :steps="steps" :step="stepsCompleted" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col v-if="!isCompleted" cols="11" md="8">
        <h1 class="text-h5 text-center mb-2">
          {{ $t("private.pagelogin.createnewaccount") }}
        </h1>
        <v-stepper
          v-model="currentStep"
          vertical
          flat
          alt-labels
          class="koko-register__form"
        >
          <v-stepper-step
            :complete="currentStep > 1"
            step="1"
            :editable="firsStepEditable"
          >
            {{ $t("private.createaccount.pageTexts.userdata.title") }}
          </v-stepper-step>
          <v-stepper-content step="1">
            <RegisterUserData
              :next-step="nextStep"
              :values="objToConfirm"
              :welcome="fromWelcome"
            />
          </v-stepper-content>
          <v-stepper-step
            :complete="currentStep > 2"
            step="2"
            :editable="firsStepEditable"
          >
            {{ $t("private.createaccount.pageTexts.registerYourKit") }}
          </v-stepper-step>
          <v-stepper-content step="2" class="pt-0">
            <RegisterKit :prev-step="prevStep" :next-step="nextStep" />
          </v-stepper-content>
          <v-stepper-step :complete="currentStep > 3" step="3" class="pb-1">
            {{ $t("private.component.dhl.title.checkAddress") }}
          </v-stepper-step>
          <v-stepper-content step="3" class="pt-0 mb-8">
            <RegisterConfirmAddress
              :prev-step="prevStep"
              :values="objToConfirm"
              :next-step="sendData"
              :update="updateDataToSend"
            />
          </v-stepper-content>
        </v-stepper>
      </v-col>
      <v-col v-if="hasError" cols="11" md="8">
        <v-alert
          id="koko-register__alert--error"
          v-model="alert"
          dismissible
          type="error"
          text
          outlined
        >
          <p class="mb-0">{{ $t("private.error.duringactivation") }}</p>
          <p class="mb-0">{{ errorText }}</p>
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="isSuccess" justify="center">
      <v-col cols="11" md="8" class="d-flex flex-column flex-wrap align-center">
        <v-alert text color="success" id="koko-register__alert--success">
          <h4 class="text-h6 mt-1 mb-2 text-center">
            {{ $t("private.createaccount.pageTexts.successTitleOne") }}
          </h4>

          <p class="mb-1 text-center">
            {{ $t("private.createaccount.pageTexts.successDescriptionOne") }}
          </p>
          <p class="mb-4 text-center">
            <strong>{{ objToSend.email }}</strong>
          </p>
          <aside
            class="text-center text-body-2 mt-n2 text-wrap--balance"
            v-html="mailDescription"
          />
        </v-alert>
      </v-col>
      <v-col cols="11" md="8" v-if="fromWelcome" class="pt-1 mb-n8">
        <SendInstructions />
      </v-col>
      <v-col cols="11" md="8" class="text-center">
        <v-btn
          to="/"
          color="primary"
          large
          dark
          depressed
          id="koko-welcome__step5--next"
          class="mx-05 mb-1"
        >
          {{ $t("private.form.accept") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import api from "@/services";
import { mapGetters } from "vuex";
import RegisterUserData from "./RegisterUserData";
import RegisterKit from "./RegisterKit";
import RegisterConfirmAddress from "./RegisterConfirmAddress";
import StepsComponent from "@/components/steps/StepsComponent";
import KokoBreadcrumbs from "@/components/breadcrumbs/KokoBreadcrumbs";
import SendInstructions from "@/components/instructions/SendInstructions";

export default {
  name: "RegisterForm",
  components: {
    RegisterUserData,
    RegisterKit,
    RegisterConfirmAddress,
    StepsComponent,
    KokoBreadcrumbs,
    SendInstructions,
  },

  data() {
    return {
      USA_ID: "65",
      textRegEx: /^([^0-9]*)$/,
      steps: [
        { msg: "private.welcome.common.proccess.salivacollection" },
        { msg: "private.welcome.common.proccess.registerkit" },
        { msg: "private.welcome.common.proccess.sendsample" },
      ],
      currentStep: 1,
      lockedForm: false,
      objToConfirm: {},
      hasError: false,
      isSuccess: false,
      isCompleted: false,
      errorText: "",
      alert: true,
      objToSend: {
        acceptConsentData: false,
        acceptUsingData: true,
        accountType: "STANDARD",
        activationCode: "string",
        allowMailMarketing: false,
        barCode: "",
        birthCountryId: 0,
        birthDate: "2000-01-01",
        birthDateCustomer: "string",
        birthRegionId: 0,
        city: "",
        countryId: 0,
        defaultLanguage: "",
        email: "",
        firstName: "",
        genderId: 1,
        lastName: "",
        number: "",
        ownerId: 0,
        password: "",
        phoneNumber: "",
        postalCode: "",
        province: "",
        raceId: 0,
        sex: "",
        street: "",
        subraceId: 0,
        userId: 0,
      },
    };
  },

  watch: {
    alert() {
      this.currentStep = 1;
      this.hasError = false;
      this.isCompleted = false;
    },

    getUILanguage() {
      this.loadStates();
    },
  },

  created() {
    if (this.$route.query.lang) {
      const languageFromLink = this.$route.query.lang;
      const { locale } = this.$i18n;
      const loadedlanguage =
        this.$languagesToLoad.includes(languageFromLink) &&
        languageFromLink !== locale;

      if (loadedlanguage) {
        this.$i18n.locale = languageFromLink;
      }
    }
    this.loadStates();
  },

  mounted() {
    this.$root.$on("scrollToStepEmit", () => this.scrollToStep());
    this.$nextTick(() => {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    });
  },

  computed: {
    ...mapGetters(["getUILanguage"]),

    stepsCompleted() {
      const { isSuccess } = this;
      return isSuccess ? 3 : 2;
    },

    fromWelcome() {
      return this.$route.path === "/new-account/welcome";
    },

    firsStepEditable() {
      const { lockedForm, currentStep } = this;
      return !lockedForm && currentStep > 1;
    },

    mailDescription() {
      return this.$t("private.createaccount.pageTexts.successDescriptionTwo");
    },

    items() {
      const items = [
        {
          text: this.$i18n.t("private.pagehome.menus.init"),
          disabled: false,
          to: "/",
          id: "koko-breadcrumbs_item--1",
        },
        {
          text: this.$i18n.t("private.pagelogin.createnewaccount"),
          disabled: true,
          id: "koko-breadcrumbs_item--2",
        },
      ];

      const welcomeItem = {
        text: this.$i18n.t("private.welcome.welcome"),
        disabled: false,
        to: "/welcome",
        id: "koko-breadcrumbs_item--1",
      };

      if (this.fromWelcome) {
        items.splice(0, 1, welcomeItem);
      }

      return items;
    },
  },

  methods: {
    resetStates() {
      this.$store.commit("SET_STATES_LIST", null);
      this.$store.commit("SET_USER_COUNTRY_STATE", null);
    },

    async loadStates() {
      const { USA_ID } = this;
      this.resetStates();
      try {
        const response = await api.getStatesByCountry(USA_ID);
        const { data } = response;
        this.setFormattedStates(data);
      } catch (e) {
        console.log(e);
      }
    },
    setFormattedStates(data) {
      const stateKey = `private.state`;
      const unorderedStates = data.reduce(
        (i, e) => [
          ...i,
          {
            id: e.id,
            text: this.$i18n.t(`${stateKey}.${e.countryId}.${e.id}`),
            name: "state",
            value: e.isoCode,
          },
        ],
        []
      );
      const states = unorderedStates.sort((a, b) =>
        a.text > b.text ? 1 : b.text > a.text ? -1 : 0
      );
      this.$store.commit("SET_STATES_LIST", states);
      this.$store.commit("SET_USER_COUNTRY_STATE", null);
    },

    async registryAccount(userData) {
      try {
        const response = await api.registryAccount(userData);
        const { status } = response;
        if (status === 201) {
          this.isSuccess = true;
          this.isCompleted = true;
          window.scrollTo(0, 0);
        }
      } catch (error) {
        this.hasError = true;
        this.isCompleted = true;
        this.showErrorMessage(error.response.data.message);
        window.scrollTo(0, 0);
      }
    },

    showErrorMessage(message) {
      const keyExists = this.$i18n.te(message);
      if (keyExists) {
        this.errorText = this.$i18n.t(message);
        return true;
      }
      this.errorText = "";
    },

    updateDataToSend(data, send) {
      const { objToSend, getUILanguage } = this;
      const items = data.reduce((i, e) => [...i, [e.name, e.value]], []);
      const fieldsObj = Object.fromEntries(items);
      objToSend.defaultLanguage = getUILanguage.toUpperCase();
      const updatedObjToSend = { ...objToSend, ...fieldsObj };
      this.objToSend = updatedObjToSend;
      this.objToConfirm = updatedObjToSend;
      if (send) {
        this.registryAccount(updatedObjToSend);
      }
    },

    nextStep(data) {
      const { currentStep } = this;
      this.updateDataToSend(data);
      this.currentStep = Number(currentStep) + 1;
    },

    sendData(data) {
      this.lockedForm = true;
      this.updateDataToSend(data, true);
    },

    prevStep() {
      const { currentStep } = this;
      this.currentStep = Number(currentStep) - 1;
      this.scrollToStep();
    },

    scrollToStep() {
      const target = this.$refs["stepKit"];
      this.$vuetify.goTo(target, {
        duration: 300,
        offset: 0,
        easing: "linear",
      });
    },
  },
};
</script>
