<template>
  <v-form :ref="formRef" lazy-validation>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <TextField
          id="koko-register__field--firstName"
          label="private.createaccount.pageTexts.ownerName"
          :set-value="setValue"
          :value="getValue('firstName')"
          :rules="[requiredRules, textRules]"
        />
      </v-col>
      <v-col cols="12" md="6">
        <TextField
          id="koko-register__field--lastName"
          label="private.createaccount.pageTexts.ownerSurname"
          :set-value="setValue"
          :value="getValue('lastName')"
          :rules="[requiredRules, textRules]"
        />
      </v-col>
      <v-col cols="12" md="6">
        <TextField
          id="koko-register__field--email"
          label="private.createaccount.pageTexts.email"
          :set-value="setValue"
          :value="getValue('email')"
          :rules="[requiredRules, emailRules, emailMatchRules]"
        />
      </v-col>
      <v-col cols="12" md="6">
        <TextField
          id="koko-register__field--email2"
          label="private.createaccount.pageTexts.emailConfirm"
          :set-value="setValue"
          :disabled="disabledConfirmEmail"
          :value="getValue('email')"
          :rules="[requiredRules, emailRules, emailConfirmMatchRules]"
        />
      </v-col>
      <v-col cols="12" md="6">
        <PasswordField
          id="koko-register__field--password"
          :set-value="setValue"
          :value="getValue('password')"
          :rules="[requiredRules, passwordRules, passwordMatchRules]"
        />
      </v-col>
      <v-col cols="12" md="6">
        <PasswordField
          id="koko-register__field--password2"
          :set-value="setValue"
          label="private.createaccount.pageTexts.passwordConfirm"
          :disabled="disabledConfirmPassword"
          :value="getValue('password')"
          :rules="[requiredRules, passwordRules, passwordMatchRules]"
        />
      </v-col>
      <v-col cols="12">
        <h6 class="text-subtitle-1 text-center">
          {{ $t("private.common.registry.shipfrom") }}
        </h6>
      </v-col>
      <v-col cols="12" md="6">
        <CountrySelect
          :set-value="setCountry"
          :value="getUserCountry"
          :rules="[requiredRules]"
        />
      </v-col>
      <v-col cols="12" md="6" v-if="!isSelectedCountryUSA">
        <TextField
          id="koko-register__field--province"
          label="private.createaccount.pageTexts.province"
          :set-value="setValue"
          :disabled="!selectedCountry"
          :value="getValue('province')"
          :rules="[requiredRules]"
        />
      </v-col>
      <v-col cols="12" md="6" v-if="isSelectedCountryUSA">
        <SelectField
          id="koko__select--state"
          :items="getStatesList"
          :set-value="setState"
          :loading="!getStatesList"
          :value="getUserCountryState"
          :rules="[requiredRules]"
        />
      </v-col>
      <v-col cols="12" md="6">
        <TextField
          id="koko-register__field--city"
          label="private.createaccount.pageTexts.city"
          :set-value="setValue"
          :value="getValue('city')"
          :rules="[requiredRules, textRules]"
        />
      </v-col>
      <v-col cols="12" md="6">
        <TextField
          id="koko-register__field--street"
          label="private.createaccount.pageTexts.street"
          :set-value="setValue"
          :value="getValue('street')"
          :rules="[requiredRules]"
        />
      </v-col>
      <v-col cols="12" md="3">
        <TextFieldOptional
          id="koko-register__field--number"
          label="private.createaccount.pageTexts.number"
          :value="getValue('number')"
          :set-value="setValue"
        />
      </v-col>
      <v-col cols="12" md="3">
        <TextField
          id="koko-register__field--postalCode"
          label="private.createaccount.pageTexts.postalCode"
          :set-value="setValue"
          :value="getValue('postalCode')"
          :rules="[requiredRules]"
        />
      </v-col>
      <v-col cols="12" md="6">
        <TextField
          id="koko-register__field--phoneNumber"
          maxlength="20"
          counter
          type="tel"
          label="private.createaccount.pageTexts.phone"
          :set-value="setValue"
          :value="getValue('phoneNumber')"
          :rules="[requiredRules, phoneRules]"
        />
      </v-col>
      <v-col cols="12" class="mt-0 pt-0">
        <p class="text--secondary text-caption">
          (✱) {{ $t("private.createaccount.pageTexts.optionalField") }}
        </p>
      </v-col>
      <v-col cols="12" class="mt-md-n5 pt-0">
        <label class="form-label">
          <input
            type="checkbox"
            name="allowMailMarketing"
            v-model="allowMailMarketing"
            id="koko-register__ckeck--allowMailMarketing"
          />
          {{ $t("private.createaccount.pageTexts.commercialInfo") }}
        </label>
      </v-col>

      <v-col cols="12" class="d-flex justify-space-between">
        <v-btn text v-if="welcome" @click="prevStep">
          {{ $t("private.createaccount.pageTexts.prev") }}
        </v-btn>
        <v-btn
          color="primary"
          @click="sendValues()"
          large
          depressed
          :disabled="!completeForm"
          id="koko-register__btn-next--1"
        >
          {{ $t("private.createaccount.pageTexts.next") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { mapGetters } from "vuex";
import { formRulesMixin } from "@/mixins/formRulesMixin";
import PasswordField from "@/components/form/PasswordField";
import CountrySelect from "@/components/form/CountrySelect";
import SelectField from "@/components/form/SelectField";
import TextField from "@/components/form/TextField";
import TextFieldOptional from "@/components/form/TextFieldOptional";

export default {
  name: "RegisterUserData",
  components: {
    PasswordField,
    CountrySelect,
    SelectField,
    TextField,
    TextFieldOptional,
  },
  props: {
    nextStep: {
      type: Function,
      default: () => null,
    },
    values: {
      type: Object,
      default: () => {},
    },
    welcome: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [formRulesMixin],
  data() {
    return {
      USA_ID: "65",
      countryID: null,
      formRef: "registerUserDataForm",
      allowMailMarketing: false,
      emailMatch: false,
      passwordMatch: false,
      completeForm: false,
      states: [],
      fields: [
        { name: "email", value: "" },
        { name: "email2", value: "" },
        { name: "password", value: "" },
        { name: "password2", value: "" },
        { name: "firstName", value: "" },
        { name: "lastName", value: "" },
        { name: "countryId", value: "" },
        { name: "province", value: "" },
        { name: "city", value: "" },
        { name: "number", value: "" },
        { name: "street", value: "" },
        { name: "phoneNumber", value: "" },
        { name: "postalCode", value: "" },
      ],
    };
  },

  watch: {
    fields(newValue) {
      this.checkFields(newValue);
    },
    getUILanguage() {
      const { formRef } = this;
      this.$refs[formRef].resetValidation();
    },
  },

  computed: {
    ...mapGetters([
      "getUILanguage",
      "getUserCountry",
      "getUserCountryState",
      "getStatesList",
    ]),

    phoneLabel() {
      return this.$i18n.t("private.createaccount.pageTexts.phone");
    },

    selectedCountry() {
      const { getUserCountry } = this;
      return Boolean(getUserCountry);
    },

    isSelectedCountryUSA() {
      const { getUserCountry, USA_ID } = this;
      if (!getUserCountry) {
        return false;
      }
      this.setCountry(getUserCountry);
      return getUserCountry.id === USA_ID;
    },

    passwordLinkRules() {
      const { fields } = this;
      const passwordValues = [...fields]
        .filter((e) => e.name.includes("password"))
        .reduce((i, e) => [...i, e.value]);
      return passwordValues.every((e) => e === passwordValues[0]);
    },

    passwordMatchRules() {
      return (v) =>
        !v ||
        this.checkPasswordMatching() ||
        this.$i18n.t("private.pageeditaccount.passwordDontMatch");
    },

    emailMatchRules() {
      const emailConfirmValue = this.fields.find(
        (e) => e.name === "email2"
      ).value;
      if (!emailConfirmValue) {
        return true;
      }
      return (v) =>
        !v ||
        v === emailConfirmValue ||
        this.$i18n.t("private.pageeditaccount.emailDontMatch");
    },

    emailConfirmMatchRules() {
      const { emailValue } = this;
      if (!emailValue) {
        return true;
      }
      return (v) =>
        !v ||
        v === emailValue ||
        this.$i18n.t("private.pageeditaccount.emailDontMatch");
    },

    emailValue() {
      return this.fields.find((e) => e.name === "email").value;
    },

    passwordValue() {
      return this.fields.find((e) => e.name === "password").value;
    },

    disabledConfirmEmail() {
      const { emailValue } = this;
      return !emailValue;
    },

    disabledConfirmPassword() {
      const { passwordValue } = this;
      return !passwordValue;
    },
  },
  methods: {
    getValue(name) {
      const { values, fields } = this;
      const value = values[name] || fields[name];
      return value && value.length ? value.trim() : "";
    },

    sendValues() {
      const { fields, allowMailMarketing } = this;
      const allowMarketingField = [
        { name: "allowMailMarketing", value: allowMailMarketing },
      ];
      const cleanedFields = fields.filter(
        (e) => e.name !== "email2" && e.name !== "password2"
      );
      const fieldstoSend = [...cleanedFields, ...allowMarketingField];
      this.$root.$emit("scrollToStepEmit");
      this.nextStep(fieldstoSend);
    },

    setValue(valueRaw, id) {
      const value = valueRaw.trim();
      const name = id.split("--").pop();
      const filteredFields = this.fields.filter((e) => e.name !== name);
      const newFields = [...filteredFields, { name, value }];
      this.fields = newFields;
    },

    setCountry(evt) {
      this.$store.commit("SET_USER_COUNTRY", evt);
      const { id } = evt;
      this.setValue(id, "countryId");
    },

    setState(evt) {
      const { value } = evt;
      this.$store.commit("SET_USER_COUNTRY_STATE", evt);
      this.setValue(value, "province");
    },

    checkEmailMatching() {
      const emails = this.fields
        .filter((e) => e.name.includes("email"))
        .reduce((i, e) => [...i, e.value], []);
      const hasFilledEmails = emails.every((i) => Boolean(i));
      if (!hasFilledEmails) {
        return true;
      }
      return emails[0] === emails[1];
    },

    checkPasswordMatching() {
      const passwords = this.fields
        .filter((e) => e.name.includes("password"))
        .reduce((i, e) => [...i, e.value], []);
      const hasFilledPasswords = passwords.every((i) => Boolean(i));
      if (!hasFilledPasswords) {
        return true;
      }
      return passwords[0] === passwords[1];
    },

    checkFields(newValue) {
      const { formRef } = this;
      const optionalFields = ["number"];

      const requiredFields = [...newValue]
        .reduce(
          (i, e) => [
            ...i,
            {
              name: optionalFields.find((a) => a === e.name) ? "" : e.name,
              value: e.value,
            },
          ],
          []
        )
        .filter((i) => Boolean(i.name));
      const completedAllFields = requiredFields.every((e) => Boolean(e.value));
      if (!completedAllFields) {
        this.completeForm = false;
        this.$refs[formRef].resetValidation();
        return false;
      }
      const linkedFieldsOK =
        this.checkEmailMatching() && this.checkPasswordMatching() && true;
      const allTextFieldsOK = this.checkTextFields();
      const allPhoneFieldsOK = this.checkPhoneFields();

      const allValuesOk = [
        linkedFieldsOK,
        allTextFieldsOK,
        allPhoneFieldsOK,
      ].every(Boolean);

      if (!allValuesOk) {
        this.completeForm = false;
        this.$refs[formRef].resetValidation();
        return false;
      }
      this.completeForm = true;
    },

    checkTextFields() {
      const { fields, textRegEx } = this;
      const textFieldNames = ["firstName", "lastName", "city"];
      const textFields = fields
        .reduce(
          (i, e) => [
            ...i,
            e.name === textFieldNames.find((a) => a === e.name)
              ? e.value
              : null,
          ],
          []
        )
        .filter((i) => Boolean(i));

      return textFields.every((e) => textRegEx.test(e));
    },

    checkPhoneFields() {
      const { fields, phoneRegEx } = this;
      const phoneFieldsNames = ["phoneNumber"];
      const phoneFields = fields
        .reduce(
          (i, e) => [
            ...i,
            e.name === phoneFieldsNames.find((a) => a === e.name)
              ? e.value
              : null,
          ],
          []
        )
        .filter((i) => Boolean(i));
      return phoneFields.every((e) => phoneRegEx.test(e));
    },

    prevStep() {
      this.$router.push("/welcome");
    },
  },
};
</script>
