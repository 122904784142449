<template>
  <v-container class="pt-0">
    <v-row justify="space-between" class="mt-0">
      <v-col cols="auto" class="pt-0">
        <KokoBreadcrumbs :items="items" class="py-0 px-4" />
      </v-col>
      <v-col v-if="pdfFileExists" cols="auto" class="pr-7 pt-0 pl-6">
        <v-btn
          :loading="downloadingPDF"
          color="primary"
          x-small
          depressed
          class="koko-download-pdf__button"
          @click="downloadPDF"
        >
          {{ $t("private.common.downloadPDF.button") }}
          <v-icon dark x-small class="ml-1"> mdi-tray-arrow-down </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" class="px-2 pt-2">
        <h1 class="text-h4 line--1-2 text-center mb-4">
          {{ getText("name") }}
        </h1>
      </v-col>
    </v-row>
    <v-row justify="center" class="pb-12" v-if="loading">
      <v-col cols="11" sm="10" class="pt-3">
        <p class="text-body-2 text--disabled text-center mt-2 mb-n14">
          {{ $t("private.common.loadingText") }}
        </p>
        <v-skeleton-loader class="my-4" type="image" />
      </v-col>
    </v-row>
    <v-row justify="center" class="pb-12" v-if="results">
      <v-col cols="11" sm="10" class="pt-3">
        <section v-html="getText('description')" class="koko-u__v-html" />
        <v-divider class="my-4" />
        <h6 class="text-subtitle-1 text-center mt-8 mb-2">
          {{ $t("private.common.yourResult") }}
        </h6>
        <p v-if="itemStatus" class="text-h6 text-center mt-n3 mb-4">
          {{ $t(itemStatus) }}
        </p>
        <aside v-if="info" class="d-none d-sm-block">
          <TableTechReport :info="info" idKey="healthId" type="health" />
        </aside>
        <aside v-if="info" class="d-block d-sm-none">
          <MobileTableTechReport :info="info" idKey="healthId" type="health" />
        </aside>
      </v-col>
      <v-col
        v-for="(section, s) in sections"
        :key="`section_${s}`"
        cols="11"
        sm="10"
        :class="setSectionClass(section)"
      >
        <h4 v-if="section.title" class="text-h6 mb-1">
          {{ $t(section.title) }}
        </h4>
        <section v-html="getText(section.key)" class="koko-u__v-html" />
      </v-col>

      <v-col v-if="technicalCitations" cols="11" sm="10">
        <h4 class="text-h6 mb-1">
          {{ $t("private.results.imputation.bibliography.title") }}
        </h4>
        <section
          class="koko-u__v-html koko-u__v-html--caption"
          v-html="technicalCitations"
        />
      </v-col>
    </v-row>
    <v-row justify="center" class="pb-12" v-if="noResults">
      <v-col cols="10">
        <v-alert text type="info">
          {{ $t("private.pagehome.noResults.title") }}
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/services";
import { mapGetters } from "vuex";
import TableTechReport from "@/components/table/TableTechReport";
import MobileTableTechReport from "@/components/table/MobileTableTechReport";
import KokoBreadcrumbs from "@/components/breadcrumbs/KokoBreadcrumbs";

export default {
  name: "HealthDetailComponent",
  components: {
    TableTechReport,
    MobileTableTechReport,
    KokoBreadcrumbs,
  },
  props: {
    idHealth: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    data: null,
    info: null,
    results: null,
    noResults: false,
    loading: true,
    pdfFileExists: false,
    downloadingPDF: false,
    technicalCitations: null,
    technicalDescription: null,
    itemStatus: null,
    sections: [
      { key: "overview" },
      { title: "private.common.reportTab.symptoms", key: "symptoms" },
      { title: "private.common.disease.management", key: "management" },
      { title: "private.common.geneticbasis", key: "geneticbasis" },
      {
        title: "private.common.reportTab.technicalReport",
        key: "technicalreport",
      },
      { title: "private.common.affected.breeds", key: "breeds" },
    ],
  }),

  created() {
    this.getResultsHealthReport();
  },

  mounted() {
    this.$nextTick(() => {
      window.scrollTo(0, 0);
    });
  },

  computed: {
    ...mapGetters(["getToken", "getUserKitData", "getHealthItems"]),

    items() {
      const items = [
        {
          text: this.$i18n.t("private.menu.health"),
          disabled: false,
          href: "/results/health",
          id: "koko-breadcrumbs_item--1",
        },
        {
          text: this.getText("name"),
          disabled: true,
          id: "koko-breadcrumbs_item--2",
        },
      ];
      return items;
    },
  },
  methods: {
    downloadPDF() {
      console.log("downloadPDF");
    },
    async getResultsHealthReport() {
      const token = this.getToken;
      const {
        user: { kit: barcode },
      } = this.getUserKitData;
      try {
        const response = await api.getResultsHealth(token, barcode);
        const { data } = response;
        if (data) {
          this.getTechnicalReportsTexts(data);
        }
      } catch (e) {
        const {
          response: { status },
        } = e;
        if (status === 401) {
          this.$store.commit("SET_LOGOUT");
          this.$router.push("/login");
          return false;
        }
        console.log(e.messsage);
      }
    },

    async getTechnicalReportsTexts(data) {
      const info = data.filter((e) => e.healthId === Number(this.idHealth));
      const infoIds = info.reduce(
        (i, e) => [...i, [`${e.healthId}.${e.idReport}.${e.name}`]],
        []
      );

      const { locale: lang } = this.$i18n;
      const idList = data.map((e) => `${e.healthId}`);
      const area = "health";
      const fullIdsList = [...idList, ...infoIds].flat();
      try {
        const citations = await api.getItemsAndReport(area, lang, fullIdsList);
        const { data } = citations;
        if (data) {
          this.$i18n.mergeLocaleMessage(lang, data);
          this.info = info;
          this.setDisplayData(data, lang);
        }
      } catch (err) {
        this.noResults = true;
        console.error(err);
      }
    },

    setDisplayData(data, lang) {
      const { idHealth } = this;
      const items = this.getHealthItems;
      const hasItem = items[lang].find((e) => e.id === Number(idHealth));
      if (hasItem) {
        this.itemStatus = hasItem.actions;
      }
      this.loading = false;
      this.results = true;
      const citationsKey = `technicalreport.health.${idHealth}.citations`;
      this.technicalCitations = data[citationsKey];
    },

    getText(type) {
      const id = this.idHealth;
      return this.$i18n.t(`item.health.${id}.${type}`);
    },

    setSectionClass(section) {
      const id = this.idHealth;
      const hasContent = this.$i18n.te(`item.health.${id}.${section.key}`);
      return hasContent ? "pt-3" : "d-none";
    },
  },
};
</script>
