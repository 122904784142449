<template>
  <v-row justify="center">
    <v-col cols="10" sm="6" md="5" lg="4">
      <div class="bloque-formulario-acceso bloque-formulario-login">
        <h1>{{ $t("private.pagechange.title") }}</h1>
        <p class="descripcion-bloque-formulario-acceso">
          {{ $t("private.pagechange.description") }}
        </p>
        <v-form
          id="passwordform"
          name="passwordform"
          ref="passwordform"
          label="private.pagechange.password"
          role="form"
          @submit.prevent="resetPassword()"
          lazy-validation
        >
          <div class="form-group">
            <div class="col-sm-12">
              <PasswordField
                id="password"
                :set-value="setValue"
                :rules="[requiredRules, passwordRules]"
              />
              <PasswordField
                id="passwordConfirm"
                label="private.pagechange.confirm"
                :set-value="setValue"
                :rules="[requiredRules, passwordRules, passwordMatching]"
              />
            </div>
          </div>
          <div class="bloque-botones-acceso px-10 mb-4">
            <v-btn
              :loading="loading"
              :disabled="disabled"
              color="primary"
              large
              depressed
              block
              type="submit"
              class="mt-2"
            >
              {{ $t("private.pagechange.button.changepassword") }}
            </v-btn>
            <div v-if="error">
              <br />
              <p v-for="(err, index) in errors" :key="index" style="color: red">
                {{ $t(err) }}
              </p>
            </div>
          </div>
        </v-form>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import api from "@/services";
import { mapGetters } from "vuex";
import { loadedLanguages } from "../../lang";
import PasswordField from "@/components/form/PasswordField";

export default {
  name: "ResetPasswordForm",
  props: ["passwordresetcode"],
  components: {
    PasswordField,
  },
  computed: {
    ...mapGetters(["getUILanguage"]),
    errorRequired() {
      return this.$i18n.t("private.createaccount.pageTexts.requiredField");
    },
    requiredRules() {
      return (value) => !!value || this.errorRequired;
    },
    passwordRules() {
      return (v) => v.length > 4 || this.errorPassword;
    },
    passwordMatching() {
      return this.password == this.passwordConfirm || this.errorMatching;
    },
    errorMatching() {
      return this.$i18n.t("private.pageeditaccount.passwordDontMatch");
    },
    passwordOK() {
      return (
        this.password.length > 4 &&
        this.password.length < 21 &&
        this.password == this.passwordConfirm
      );
    },
    errorPassword() {
      return this.$i18n.t(
        "private.createaccount.pageTexts.passwordlength.info"
      );
    },
  },
  data() {
    return {
      languages: "",
      loading: false,
      disabled: true,
      error: false,
      passwordMatch: false,
      errors: [],
      password: "",
      passwordConfirm: "",
      loadedView: false,
    };
  },
  created() {
    this.languages = loadedLanguages;
  },
  watch: {
    getUILanguage() {
      this.$refs.passwordform.resetValidation();
    },
  },
  methods: {
    setValue(value, id) {
      this[id] = value;
      if (this.passwordOK) {
        return (this.disabled = false);
      }
      this.disabled = true;
    },
    async resetPassword() {
      this.validateForm();
      if (!this.error) {
        this.disabled = true;
        this.loading = true;
        try {
          const response = await api.resetPassword(
            this.passwordresetcode,
            this.password
          );
          const { status } = response;
          if (status === 200) {
            await this.$router.push("/");
          }
        } catch (e) {
          this.errors.push(e.response.data.message);
          this.error = true;
          this.disabled = false;
          this.loading = false;
        }
      }
    },
    validateForm() {
      this.errors = [];
      this.error = false;
      if (this.password.length < 4 || this.password.length > 20) {
        this.errors.push("private.createaccount.pageTexts.passwordlength.info");
        this.error = true;
      }
      if (this.password !== this.passwordConfirm) {
        this.errors.push("private.pageeditaccount.passwordDontMatch");
        this.error = true;
      }
    },
  },
};
</script>
