export const formRulesMixin = {
  data() {
    return {
      textRegEx: /^([^0-9]*)$/,
      mailRegEx: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/,
      phoneRegEx:
        /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/,
    };
  },
  computed: {
    errorMail() {
      return this.$i18n.t("private.error.email");
    },
    errorRequired() {
      const key = "private.createaccount.pageTexts.requiredField";
      return this.$te(key)
        ? this.$i18n.t(key)
        : this.$i18n.t("private.createaccount.pageTexts.requiredField");
    },
    errorPass() {
      return this.$i18n.t(
        "private.createaccount.pageTexts.passwordlength.info"
      );
    },
    errorPhone() {
      return this.$i18n.t("private.error.addphonenumber");
    },
    phoneRules() {
      const { phoneRegEx } = this;
      return (v) => !v || phoneRegEx.test(v) || this.errorPhone;
    },

    emailRules() {
      const { mailRegEx } = this;
      return (v) => !v || mailRegEx.test(v) || this.errorMail;
    },

    textRules() {
      const { textRegEx } = this;
      return (v) => !v || textRegEx.test(v) || "Error";
    },

    requiredRules() {
      return (value) => !!value || this.errorRequired;
    },

    passwordRules() {
      return (v) => v.length > 4 || this.errorPass;
    },
  },
};
