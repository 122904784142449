<template>
  <v-container>
    <TraitsDetailComponent :id-trait="idTrait" />
  </v-container>
</template>

<script>
import TraitsDetailComponent from "@/components/traits/TraitsDetailComponent";

export default {
  name: "TraitsViewDetail",
  props: {
    idTrait: {
      type: String,
      default: "",
    },
  },
  components: {
    TraitsDetailComponent,
  },
};
</script>
