<template>
  <v-text-field
    :id="id"
    :class="className"
    :value="value"
    :rules="rules"
    :label="translatedLabel"
    :error="errorValue"
    :error-messages="errorMsg"
    :disabled="disabled"
    :hint="hint"
    :persistent-hint="persistentHint"
    :append-icon="appendIcon"
    :type="fieldType"
    :maxlength="maxlength"
    required
    counter
    filled
    @click:append="showPassword = !showPassword"
    @input="setValue($event, id)"
  />
</template>
<script>
export default {
  name: "PasswordField",
  props: {
    id: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    hint: {
      type: String,
      default: "",
    },
    setValue: {
      type: Function,
      default: () => null,
    },
    rules: {
      type: Array,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: "koko-form__textfield",
    },
    label: {
      type: String,
      default: "private.createaccount.pageTexts.password",
    },
    errorValue: {
      type: Boolean,
      default: false,
    },
    errorMsg: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      maxlength: 20,
      showPassword: false,
      iconShow: "mdi-eye",
      iconHide: "mdi-eye-off",
    };
  },
  computed: {
    appendIcon() {
      const { showPassword, iconShow, iconHide } = this;
      return showPassword ? iconShow : iconHide;
    },
    fieldType() {
      const { showPassword } = this;
      return showPassword ? "text" : "password";
    },
    persistentHint() {
      return Boolean(this.hint);
    },
    translatedLabel() {
      return this.$i18n.t(this.label);
    },
  },
};
</script>
