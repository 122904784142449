<template>
  <aside v-if="hasSteps" :style="stepsNumber">
    <ul class="steps pl-0" :style="counter">
      <li
        v-for="(item, index) in list"
        :key="index"
        :class="getState(index)"
        :style="progressPos(index)"
      >
        <span class="step-msg">{{ $t(item.msg) }}</span>
      </li>
    </ul>
  </aside>
</template>

<script>
export default {
  name: "StepsComponent",
  props: {
    steps: {
      type: Array,
      default: null,
    },
    step: {
      type: [String, Number],
      default: 1,
    },
  },
  computed: {
    hasSteps() {
      return this.list && this.list.length > 0;
    },

    currentStep() {
      return Number(this.step);
    },
    list() {
      return [...this.steps].reverse();
    },
    counter() {
      const steps = [...this.list].length;
      const len = steps + 1;
      return `counter-reset: step ${len}`;
    },
    stepsNumber() {
      return `--steps-number: ${this.list.length - 1}`;
    },
  },

  methods: {
    getStr(str) {
      return this.$t(str).startsWith("private") ? "" : this.$t(str);
    },
    getState(index) {
      const stepNumber = this.list.length - index;
      let className = "default";
      if (stepNumber <= this.step) {
        className = stepNumber < this.currentStep ? "complete" : "active";
      }
      return `step ${className}`;
    },
    progressPos(index) {
      const percent = Math.floor((100 / (this.list.length - 1)) * index);
      return `--progress-pos: ${100 - percent}%`;
    },
  },
};
</script>

<style lang="scss">
:root {
  --steps-number: 2;
  --progress-pos: 2.5em;
  --progress-height: 2px;
  --step-size: 2em;
  --active-color: #42ac55;
  --complete-color: #0d0d3f;
  --step-hue: 131;
  --step-sat: 5%;
  --step-lum: 70%;
}

.steps {
  counter-reset: step;
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-direction: row-reverse;
  box-sizing: border-box;

  .step {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    color: hsl(var(--step-hue), var(--step-sat), var(--step-lum));
  }

  .step.active {
    --step-sat: 45%;
    --step-lum: 47%;
  }

  .step.complete::before {
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='0 0 24 24'><path d='M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z'/></svg>");
  }

  .step.complete {
    --step-hue: 240;
    --step-sat: 66%;
    --step-lum: 15%;
  }

  .step.complete::before,
  .step.complete::after {
    color: transparent;
    display: block;
    box-shadow: 0 0 1px 2px white;
  }

  .step.complete::after {
    opacity: 1;
  }

  .step:not(:first-child):not(:last-child) {
    flex-grow: 1;
  }

  .step::before {
    display: flex;
    counter-increment: step -1;
    content: counter(step);
    background-color: hsl(var(--step-hue), var(--step-sat), var(--step-lum));
    color: white;
    border-radius: 50%;
    height: var(--step-size);
    width: var(--step-size);
    align-items: center;
    justify-content: center;
    z-index: 1;
    align-self: center;
    box-sizing: border-box;
    box-shadow: 0 0 0 3px;
  }
  .step-msg {
    width: 8em;
    display: block;
    text-align: center;
    line-height: 1.1;
    margin-top: 0.5em;
    font-size: 0.88em;
  }
  .step::after {
    content: "";
    width: calc((100% / var(--steps-number)) - 2.5em);
    display: none;
    position: absolute;
    background-color: hsl(var(--step-hue), var(--step-sat), var(--step-lum));
    top: 0.95em;
    left: var(--progress-pos);
    height: var(--progress-height);
    transform-origin: center left;
    transition: transform 0.2s 0.5s;
  }
  .step:first-child::after {
    display: none;
  }
  .step:last-child::after {
    margin-left: 3em;
  }
}

.steps::before {
  content: "";
  width: calc(100% - 6em);
  display: block;
  position: absolute;
  background-color: hsl(var(--step-hue), var(--step-sat), var(--step-lum));
  top: 0.95em;
  left: 3em;
  height: var(--progress-height);
}

/* @keyframes growX {
  to {
    transform: scaleX(1);
  }
}
@keyframes growY {
  to {
    transform: scaleY(1);
  }
} */

@media screen and (max-width: 600px) {
  .steps {
    flex-direction: column-reverse;
    .step {
      flex-direction: row;
      padding-bottom: 1em;
      padding-left: 1em;
      .step-msg {
        max-width: 20em;
        width: calc(100% - 4em);
        text-align: left;
        margin: 0;
        padding: 1em;
      }
    }
  }
  .steps .step::after {
    width: var(--progress-height);
    height: calc(100% / var(--steps-number));
    top: var(--progress-pos);
    left: 1.93em;
  }
  .steps .step:last-child::after {
    margin-left: 0;
    margin-top: 2em;
  }

  .steps::before {
    width: var(--progress-height);
    height: calc(100% - 4em);
    left: 1.93em;
    top: 2em;
  }
}
</style>
