<template>
  <section v-if="hasSteps">
    <v-row justify="center">
      <v-col cols="11" sm="9" mb="7" lg="6">
        <h4 class="text-h6 font-weight-regular text-center line--1-2 mb-6">
          {{ $t(`${pageKey}.cautions`) }}
        </h4>

        <v-alert
          dense
          text
          type="info"
          class="px-6 pt-6 pb-4 mb-8"
          :icon="false"
        >
          <p
            v-for="caution in cautions"
            :key="caution"
            class="text-body-2 line--1-2 text-wrap--pretty mb-2"
          >
            {{ $t(caution) }}
          </p>
        </v-alert>

        <section class="koko-instructions mb-n12">
          <h4 class="text-h5 text-center mb-10 line--1-2 mt-4">
            {{ $t(`private.welcome.common.instructions`) }}
          </h4>
          <article
            v-for="step in stepsLength"
            :key="`step-instruction_${step}`"
            class="koko-instructions__step pb-8 mb-8"
          >
            <figure class="koko-instructions__figure">
              <img :src="imageExists(step)" width="150" height="150" />
            </figure>
            <aside class="koko-instructions__text text-body-2 line--1-2 pb-0">
              <span v-html="$t(`${msgKey}${step}`)" />
            </aside>
            <span class="koko-instructions__number">{{ step }}</span>
          </article>
        </section>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  name: "KitInstructions",

  props: {
    instructions: {
      type: Object,
      default: () => null,
    },
  },
  data: () => ({
    stepsNumber: 10,
  }),

  computed: {
    hasSteps() {
      const { instructions } = this;
      return Boolean(instructions);
    },

    stepsLength() {
      const {
        instructions: { stepsLength },
      } = this;
      return stepsLength;
    },

    msgKey() {
      const {
        instructions: { msgKey },
      } = this;
      return msgKey;
    },

    svgKey() {
      const {
        instructions: { svgKey },
      } = this;
      return svgKey;
    },

    pageKey() {
      const {
        instructions: { pageKey },
      } = this;
      return pageKey;
    },

    cautions() {
      const {
        instructions: { cautions },
      } = this;
      return cautions;
    },
  },
  methods: {
    imageExists(step) {
      const { svgKey } = this;
      const image = `${svgKey}${step}.svg`;
      try {
        const imgFile = require(`@/assets/img/welcome/${image}`);
        return imgFile;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
  },
};
</script>

<style>
.koko-instructions {
  box-sizing: border-box;
  position: relative;
  counter-reset: instructions-counter;
}

.koko-instructions__step {
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 1.5em;
  grid-gap: 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.koko-instructions__step:nth-last-of-type(1) {
  border-bottom-width: 0;
}

.v-application .koko-instructions__step p {
  margin-bottom: 8px;
}

.v-application .koko-instructions__step p:nth-last-of-type(1) {
  margin-bottom: -11px;
}

.koko-instructions__figure,
.koko-instructions__number {
  grid-column: 1;
  grid-row: 1;
}

.koko-instructions__figure {
  display: flex;
  width: 150px;
  height: 150px;
  align-items: center;
  justify-content: center;
  background-color: #0d0d3f;
}

.koko-instructions__number {
  display: inline-block;
  font-size: 0.85em;
  color: white;
  width: 1.85em;
  height: 1.9em;
  background: #42ac55;
  border-end-end-radius: 1em;
  text-align: center;
  padding-right: 0.17em;
  padding-top: 0.2em;
  user-select: none;
  position: relative;
}

.koko-instructions__title {
  font-size: 1.1rem;
  font-weight: 600;
  letter-spacing: -0.01em;
}

.koko-instructions__text {
  line-height: 1.2;
  padding-bottom: 1em;
  text-wrap: pretty;
}

article.koko-instructions__text:has(ul) ul {
  padding-inline-start: 17px;
  margin-bottom: 4px;
}
@media (min-width: 500px) {
  .koko-instructions__step {
    grid-template-columns: 150px 1fr;
    margin-bottom: 3em;
  }
}

.koko-instructions--noimages .koko-instructions__figure {
  display: none;
}

.koko-instructions--noimages .koko-instructions__step {
  display: block;
  padding: 0.25em;
  counter-increment: instructions-counter;
  margin: 0 0 0.5em;
}

.koko-instructions--noimages .koko-instructions__number {
  display: none;
}

.koko-instructions--noimages .koko-instructions__text {
  padding: 0 0 0 1em;
  text-indent: -1em;
}

.koko-instructions--noimages .koko-instructions__text::before {
  content: counter(instructions-counter) ". ";
  font-weight: 600;
}
</style>
