<template>
  <v-simple-table :class="tableClass">
    <thead>
      <tr>
        <th
          v-for="(header, h) in headers"
          :key="`${h}_th`"
          :class="header.className"
        >
          {{ $t(header.name) }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(item, i) in itemsList"
        :key="`${i}_td`"
        class="tr--selectable"
        :id="itemID(i)"
        @click="onClick(item)"
      >
        <td>
          <label>
            <span>{{ $t(item.name) }}</span>
            <v-chip
              v-if="hasStatus(item)"
              x-small
              :class="itemStatusClass(item)"
            >
              {{ itemStatusName(item) }}
            </v-chip>
          </label>
        </td>
        <td :class="itemActionsClass(item)">
          <span>
            {{ $t(item.actions) }}
            <v-icon class="pl-2 d-inline-flex" dense>
              mdi-chevron-right
            </v-icon>
          </span>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>
<script>
export default {
  name: "DesktopItemsTable",
  props: {
    items: {
      type: Array,
      default: () => null,
    },
    tableProps: {
      type: Object,
      default: () => null,
    },
    onClick: {
      type: Function,
      default: () => null,
    },
  },
  data: () => ({
    itemsList: [],
    headers: null,
    tableClass: null,
    expectedStatus: ["CREATED"],
    commonID: "koko-results-desktop__table--",
  }),

  watch: {
    items: function (newVal) {
      if (newVal) {
        this.setItemsList(newVal);
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setItemsList(this.items);
      this.setTableprops();
    });
  },

  methods: {
    setItemsList(items) {
      // this.itemsList = items.map((e) => ({ ...e, status: "CREATED" })); //test chips;
      this.itemsList = items;
    },

    itemID(order) {
      const { commonID } = this;
      const specificID = `item-${order + 1}`;
      return `${commonID}${specificID}`;
    },

    actionsName(item) {
      const { actions } = item;
      return this.$i18n.te(actions) ? this.$i18n.t(actions) : "····";
    },

    setTableprops() {
      const { headers, tableClass } = this.tableProps;
      this.headers = headers;
      this.tableClass = tableClass;
    },

    hasStatus(item) {
      const { expectedStatus } = this;
      const { status } = item;
      return status && expectedStatus.includes(status);
    },

    itemStatusName(item) {
      const { status } = item;
      return status === "CREATED"
        ? this.$t("private.item.isNew")
        : this.$t("private.item.isUpdated");
    },

    itemStatusClass(item) {
      const { status } = item;
      const defaultClass = "green px-2 mx-2 pointer--none text-uppercase";
      const statusClass =
        status === "CREATED"
          ? "white--text font-weight-light"
          : "v-chip--outlined green--text";
      return `${defaultClass} ${statusClass}`;
    },

    itemActionsClass(item) {
      const { resalted } = item;
      const itemResalted = resalted;
      const defClass = `pr-2 col--actions deep-purple--text`;
      return itemResalted ? `${defClass} prominent` : `${defClass}`;
    },
  },
};
</script>
<style>
.koko-o-imputation__table .v-chip .v-chip__content {
  letter-spacing: 0.1em;
}
</style>
