import api from "@/services";
import { mapGetters } from "vuex";

export const reportMixin = {
  computed: {
    ...mapGetters(["getUserKitData", "getUILanguage", "getUserData"]),
  },
  methods: {
    async downloadReport(itemsRaw, type) {
      const { locale } = this.$i18n;
      const items = itemsRaw[locale].map((e) => {
        return { ...e, actions: this.$i18n.t(e.actions) };
      });
      const {
        user: { petName, kit: barcode },
      } = this.getUserKitData;
      const { firstName, lastName } = this.getUserData;
      const language = this.getUILanguage.toUpperCase();
      const headers = {
        header1: this.$i18n.t("private.common.name"),
        header2: this.$i18n.t("private.common.result"),
        header3: "",
        name: this.$i18n.t("private.common.name"),
        date: this.$i18n.t("private.common.date"),
        petName: this.$i18n.t("private.createaccount.pageTexts.petname"),
      };

      const name = `${firstName} ${lastName}`;
      const paramsToSend = {
        barcode,
        items,
        language,
        type,
        headers,
        name,
        petName,
      };

      try {
        const response = await api.getReport(paramsToSend);
        const { data } = response;
        const url = window.URL.createObjectURL(
          new Blob([data], { type: "application/pdf" })
        );
        let a = document.createElement("a");
        a.href = url;
        a.download = `${barcode}_${type}_report.pdf`;
        a.click();
        a.remove();
        setTimeout(() => window.URL.revokeObjectURL(url), 100);
        this.$emit("downloaded-pdf", false);
      } catch (error) {
        console.error("Response error: " + error);
      }
    },
  },
};
