<template>
  <fieldset class="item-group__options">
    <section
      v-for="(n, i) in sampleTypes"
      :key="i"
      class="item-group__option"
      v-ripple="{ class: 'white--text' }"
      @click="onSelectSampleType(n.value)"
    >
      <input
        :id="`sample-${i}`"
        type="radio"
        name="sample"
        class="item-group__radio"
        :value="n.value"
        :checked="hasSelected(n.value)"
      />

      <label
        color="primary"
        :for="`sample-${i}`"
        class="item-group__content justify-center"
      >
        <img
          class="item-group__image"
          :src="imageExists(n)"
          width="150"
          height="150"
        />
      </label>
      <p class="item-group__text my-3">
        {{ n.title }}
      </p>
    </section>
  </fieldset>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "SampleSelector",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getSampleType"]),

    sampleTypes() {
      const SAMPLE_TYPE_KEY = "private.welcome.sampleType";
      const swabSample = this.$i18n.t(`${SAMPLE_TYPE_KEY}.swab`);
      const rapiDriSwabSample = this.$i18n.t(`${SAMPLE_TYPE_KEY}.driswab`);
      return [
        {
          value: "swab",
          img: "swab.svg",
          title: swabSample,
        },
        {
          value: "rapiDriSwab",
          img: "rapi_dri_swab.svg",
          title: rapiDriSwabSample,
        },
      ];
    },
  },

  methods: {
    onSelectSampleType(sampleType) {
      this.$store.dispatch("setSampleType", { sampleType });
    },

    hasSelected(sampleType) {
      return this.getSampleType === sampleType;
    },

    imageExists(item) {
      const { img } = item;
      if (!img) {
        return false;
      }
      try {
        const imgFile = require(`@/assets/img/welcome/${img}`);
        return imgFile;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
  },
};
</script>
<style>
.item-group__options {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-columns: min-content;
  grid-gap: 1rem;
  padding: 1em 0 0;
  border: none;
}

.item-group__option {
  display: block;
}

.item-group__radio {
  display: none;
}

.item-group__content {
  display: flex;
  height: 220px;
  width: 100%;
  cursor: pointer;
  user-select: none;
  align-items: center;
  background-color: #f0f0f0;
  overflow: hidden;
  border-radius: 0.3em 0.3em 0 0;
  border-bottom: 1px solid #888;
  transition: background-color 0.2s;
}

.item-group__content:hover {
  background-color: #e0e0e0;
  border-color: #666;
}

.item-group__radio:checked + .item-group__content {
  background-color: #1976d2;
  border-color: #1976d2;
}

.item-group__text {
  font-size: 0.9em;
  text-align: center;
  opacity: 0.5;
  hyphens: none;
}

.item-group__radio:checked + .item-group__content + .item-group__text {
  opacity: 1;
  color: #1976d2;
}

.item-group__image {
  display: block;
  width: 150px;
  max-width: 100%;
  height: auto;
}

.tmg-welcome__languages {
  grid-column: 3;
  max-width: 200px;
  padding-top: 2rem;
  margin: 0 auto;
}
</style>
