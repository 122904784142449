<template>
  <v-row justify="center">
    <v-col cols="12" class="pa-0">
      <KokoBreadcrumbs :items="items" />
    </v-col>
    <v-col cols="12">
      <h2 class="text-h6 mb-0 text-center">
        {{ $t("private.form.deleteAccount") }}
      </h2>
      <h4 class="text-subtitle-1 text-center mt-1 red--text font-weight-medium">
        {{ $t("private.form.confirmation.deleteAccount") }}
      </h4>
    </v-col>
    <v-col cols="11" sm="8" md="6" lg="4" class="pb-2">
      <v-form :ref="formRef" lazy-validation>
        <PasswordField
          id="password"
          :value="password"
          :set-value="setValue"
          :disabled="fieldsDisabled"
          label="private.createaccount.pageTexts.password"
          :rules="[requiredRules, passwordRules]"
        />
      </v-form>
    </v-col>
    <v-col cols="12" class="pt-0 mb-4 text-center">
      <v-btn
        :loading="loading"
        :disabled="disabled"
        color="error"
        depressed
        class="mr-4"
        @click="deleteAccount"
      >
        {{ $t("private.form.deleteAccount") }}
      </v-btn>
      <!--   <v-btn depressed :to="'/settings'" color="primary">
        {{ $t("private.form.cancel") }}
      </v-btn> -->
    </v-col>
    <v-col v-if="error" cols="12" md="4" class="mt-1 text-center">
      <p
        v-for="(error, e) in errors"
        :key="`error_${e}`"
        class="my-0 text-caption red--text line--1-2"
      >
        {{ $t(error) }}
      </p>
    </v-col>
  </v-row>
</template>

<script>
import api from "@/services";
import { mapGetters } from "vuex";
import { formRulesMixin } from "@/mixins/formRulesMixin";
import PasswordField from "@/components/form/PasswordField";
import KokoBreadcrumbs from "@/components/breadcrumbs/KokoBreadcrumbs";

export default {
  name: "DeleteAccount",
  components: {
    PasswordField,
    KokoBreadcrumbs,
  },
  mixins: [formRulesMixin],

  data() {
    return {
      formRef: "deleteForm",
      loading: false,
      disabled: true,
      fieldsDisabled: false,
      error: false,
      errors: [],
      password: "",
    };
  },

  computed: {
    ...mapGetters(["getToken", "getLoginWithToken"]),
    items() {
      const items = [
        {
          text: this.$i18n.t("private.header.menus.settings"),
          disabled: false,
          to: "/settings",
          id: "koko-breadcrumbs_item--1",
        },
        {
          text: this.$i18n.t("private.form.deleteAccount"),
          disabled: true,
          id: "koko-breadcrumbs_item--2",
        },
      ];
      return items;
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.setDefaultValues();
    });
  },

  methods: {
    setDefaultValues() {
      const { getLoginWithToken } = this;
      this.fieldsDisabled = Boolean(getLoginWithToken);
    },

    setValue(value) {
      const disabled = value.length < 4;
      this.disabled = disabled;
      this.password = disabled ? "" : value;
    },

    logout() {
      this.$store.commit("SET_LOGOUT");
      this.$router.push("/login");
    },

    async deleteAccount() {
      const { password, getToken: token } = this;
      this.fieldsDisabled = true;
      this.disabled = true;
      this.loading = true;
      try {
        const isAllowed = await api.deleteAccount({ password, token });
        if (isAllowed) {
          this.logout();
        }
      } catch (e) {
        this.password = "";
        this.$router.push("/settings");
      }
    },
  },
};
</script>
