<template>
  <v-dialog v-model="showChat" content-class="koko-dna__room">
    <v-card flat tile class="koko-dna__room-content" :id="chatID">
      <v-toolbar color="#0d0d3f" dark flat>
        <v-toolbar-title class="text-h5">
          {{ $t("private.peoplefinder.messages.name") }}
        </v-toolbar-title>

        <v-btn icon @click="showChat = false" :id="closeBtnID">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="koko-dna__room-messages" :ref="roomRef">
        <p v-if="loadingMessages" class="text-center mt-12 text--secondary">
          {{ $t("private.common.loadingText") }}
        </p>
        <p v-if="firstMessage" class="koko-dna__room--firstMessage">
          {{ $t("private.dnaconnect.newmessage.subtitle") }}
        </p>

        <DNAConnectChatItem
          v-for="(message, i) in roomMessages"
          :key="`message-${i}`"
          :message="message"
        />
      </v-card-text>
      <v-card-actions class="koko-dna__room-actions">
        <v-text-field
          :id="inputTextID"
          class="koko-dna__room-input"
          color="indigo"
          ref="inputMessage"
          outlined
          hide-details
          clearable
          v-model="message"
          :disabled="isLoading"
          type="text"
          :placeholder="placeholder"
          append-icon="mdi-send"
          @click:append="sendMessage"
          @keyup.enter="sendMessage"
          @click:clear="clearMessage"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/services";
import { mapGetters } from "vuex";
import DNAConnectChatItem from "./DNAConnectChatItem";

export default {
  name: "DNAConnectRoom",
  components: {
    DNAConnectChatItem,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => null,
    },
    openChat: { type: Function, default: () => null },
  },
  emits: ["close-chat"],

  data() {
    return {
      showChat: false,
      firstMessage: false,
      message: "",
      loadingMessages: true,
      isLoading: true,
      roomMessages: [],
      idRoom: 0,
      cardID: "koko-dnaconnect__room--",
      closeID: "koko-dnaconnect__room-close--",
      textfieldID: "koko-dnaconnect__room-input--",
    };
  },

  created() {
    const {
      show,
      item: { idRoom },
    } = this;
    this.idRoom = idRoom ?? 0;
    this.showChat = show;
  },
  mounted() {
    this.$nextTick(() => {
      const { show } = this;
      this.showChat = show;
    });
  },

  watch: {
    show(newValue) {
      this.showChat = newValue;
    },
    showChat(newValue) {
      if (newValue) {
        this.getRoomMessages();
        return true;
      }
      this.$emit("close-chat");
    },
  },

  computed: {
    ...mapGetters(["getToken", "getUserKitData"]),

    inputTextID() {
      const {
        textfieldID,
        item: { itemID },
      } = this;
      return `${textfieldID}${itemID}`;
    },

    closeBtnID() {
      const {
        closeID,
        item: { itemID },
      } = this;
      return `${closeID}${itemID}`;
    },

    chatID() {
      const {
        cardID,
        item: { itemID },
      } = this;
      return `${cardID}${itemID}`;
    },

    roomRef() {
      const { idRoom } = this;
      return `room_${idRoom}`;
    },

    placeholder() {
      return this.$i18n.t("private.peoplefinder.sendmessage.name");
    },
  },
  methods: {
    async getRoomMessages() {
      const { idRoom, getToken: token } = this;

      const params = { idRoom, token };
      if (idRoom === 0) {
        this.isLoading = false;
        this.loadingMessages = false;
        this.firstMessage = true;
        return false;
      }
      try {
        const response = await api.getChatRoom(params);
        const { status, data } = response;
        if (status === 200) {
          const { messages } = data;
          this.setRoomMessages(messages);
        }
      } catch (e) {
        console.log(e);
      }
    },

    setRoomMessages(messages) {
      this.roomMessages = messages;
      this.loadingMessages = false;
      this.isLoading = false;
      this.scrollToBottom();
    },

    scrollToBottom() {
      const { roomRef } = this;
      const roomChat = this.$refs[roomRef];
      setTimeout(() => {
        roomChat.scrollTo(0, roomChat.scrollHeight);
      }, 100);
    },

    async sendMessage() {
      if (this.message === "") {
        return false;
      }
      const {
        message: text,
        getToken: token,
        getUserKitData: {
          user: { account_id },
        },
        item: { id: receiver },
      } = this;

      const sender = Number(account_id);
      const params = { sender, receiver, text, token };
      this.isLoading = true;
      try {
        const response = await api.sendMessage(params);
        const { status, data } = response;
        if (status === 200) {
          const { id: idRoom } = data;
          this.idRoom = idRoom;
          this.firstMessage = false;
          this.addNewMessage(data);
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.clearMessage();
      }
    },

    addNewMessage(data) {
      const { roomMessages } = this;
      const { messages } = data;
      this.roomMessages = [...roomMessages, ...messages];
      this.scrollToBottom();
    },

    clearMessage() {
      this.message = "";
      this.isLoading = false;
      const input = this.$refs["inputMessage"];
      setTimeout(() => {
        input.focus();
      }, 300);
    },
  },
};
</script>

<style>
.koko-dna__room {
  --max-width: 50vw;
  --border-radius: 4px;
  --messages-height-factor: calc(30vh + 6rem);
  --room-bg: #f2f3ff;
  --size: 130;
  width: var(--max-width);
  max-width: var(--max-width) !important;
  margin: 0 auto;
  border-radius: var(--border-radius);
}

.koko-dna__room--firstMessage {
  display: flex;
  align-items: self-end;
  justify-content: center;
  height: 100%;
  font-size: 1.25rem;
  margin-bottom: 0 !important;
  padding: 0.2rem;
  font-weight: 400;
  user-select: none;
  line-height: 1.2;
  opacity: 0.3;
}

.v-application--is-ltr
  .koko-dna__room
  .v-toolbar__content
  > .v-btn.v-btn--icon:last-child {
  margin-right: 0;
  position: absolute;
  top: 8px;
  right: 8px;
}

.koko-dna__room .v-toolbar__content {
  justify-content: center;
}

.koko-dna__room-content {
  padding-bottom: 1rem;
}

.koko-dna__room-actions {
  padding: 0 2rem;
}

.v-dialog > .v-card > .v-card__actions.koko-dna__room-actions {
  padding: 1rem 2rem;
  background-image: linear-gradient(
    90deg,
    transparent,
    transparent 1rem,
    var(--room-bg) 1rem,
    var(--room-bg) calc(100% - 1rem),
    transparent calc(100% - 1rem),
    transparent 100%
  );
}
.koko-dna__room-input.v-text-field.v-text-field--enclosed:not(
    .v-text-field--rounded
  )
  > .v-input__control
  > .v-input__slot {
  background-color: white;
  padding: 0 8px 0 16px;
}

.koko-dna__room-input .v-icon.v-icon {
  font-size: 18px;
}

.koko-dna__room .v-navigation-drawer__content {
  overflow: hidden;
}

.koko-app .koko-dna__room .theme--light.v-card > .v-card__text {
  height: calc(100vh - var(--messages-height-factor));
  background-color: var(--room-bg);
  margin: 1rem auto 0;
  padding: 0 1rem;
  overflow-y: auto;
  max-width: calc(100% - 2rem);
  opacity: 1;
}

.koko-dna__room-messages {
  --webkit-scrollbar-thumb: rgb(121, 126, 185);
  --webkit-scrollbar-size: 7px;
  scroll-behavior: smooth;
  scrollbar-width: thin;
}

.koko-dna__room .v-card__title {
  background-color: #0d0d3f;
}

@media (max-width: 961px) {
  .koko-dna__room {
    --messages-height-factor: 260px;
    --max-width: 90vw;
    --size: 140;
  }
}

@media (max-width: 945px) {
  .koko-dna__room {
    --max-width: 90vw;
  }
}

@media (max-width: 640px) {
  .koko-dna__room {
    --max-width: 100vw;
    --border-radius: 0;
    --messages-height-factor: 11.5rem;
    --size: 120;
    overflow: hidden;
  }
  .koko-dna__room.v-dialog:not(.v-dialog--fullscreen) {
    max-height: 100%;
  }
  .koko-dna__room .v-card {
    height: 99vh;
  }
}

@media (max-width: 460px) {
  .koko-dna__room {
    --messages-height-factor: 225px;
    --size: 90;
  }
  .koko-dna__room.v-dialog:not(.v-dialog--fullscreen) {
    max-height: 95vh;
  }
}
</style>
