<template>
  <aside :class="avatarClass" :style="avatarStyles">
    <svg class="koko-pet-symbol__image" xmlns="http://www.w3.org/2000/svg">
      <use :xlink:href="symbolId" />
    </svg>
  </aside>
</template>

<script>
export default {
  name: "PetDefaultAvatar",
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    invert: {
      type: Boolean,
      default: false,
    },
    noShadow: {
      type: Boolean,
      default: true,
    },
    shadowColor: {
      type: String,
      default: "rgba(13, 13, 63, 0.25)",
    },
    size: {
      type: String,
      default: "150",
    },
  },
  data: () => ({
    symbolId: "#breeds",
  }),
  computed: {
    avatarStyles() {
      const { size, shadowColor } = this;
      return {
        "--size": `${size}`,
        "--avatar-shadow-color": `${shadowColor}`,
      };
    },

    avatarClass() {
      const { dark, invert, noShadow: noshadow } = this;
      const defaultClass = "koko-pet-symbol";
      const styleOptions = { dark, invert, noshadow };
      const aditionalClasses = Object.entries(styleOptions)
        .filter((e) => e[1])
        .reduce((i, a) => [...i, `${defaultClass}--${a[0]}`], []);
      return [defaultClass, ...aditionalClasses].join(" ");
    },
  },
};
</script>
