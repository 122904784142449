<template>
  <v-container>
    <UpdateProfile />
  </v-container>
</template>
<script>
import UpdateProfile from "@/components/update/UpdateProfile.vue";

export default {
  name: "UpdateProfileView",

  components: {
    UpdateProfile,
  },
};
</script>
