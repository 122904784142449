<template>
  <BreedComponent />
</template>

<script>
import BreedComponent from "@/components/breed/BreedComponent";

export default {
  name: "BreedView",
  components: { BreedComponent },
};
</script>
