<template>
  <v-container class="pb-11">
    <v-row>
      <v-col
        cols="12"
        sm="4"
        md="6"
        offset-sm="4"
        offset-md="3"
        class="text-center pb-0 pb-md-4"
      >
        <h1 class="text-h5 text-center">
          {{ $t("private.menu.health") }}
        </h1>
      </v-col>
      <v-col
        v-if="pdfFileExists"
        cols="12"
        sm="4"
        md="3"
        class="text-center text-sm-right"
      >
        <v-btn
          id="koko-health__button--downloadPdf"
          :loading="downloadingPDF"
          color="primary"
          x-small
          depressed
          class="koko-download-pdf__button mx-4"
          @click="downloadPDF"
        >
          {{ $t("private.common.downloadPDF.button") }}
          <v-icon dark x-small class="ml-1"> mdi-tray-arrow-down </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col
        cols="12"
        sm="8"
        md="6"
        lg="5"
        xl="3"
        class="text-center px-6"
        v-if="healthItems"
      >
        <v-autocomplete
          id="koko-health__select--search"
          v-model="searchModel"
          class="koko-o-search"
          clearable
          :items="healthItems"
          filled
          :label="$t('private.common.text.search')"
          single-line
          hide-details
          append-icon="mdi-magnify"
        />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="11" v-if="healthItems">
        <aside class="d-none d-sm-block mt-2 px-3">
          <DesktopItemsTable
            :items="filteredItems()"
            :table-props="tableProps"
            :on-click="gotoPage"
          />
        </aside>
        <aside class="d-block d-sm-none px-2">
          <MobileItemsTable
            :items="filteredItems()"
            :table-props="tableProps"
            :on-click="gotoPage"
          />
        </aside>
      </v-col>
      <v-col cols="12" sm="11" md="10" lg="8" v-if="loading">
        <p class="text-body-2 text--disabled text-center mt-2 mb-n14">
          {{ $t("private.common.loadingText") }}
        </p>
        <v-skeleton-loader class="my-4" type="image" />
      </v-col>
      <v-col cols="12" sm="11" md="10" lg="8" v-if="noResults">
        <v-alert text type="info">
          {{ $t("private.pagehome.noResults.title") }}
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/services";
import { mapGetters } from "vuex";
import { reportMixin } from "@/mixins/reportMixin";
import { sortLocaleMixin } from "@/mixins/sortLocaleMixin";
import DesktopItemsTable from "@/components/table/DesktopItemsTable";
import MobileItemsTable from "@/components/table/MobileItemsTable";

export default {
  name: "HealthComponent",
  components: {
    DesktopItemsTable,
    MobileItemsTable,
  },
  mixins: [reportMixin, sortLocaleMixin],
  data() {
    return {
      variants: {
        VARIANT_PRESENT_COPY_2: 4,
        VARIANT_PRESENT_COPY_1: 3,
        VARIANT_PRESENT: 2,
        VARIANT_ABSENT: 1,
      },
      data: null,
      loading: true,
      healthItems: null,
      noResults: false,
      formattedList: null,
      itemKey: "health",
      searchModel: null,
      downloadingPDF: false,
      pdfFileExists: true,
    };
  },

  created() {
    this.$on("downloaded-pdf", (data) => {
      this.downloadingPDF = data;
    });
  },

  mounted() {
    this.$nextTick(() => {
      this.setDefaultValues();
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    });
  },

  computed: {
    ...mapGetters(["getToken", "getUserKitData", "getHealthItems"]),

    tableProps() {
      const { itemKey } = this;
      const headers = [
        { className: "", name: "private.common.name" },
        {
          className: "col--min",
          name: "private.common.result",
        },
      ];
      return {
        itemKey,
        tableClass: "koko-o-imputation__table",
        actionsClassName: "col--actions",
        headers: headers,
      };
    },
  },
  methods: {
    downloadPDF() {
      this.downloadingPDF = true;
      this.downloadReport(this.getHealthItems, "HEALTH");
    },
    setDefaultValues() {
      const { locale } = this.$i18n;
      const localeItems = this.getHealthItems;
      if (!localeItems) {
        this.getResultsHealth();
        return false;
      }
      const hasItemsByLang = Object.hasOwn(localeItems, locale);
      if (hasItemsByLang) {
        const itemsList = localeItems[locale];
        this.formattedList = itemsList;
        this.healthItems = this.getItemNames(itemsList);
        this.loading = false;
        return true;
      }
      this.getResultsHealth();
    },

    async getResultsHealth() {
      const token = this.getToken;
      const {
        user: { kit: barcode },
      } = this.getUserKitData;

      try {
        const response = await api.getResultsHealth(token, barcode);
        const { data } = response;
        if (data) {
          this.setDisplayData(data);
        }
      } catch (e) {
        if (!e.response) {
          return false;
        }
        const {
          response: { status },
        } = e;
        if (status === 401) {
          this.$store.commit("SET_LOGOUT");
          this.$router.push("/login");
          return false;
        }
        if (status === 406) {
          this.noResults = true;
          this.loading = false;
        }
      }
    },

    setDisplayData(data) {
      const { itemKey } = this;
      const items = [...data].reduce(
        (i, e) => [
          ...i,
          {
            itemId: e.healthId,
            id: e.healthId,
            name: this.$i18n.t(`item.${itemKey}.${e.healthId}.name`),
            idResponse: e.idResponse,
            actions: `item.healthstatus.${e.idResponse}`,
            resalted: this.resaltedItem(e),
            status: e.status,
          },
        ],
        []
      );
      this.createItemList(items);
    },
    resaltedItem(item) {
      const { variants } = this;
      const { idResponse } = item;
      const resaltedKey = "PRESENT";
      const value = Object.entries(variants)
        .filter((e) => e[1] === idResponse)[0][0]
        .includes(resaltedKey);
      return value;
    },

    createItemList(items) {
      const { variants } = this;
      const orderedList = Object.values(variants)
        .map((e) => {
          const filtered = items.filter((r) => r.idResponse === e);
          return this.sortItems(filtered);
        })
        .flat();
      this.addTranslations(orderedList);
    },

    setItems(items) {
      const { locale } = this.$i18n;
      this.formattedList = items;
      this.noResults = !items.length;
      const itemsToCommit = {};
      itemsToCommit[locale] = items;
      this.$i18n.mergeLocaleMessage(locale, items);
      this.$store.commit("SET_HEALTH_ITEMS", itemsToCommit);
      this.loading = false;
    },

    getItemNames(items) {
      return [...items].reduce((i, e) => [...i, e.name], []);
    },

    addTranslations(items) {
      const itemsNames = this.getItemNames(items);
      this.healthItems = itemsNames;
      this.setItems(items);
    },

    gotoPage(item) {
      const { itemId } = item;
      this.$router.push({
        path: `/results/health/${itemId}`,
      });
    },

    filteredItems() {
      if (this.searchModel) {
        return this.formattedList.filter((e) => e.name === this.searchModel);
      }
      return this.formattedList;
    },
  },
};
</script>
