<template>
  <section class="koko-instructions">
    <SendInstructionsIcons />

    <h3 class="text-subtitle-1 text-center mb-1">
      {{ $t("private.welcome.common.proccess.sendsampleKoko") }}
    </h3>
    <h2 class="text-h5 text-center mb-12">
      {{ $t("private.shipment.instructions") }}
    </h2>
    <article
      v-for="(step, s) in steps"
      :key="`step-instruction_${s}`"
      class="koko-instructions__step pb-8 mb-8"
    >
      <figure class="koko-instructions__figure">
        <svg xmlns="http://www.w3.org/2000/svg">
          <use :xlink:href="`#${step.iconID}`" />
        </svg>
      </figure>
      <aside class="koko-instructions__text text-body-2 line--1-2 pb-0">
        <h4 class="koko-instructions__title mb-2 mx-1">
          {{ $t(step.titleKey) }}
        </h4>
        <article
          class="text-secondary koko-instructions__text ma-1 pb-0"
          v-html="$t(step.textKey)"
        />
      </aside>
      <span class="koko-instructions__number">{{ s + 1 }}</span>
    </article>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import SendInstructionsIcons from "@/components/instructions/SendInstructionsIcons";
export default {
  name: "SendInstructions",
  components: {
    SendInstructionsIcons,
  },
  data: () => ({
    USA_ID: "65",
    STEPS_DEFAULT: [
      {
        titleKey: "private.send.shipmentKoko.title",
        textKey: `private.send.shipmentKoko.text`,
        iconID: "shipment",
      },
      {
        titleKey: "private.send.printKoko.title",
        textKey: "private.send.printKoko.text",
        iconID: "print",
      },
      {
        titleKey: "private.send.backKoko.title",
        textKey: "private.send.backKoko.text",
        iconID: "back",
      },
    ],
    STEPS_USA: [
      {
        titleKey: "private.send.shipmentKoko.title",
        textKey: `private.send.shipmentKoko.eeuu.text`,
        iconID: "shipment",
      },
      {
        titleKey: "private.send.printKoko.eeuu.title",
        textKey: `private.send.printKoko.eeuu.text`,
        iconID: "ups",
      },
      {
        titleKey: "private.send.backKoko.title",
        textKey: "private.send.backKoko.text",
        iconID: "back",
      },
    ],
  }),
  computed: {
    ...mapGetters(["getUserCountry"]),

    isSelectedCountryUSA() {
      const { getUserCountry, USA_ID } = this;
      if (!getUserCountry) {
        return false;
      }
      return getUserCountry.id === USA_ID;
    },

    steps() {
      const { isSelectedCountryUSA, STEPS_DEFAULT, STEPS_USA } = this;
      return isSelectedCountryUSA ? STEPS_USA : STEPS_DEFAULT;
    },
  },
};
</script>
