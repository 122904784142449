<template>
  <v-dialog v-model="showProfile" content-class="koko-dna__room">
    <v-card flat tile class="koko-dna__room-content" :id="profileID">
      <v-toolbar color="#0d0d3f" dark flat>
        <v-toolbar-title class="text-h5">
          {{ $t("private.peoplefinder.profile") }}
        </v-toolbar-title>

        <v-btn icon @click="showProfile = false" :id="closeBtnID">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text
        v-if="loadingData"
        class="koko-dna__room-messages koko-dna__room-messages--profile"
      >
        <p class="text-center mt-12 text--secondary">
          {{ $t("private.common.loadingText") }}
        </p>
      </v-card-text>
      <v-card-text
        v-if="!loadingData"
        class="koko-dna__room-messages koko-dna__room-messages--profile"
      >
        <AvatarComponent :values="avatarValues" class="mb-4 mx-auto" />
        <h2 class="text-h5 text-center mt-3 mb-4 px-4">
          {{ petName }}
        </h2>
        <p class="text-subtitle-2 font-weight-regular mb-0">
          {{ $t("private.createaccount.pageTexts.gender") }}
        </p>
        <p class="text-subtitle-1">{{ getPetSex }}</p>
        <p class="text-subtitle-2 font-weight-regular mb-0">
          {{ $t("private.createaccount.pageTexts.user") }}
        </p>

        <p class="text-subtitle-1 mb-8">
          {{ userFullName }}
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/services";
import { mapGetters } from "vuex";
import AvatarComponent from "@/components/avatar/AvatarComponent";

export default {
  name: "DNAConnectProfile",
  components: {
    AvatarComponent,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => null,
    },
    openProfile: { type: Function, default: () => null },
  },
  emits: ["close-profile"],
  data() {
    return {
      avatarSize: "inherit",
      showProfile: false,
      loadingData: true,
      userFullName: "",
      userData: null,
      cardID: "koko-dnaconnect__profile--",
      closeID: "koko-dnaconnect__profile-close--",
    };
  },
  watch: {
    show(newValue) {
      this.showProfile = newValue;
    },
    showProfile(newValue) {
      if (newValue) {
        this.getProfileUserData();
        return true;
      }
      this.$emit("close-profile");
    },
  },
  computed: {
    ...mapGetters(["getToken"]),

    closeBtnID() {
      const {
        closeID,
        item: { itemID },
      } = this;
      return `${closeID}${itemID}`;
    },

    profileID() {
      const {
        cardID,
        item: { itemID },
      } = this;
      return `${cardID}${itemID}`;
    },

    avatarValues() {
      const { avatarSize: size, imageExists, image, initials } = this;
      return { size, imageExists, image, initials };
    },
    getPetSex() {
      const {
        item: {
          sex: { value },
        },
      } = this;
      const keyCapitalized = value.charAt(0).toUpperCase() + value.slice(1);
      const composedKey = `private.createaccount.pageTexts.gender${keyCapitalized}`;
      return this.$i18n.t(composedKey);
    },

    petName() {
      const {
        item: {
          petName: { value },
        },
      } = this;
      return value ? value : "Anonymous Pet";
    },

    initials() {
      const { petName } = this;
      return petName.trim().slice(0, 1).toUpperCase();
    },

    imageExists() {
      const {
        item: {
          profilePic: { value },
        },
      } = this;
      return Boolean(value);
    },
    image() {
      const {
        item: {
          profilePic: { value },
        },
      } = this;
      return value ? value : "";
    },
  },
  methods: {
    getProfileUserData() {
      const {
        getToken: token,
        item: { id: userAccountId },
      } = this;
      this.getNewUserData(userAccountId, token);
    },

    async getNewUserData(userAccountId, token) {
      try {
        const response = await api.getUserData(userAccountId, token);
        const { data } = response;
        if (data) {
          this.setProfileUserData(data);
        }
      } catch (e) {
        console.log(e);
      }
    },

    setProfileUserData(data) {
      const { firstName, lastName } = data;
      this.userFullName = `${firstName} ${lastName}`;
      this.userData = data;
      this.loadingData = false;
    },
  },
};
</script>

<style>
.koko-dna__room-messages--profile {
  --room-bg: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
