<template>
  <LoginForm />
</template>
<script>
import LoginForm from "@/components/login/LoginForm";

export default {
  name: "LoginView",
  components: {
    LoginForm,
  },
};
</script>
