<template>
  <v-card class="mb-6 koko-settings__card">
    <v-card-title class="text-subtitle-1 px-5 mb-1 pt-3">
      RAW Data
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="8" class="px-4 py-0 mb-0">
          <v-btn
            v-if="urlRawData"
            color="primary"
            depressed
            :href="urlRawData"
            small
            target="_blank"
            class="mr-4 mb-4 overflow-ellipsis"
            id="koko-settings__btn--download-rawdata"
          >
            {{ $t("private.common.downloadRawData") }}
          </v-btn>
          <p v-if="!urlRawData" class="pb-2 mb-2 text--secondary">
            {{ $t("private.pagehome.menus.errorNoResults") }}
          </p>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import api from "@/services";
import { mapGetters } from "vuex";

export default {
  name: "SettingsDownloadRawData",

  data() {
    return {
      urlRawData: null,
    };
  },
  computed: {
    ...mapGetters(["getUserKitData", "getToken"]),
  },
  created() {
    this.getDownloadLink();
  },

  methods: {
    async getDownloadLink() {
      const token = this.getToken;
      const {
        user: { kit: barCode },
      } = this.getUserKitData;

      try {
        const response = await api.getRawData({ barCode, token });
        const { data, status } = response;
        if (status !== 200 || !data) {
          return false;
        }
        const { url } = data;
        if (!url.length) {
          return false;
        }
        this.urlRawData = url;
      } catch (e) {
        console.log("error");
      }
    },
  },
};
</script>
<style></style>
