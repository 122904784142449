<template>
  <v-app class="koko-app">
    <SimpleHeader />
    <v-main>
      <router-view />
      <PageFooter />
    </v-main>
  </v-app>
</template>
<script>
import SimpleHeader from "@/components/header/SimpleHeader";
import PageFooter from "@/components/footer/PageFooter";
export default {
  components: {
    SimpleHeader,
    PageFooter,
  },
};
</script>
