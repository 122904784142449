<template>
  <TraitsComponent />
</template>

<script>
import TraitsComponent from "@/components/traits/TraitsComponent";

export default {
  name: "TraitsView",
  components: { TraitsComponent },
};
</script>
