import axios from "axios";

const AXIOS = axios.create({
  baseURL: process.env.VUE_APP_KOKO_CORE_URL,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Access-Control-Allow-Origin": "*",
  },
});

const AXIOSI18N = axios.create({
  baseURL: process.env.VUE_APP_KOKO_I18N_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

const AXIOSREPORT = axios.create({
  baseURL: process.env.VUE_APP_KOKO_REPORTS_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

export default {
  getTextsInLanguage(language) {
    return AXIOSI18N.get(`/private/${language}`);
  },

  getItemTextsInLanguage(language) {
    return AXIOSI18N.get(`/byPattern/4/${language}/item`);
  },

  registryAccount(data) {
    return AXIOS.post("/api/v1/public/registryAccount", data);
  },

  getAccess(email, password) {
    return AXIOS.post(`/api/v1/login`, { username: email, password: password });
  },

  getUserKitData(token) {
    return AXIOS.get(`/api/v1/users/loginAccount`, {
      headers: { Authorization: token },
    });
  },

  getUserData(userAccountId, token) {
    return AXIOS.get(`/api/v1/accounts/${userAccountId}`, {
      headers: { Authorization: token },
    });
  },
  getUserKinships({ barcode, token }) {
    return AXIOS.get(`/api/v1/kinship/${barcode}`, {
      headers: { Authorization: token },
    });
  },

  sendMessage({ sender, receiver, text, token }) {
    return AXIOS.put(
      `/api/v1/message/send-message`,
      { sender, receiver, text },
      { headers: { Authorization: token } }
    );
  },

  getChatRoom({ idRoom, token }) {
    return AXIOS.get(`/api/v1/message/room/${idRoom}`, {
      headers: { Authorization: token },
    });
  },

  getRawData({ barCode, token }) {
    return AXIOS.get(`api/v1/kits/downloadRawData`, {
      headers: {
        Authorization: token,
      },
      params: {
        barCode,
      },
    });
  },

  getDNATree({ barcode, token }) {
    return AXIOS.get(`/api/v1/dna-tree/${barcode}`, {
      headers: { Authorization: token },
    });
  },

  changeLockedUser({ userId, blockedUser, value, token }) {
    return AXIOS.put(
      "/api/v1/kinship/blockedRelation",
      { userId, blockedUser, value },
      { headers: { Authorization: token } }
    );
  },

  changeAllowDNAConnect(body, token) {
    return AXIOS.put(`/api/v1/kits/changeAllowConnectPet`, body, {
      headers: { Authorization: token },
    });
  },

  changePassword({ accountUserId, newPassword, oldPassword, token }) {
    return AXIOS.post(
      `/api/v1/users/change-password`,
      { accountUserId, newPassword, oldPassword },
      { headers: { Authorization: token } }
    );
  },
  changeLanguage(accountUserId, newLanguage, token) {
    return AXIOS.post(
      `/api/v1/users/change-language`,
      { accountUserId, newLanguage },
      { headers: { Authorization: token } }
    );
  },

  changeEmail(accountUserId, newEmail, token) {
    return AXIOS.post(
      `/api/v1/users/change-email`,
      { accountUserId, newEmail },
      { headers: { Authorization: token } }
    );
  },

  updateUserProfile(barcode, values, token) {
    return AXIOS.put(`/api/v1/kits/user-profile/${barcode}`, values, {
      headers: { Authorization: token },
    });
  },

  updatePetName(barcode, petName, token) {
    return AXIOS.patch(
      `/api/v1/kits/${barcode}/change-pet-name/${petName}`,
      null,
      { headers: { Authorization: token } }
    );
  },

  getCountriesByLang(lang) {
    return AXIOSI18N.get(`/byPattern/3/${lang}/private.country`);
  },

  forgotPassword(email) {
    return AXIOS.get(`/api/v1/public/forgotYourPassword`, {
      params: { email: email },
    });
  },

  resetPassword(passwordRestToken, password) {
    return AXIOS.post(`/api/v1/public/password-reset`, {
      hash: passwordRestToken,
      password: password,
    });
  },

  activateAccount(token) {
    return AXIOS.get(`/api/v1/public/validateAccount`, {
      params: { activationCode: token },
    });
  },

  getResultsBreed(token, barcode) {
    return AXIOS.get(`/api/v1/race-data/${barcode}`, {
      headers: { Authorization: token },
    });
  },

  getResultsHealth(token, barcode) {
    return AXIOS.get(`/api/v1/health-data/${barcode}`, {
      headers: { Authorization: token },
    });
  },

  getResultsHealthReport(token, barcode) {
    return AXIOS.get(`/api/v1/health-data/report/${barcode}`, {
      headers: { Authorization: token },
    });
  },

  getResultsTraitsReport(token, barcode) {
    return AXIOS.get(`/api/v1/traitsData/results/report/${barcode}`, {
      headers: { Authorization: token },
    });
  },

  getResultsTraits(token, barcode) {
    return AXIOS.get(`api/v1/traitsData/results/${barcode}`, {
      headers: { Authorization: token },
    });
  },

  getResultsReports(patternId, language, sectionName) {
    return AXIOSI18N.get(
      `byPattern/${patternId}/${language}/technicalreport.${sectionName}`
    );
  },

  getItemsAndReport(area, language, listOfIdsReports) {
    return AXIOSI18N.post(`/reports/`, {
      area: area,
      listOfIdsReports: listOfIdsReports,
      language: language,
    });
  },

  uploadPic(barcode, file, token) {
    return AXIOS.post(
      `/api/v1/kits/${barcode}/upload-pic`,
      { file: file },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      }
    );
  },

  loginFromToken(token, code) {
    return AXIOS.get(`/api/v1/token?token=${token}&code=${code}`);
  },

  resendActivationEmailTo(email) {
    return AXIOS.get(`/api/v1/public/resendActivation`, {
      params: { email: email },
    });
  },

  deleteAccount({ password, token }) {
    return AXIOS.delete(`/api/v1/users/delete`, {
      data: { password },
      headers: {
        Authorization: token,
        "Content-Type": "application/json; charset=utf-8",
      },
    });
  },

  getReport({ barcode, items, language, type, headers, name, petName }) {
    return AXIOSREPORT.post(
      `/koko/generate-report`,
      {
        language,
        barcode,
        type,
        items,
        headers,
        name,
        petName,
      },
      { responseType: "blob" }
    );
  },

  getStatesByCountry(countryId) {
    return AXIOS.get(`/api/v1/public/states-by-country/${countryId}`);
  },
};
