<template>
  <v-container class="pt-1 pb-4">
    <v-row v-if="!loading" justify="center" class="ma-0">
      <v-col v-if="!hasResults">
        <ResultsSteps :values="values" />
      </v-col>
    </v-row>
    <HomeDashboard v-if="hasResults" />
  </v-container>
</template>

<script>
import api from "@/services";
import { mapGetters } from "vuex";

import ResultsSteps from "@/components/steps/ResultsSteps";
import HomeDashboard from "./HomeDashboard";

export default {
  name: "HomeComponent",

  components: {
    ResultsSteps,
    HomeDashboard,
  },
  data: () => ({
    values: null,
    data: null,
    success: false,
    login: null,
    user: null,
    loading: true,
    kitReceived: false,
    hasResults: false,
    lowCallRate: false,
  }),
  computed: {
    ...mapGetters(["getToken"]),

    lowCallRateText() {
      return this.$i18n.t("private.common.lowCallRate.msg1");
    },
    alertHTMLText() {
      return this.$i18n.t("private.common.rawData.error.msg2");
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getUserKitData();
    });
  },
  methods: {
    async getUserKitData() {
      const { getToken: token } = this;
      try {
        const response = await api.getUserKitData(token);
        const { data } = response;
        if (data) {
          this.setDisplayData(data);
        }
      } catch (e) {
        const {
          response: { status },
        } = e;
        if (status === 401) {
          this.$store.commit("SET_LOGOUT");
          this.$router.push("/login");
          return false;
        }
        console.log(e.messsage);
      }
    },
    setDisplayData(data) {
      const { lowCallRateText, alertHTMLText: htmlText } = this;
      const {
        user: { kitReceived, hasResults, lowCallRate },
      } = data;

      const values = { kitReceived, lowCallRate, lowCallRateText, htmlText };
      this.values = values;
      this.hasResults = Boolean(hasResults);
      this.loading = false;
    },
  },
};
</script>
