<template>
  <v-row justify="center">
    <v-col cols="12" class="pa-0">
      <KokoBreadcrumbs :items="items" />
    </v-col>
    <v-col cols="12">
      <h2 class="text-h6 mb-0 text-center">
        {{ $t("private.pageeditaccount.updatePassword") }}
      </h2>
    </v-col>
    <v-col cols="11" sm="8" md="6" lg="4">
      <v-form :ref="formRef" lazy-validation>
        <PasswordField
          id="koko-password__update--password0"
          :value="password0"
          :set-value="setValue"
          :disabled="fieldsDisabled"
          label="private.pageeditaccount.previousPassword"
          :rules="[requiredRules, passwordRules]"
        />

        <h4 class="text-subtitle-1 text-center mb-4">
          {{ $t("private.pagechange.description") }}
        </h4>

        <PasswordField
          id="koko-password__update--password1"
          :value="password1"
          :set-value="setValue"
          :disabled="fieldsDisabled"
          class="mb-2"
          label="private.pageeditaccount.newPassword"
          :rules="[requiredRules, passwordRules, passwordMatchRules]"
        />

        <PasswordField
          id="koko-password__update--password2"
          :value="password2"
          :set-value="setValue"
          :disabled="fieldsDisabled"
          label="private.pageeditaccount.confirmPassword"
          :rules="[requiredRules, passwordRules, passwordMatchRules]"
        />
      </v-form>
    </v-col>
    <v-col cols="12" class="pt-0 text-center">
      <v-btn
        id="koko-password__update--send"
        :loading="loading"
        :disabled="disabled"
        color="primary"
        large
        depressed
        @click="changePassword"
      >
        {{ $t("private.pageeditaccount.update") }}
      </v-btn>
    </v-col>
    <v-col
      v-if="error"
      cols="12"
      md="4"
      class="mt-1 text-center"
      id="koko-password__update--error"
    >
      <p
        v-for="(error, e) in errors"
        :key="`error_${e}`"
        class="my-0 text-caption red--text line--1-2"
      >
        {{ $t(error) }}
      </p>
    </v-col>
  </v-row>
</template>

<script>
import api from "@/services";
import { mapGetters } from "vuex";
import { formRulesMixin } from "@/mixins/formRulesMixin";
import PasswordField from "@/components/form/PasswordField";
import KokoBreadcrumbs from "@/components/breadcrumbs/KokoBreadcrumbs";

export default {
  name: "UpdatePassword",
  components: {
    PasswordField,
    KokoBreadcrumbs,
  },
  mixins: [formRulesMixin],

  data() {
    return {
      commonID: "koko-password__update--",
      formRef: "passwordForm",
      loading: false,
      disabled: true,
      fieldsDisabled: false,
      error: false,
      passwordMatch: false,
      errors: [],
      password0: "",
      password1: "",
      password2: "",
    };
  },

  computed: {
    ...mapGetters(["getToken", "getUserKitData", "getLoginWithToken"]),
    passwordMatchRules() {
      return (v) =>
        !v ||
        this.checkPasswordMatching() ||
        this.$i18n.t("private.pageeditaccount.passwordDontMatch");
    },
    defaultError() {
      return this.$i18n.t("private.user.validate-change-email.error");
    },
    items() {
      const items = [
        {
          text: this.$i18n.t("private.header.menus.settings"),
          disabled: false,
          to: "/settings",
          id: "koko-breadcrumbs_item--1",
        },
        {
          text: this.$i18n.t("private.pageeditaccount.updatePassword"),
          disabled: true,
          id: "koko-breadcrumbs_item--2",
        },
      ];
      return items;
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.setDefaultValues();
    });
  },

  methods: {
    setDefaultValues() {
      const { getLoginWithToken } = this;
      this.fieldsDisabled = Boolean(getLoginWithToken);
    },

    checkPasswordMatching() {
      const { password1, password2 } = this;
      const newPasswords = [password1, password2];
      const hasFilledPasswords = [...newPasswords].every((i) => Boolean(i));
      if (!hasFilledPasswords) {
        return true;
      }
      return newPasswords[0] === newPasswords[1];
    },

    setValue(value, id) {
      const { commonID } = this;
      const prop = id.replace(commonID, "");
      this[prop] = value;
      const allPasswords = [this.password0, this.password1, this.password2];
      const hasFilledPasswords = [...allPasswords].every((i) => Boolean(i));
      if (!hasFilledPasswords) {
        return (this.disabled = true);
      }
      this.disabled = this.password1 !== this.password2;
    },
    logout() {
      this.$store.commit("SET_LOGOUT");
      this.$router.push("/login");
    },

    resetForm(formRef) {
      this.loading = false;
      this.disabled = false;
      this.fieldsDisabled = false;
      this.password0 = "";
      this.password1 = "";
      this.password2 = "";
      this.$refs[formRef].resetValidation();
    },

    async changePassword() {
      const {
        formRef,
        password1: newPassword,
        password0: oldPassword,
        getToken: token,
        getUserKitData: {
          user: { account_id: accountUserId },
        },
      } = this;
      this.fieldsDisabled = true;
      this.disabled = true;
      this.loading = true;
      const params = { accountUserId, newPassword, oldPassword, token };
      try {
        const response = await api.changePassword(params);
        const { status } = response;
        if (status === 200) {
          this.$router.push("/settings");
        }
        if (status === 401) {
          this.logout();
        }
      } catch (e) {
        if (e.response.status === 401) {
          this.logout();
        }
        this.errors.push(this.defaultError);
        this.errors.push(e.response.data.message);
        this.error = true;
        this.loading = false;
        this.disabled = false;
        this.fieldsDisabled = false;
        this.$refs[formRef].resetValidation();
      }
    },
  },
};
</script>
