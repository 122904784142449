<template>
  <label class="koko-family-tree__item" :for="checkboxFor">
    <AvatarComponent v-if="imageExists" :values="values" class="mb-4 mb-md-2" />
    <PetDefaultAvatar
      v-if="!imageExists"
      :size="size"
      :noShadow="noShadow"
      invert
      shadowColor="inherit"
      class="mb-4 mb-md-2"
    />
    <aside
      v-if="breedsList"
      class="koko-family-tree__itemlist mt-md-2"
      :style="`--list-min-height: ${listMinHeight}px`"
    >
      <p
        v-for="(breed, b) in treeItem.breeds"
        :key="`treeItem--${b}`"
        class="koko-family-tree__breed mb-0 pt-1"
      >
        {{ breedName(breed) }}
      </p>
    </aside>
  </label>
</template>

<script>
import AvatarComponent from "@/components/avatar/AvatarComponent";
import PetDefaultAvatar from "@/components/avatar/PetDefaultAvatar";

export default {
  name: "FamilyTreeItem",
  components: {
    AvatarComponent,
    PetDefaultAvatar,
  },
  props: {
    inputFor: {
      type: String,
      default: "",
    },
    row: {
      type: String,
      default: "0",
    },
    index: {
      type: Number,
      default: 0,
    },
    item: {
      type: Object,
      default: () => {},
    },
    userValues: {
      type: Object,
      default: () => {},
    },
    listMinHeight: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      imageExists: false,
      imgFile: null,
      size: "inherit",
      initials: "A",
      image: null,
      noShadow: false,
      shadowColor: "inherit",
      breedsList: false,
    };
  },

  created() {
    this.setItemProps();
  },

  computed: {
    checkboxFor() {
      const { inputFor } = this;
      return inputFor.length ? inputFor : null;
    },

    values() {
      const {
        userValues,
        imageExists,
        size,
        initials,
        image,
        noShadow,
        shadowColor,
      } = this;
      if (userValues) {
        return { ...userValues, size, noShadow, shadowColor };
      }
      return { imageExists, size, initials, image, noShadow, shadowColor };
    },

    treeItem() {
      const { item } = this;
      return item;
    },
  },

  methods: {
    breedName(breed) {
      const { id, mix } = breed;
      const breedName = this.$i18n.t(`item.breed.${id}.name`);
      const breedMix = mix ? " (Mix)" : "";
      return `${breedName}${breedMix}`;
    },

    setItemProps() {
      const {
        item: { breeds },
        values: { imageExists },
      } = this;
      if (!breeds) {
        this.imageExists = imageExists;
        return false;
      }
      this.hasImage();
      this.breedsList = Boolean(breeds.length);
    },

    hasImage() {
      const {
        item: { breeds },
      } = this;
      if (breeds.length > 1) {
        return false;
      }
      const { id } = breeds[0];
      try {
        const imageName = `${id}.webp`;
        const imgFile = require(`../../../public/img/breeds/${imageName}`);
        this.image = imgFile;
        this.imageExists = true;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
