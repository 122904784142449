<template>
  <WelcomeComponent />
</template>
<script>
import WelcomeComponent from "@/components/welcome/WelcomeComponent";

export default {
  name: "WelcomeView",
  components: {
    WelcomeComponent,
  },
};
</script>
