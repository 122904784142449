<template>
  <section :class="dashboardClass">
    <IconsSvg />
    <router-link
      v-for="(item, i) in menuList"
      :key="`menu-link-${i}`"
      :to="item.to"
      class="koko-home__section-button"
      :id="`koko-home__section-button--${item.img}`"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="68" height="68">
        <use :xlink:href="`#${item.img}`" />
      </svg>

      {{ $t(item.title) }}
    </router-link>
    <a
      :href="getURLShopUpgrade"
      class="koko-upgrade__banner"
      id="koko-upgrade__banner"
      v-if="!isAdvanced"
    >
      <span class="koko-upgrade__texts">
        upgrade<strong>advanced</strong>
      </span>
      <span class="koko-banner__button mt-2">
        {{ $t("private.common.buy") }}
      </span>
      <img
        src="@/assets/img/box-perspective-advanced.webp"
        class="koko-upgrade__image"
      />
    </a>
    <aside class="koko-gift__banner-container">
      <a :href="getURLShopKit" class="koko-gift__banner" id="koko-gift__banner">
        <span class="koko-gift__texts">
          {{ $t("private.common.buy.gift.banner.text") }}
        </span>
        <span class="koko-banner__button mt-2">
          {{ $t("private.common.buy") }}
        </span>
      </a>
    </aside>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import IconsSvg from "@/components/icons/IconsSvg";

export default {
  name: "HomeDashboard",

  components: {
    IconsSvg,
  },
  data: () => ({
    data: null,
    success: false,
    login: null,
    user: null,
    loading: true,
    kitReceived: false,
    hasResults: false,
  }),
  computed: {
    ...mapGetters(["getUserKitData"]),
    menuList() {
      const subMenuStarter = [
        {
          id: 23,
          parentId: 2,
          img: "traits",
          title: "private.pagelistkit.traits",
          to: "/results/traits",
        },
        {
          id: 21,
          parentId: 2,
          img: "breeds",
          title: "private.menu.breeds",
          to: "/results/breed",
        },
      ];

      const subMenuAdvanced = [
        {
          id: 22,
          parentId: 2,
          img: "health",
          title: "private.menu.health",
          to: "/results/health",
        },
        ...subMenuStarter,
      ];

      return this.isAdvanced ? subMenuAdvanced : subMenuStarter;
    },

    dashboardClass() {
      const defClassName = "koko-home__dashboard";
      return this.isAdvanced
        ? `${defClassName} ${defClassName}--advanced`
        : `${defClassName} ${defClassName}--starter`;
    },

    isAdvanced() {
      const {
        user: { typeKitId, typeKitName },
      } = this.getUserKitData;
      return typeKitId === "1" && typeKitName === "Advanced";
    },

    getURLShopUpgrade() {
      const urlShop = this.getURLShopKit;
      return `${urlShop}inicio/34-advanced-upgrade-koko.html`;
    },

    getURLShopKit() {
      const { locale } = this.$i18n;
      return `https://shop.kokogenetics.com/${locale}/`;
    },
  },
};
</script>
