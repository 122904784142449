<template>
  <v-container class="pb-lg-6">
    <v-row justify="center">
      <v-col cols="12" class="pb-0">
        <h1 class="text-h5 text-center line--1-3">DNA Connect</h1>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="loading">
      <v-col cols="12" class="mt-4 grey lighten-3">
        <p class="text-center text-caption mt-4">
          {{ $t("private.common.loadingText") }}
        </p>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="hasKinships">
      <v-col cols="12" lg="8" class="pb-0">
        <h2 class="text-body-1 pb-2 px-3 text-center line--1-3">
          {{ $t("private.petfinder.description") }}
        </h2>
      </v-col>
    </v-row>
    <DNAConnectInfo v-if="waitingForKinships" :msgObject="waitingObj" />
    <DNAConnectInfo
      v-if="disallowDNAConnect"
      :msgObject="disallowObj"
      :btnFunction="toggleConditions"
    />

    <v-row justify="center" v-if="hasKinships">
      <v-col class="koko-dna__container mt-2">
        <DNAConnectList :list="kinships" />
      </v-col>
    </v-row>
    <DNAConnectDialog
      :show="showDialogConnect"
      :confirm="setDNAConnect"
      :cancel="cancelDialog"
    />
  </v-container>
</template>
<script>
import api from "@/services";
import { mapGetters } from "vuex";
import { dnaConnectMixin } from "@/mixins/dnaConnectMixin";
import DNAConnectList from "./DNAConnectList";
import DNAConnectInfo from "./DNAConnectInfo";
import DNAConnectDialog from "./DNAConnectDialog";
import advices from "@/data/DNAConnectContents.json";

export default {
  name: "DNAConnectComponent",
  components: {
    DNAConnectList,
    DNAConnectInfo,
    DNAConnectDialog,
  },
  mixins: [dnaConnectMixin],

  created() {
    this.hasUserKinships();
  },

  data() {
    return {
      disallowDNAConnect: false,
      hasKinships: false,
      loading: true,
      kinships: [],
      showDialogConnect: false,
      waitingForKinships: false,
      blockedUsersList: null,
    };
  },

  computed: {
    ...mapGetters(["getToken", "getUserKitData", "getBlockedUsers"]),
    waitingObj() {
      return advices.waiting;
    },
    disallowObj() {
      return advices.disallow;
    },
  },

  methods: {
    async hasUserKinships() {
      const token = this.getToken;
      const {
        user: { allowConnectPet, kit: barcode },
      } = this.getUserKitData;

      if (!allowConnectPet) {
        this.disallowDNAConnect = true;
        this.loading = false;
        return false;
      }

      try {
        const response = await api.getUserKinships({ barcode, token });
        const { status, data } = response;
        if (status === 200) {
          this.getBlockedUsersList(data);
        }
      } catch (e) {
        const {
          response: { status },
        } = e;
        if (status === 401) {
          this.$store.commit("SET_LOGOUT");
          this.$router.push("/login");
          return false;
        }
      }
    },

    toggleConditions() {
      const { showDialogConnect } = this;
      this.showDialogConnect = !showDialogConnect;
    },

    getBlockedUsersList(data) {
      const { getBlockedUsers } = this;
      if (getBlockedUsers) {
        this.blockedUsersList = getBlockedUsers;
        this.setKinships(data);
        return false;
      }

      const blockedUsersList = [...data].reduce(
        (i, e) => [
          ...i,
          {
            id: e.id,
            blocked: e.blocked,
          },
        ],
        []
      );
      this.$store.commit("SET_BLOCKED_USERS", blockedUsersList);
      this.blockedUsersList = blockedUsersList;
      this.setKinships(data);
    },

    setKinships(data) {
      const { disallowDNAConnect, blockedUsersList } = this;

      const kinships = data.map((e) => {
        const { blocked } = blockedUsersList.find((i) => i.id === e.id);
        return { ...e, blocked };
      });

      this.kinships = kinships;
      this.hasKinships = Boolean(kinships.length);
      this.loading = false;
      this.waitingForKinships = !kinships.length && !disallowDNAConnect && true;
    },

    cancelDialog() {
      this.showDialogConnect = false;
    },

    setDNAConnect() {
      this.changeAllowDNAConnect(true);
      this.showDialogConnect = false;
    },
  },
};
</script>
<style lang="scss">
.koko-dna__container {
  max-width: 100vw;
}
@media (min-width: 600px) {
  .koko-dna__container {
    max-width: calc(100vw - 4rem);
  }
}

@media (min-width: 760px) {
  .koko-dna__container {
    max-width: 46rem;
  }
}
</style>
