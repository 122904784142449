<template>
  <v-container>
    <UserProfile />
  </v-container>
</template>

<script>
import UserProfile from "@/components/profile/UserProfile";

export default {
  name: "ProfileView",
  components: { UserProfile },
};
</script>
