import api from "@/services";
import { mapGetters } from "vuex";

export const dnaConnectMixin = {
  computed: {
    ...mapGetters(["getToken", "getUserKitData"]),
  },
  methods: {
    async changeAllowDNAConnect(value) {
      const token = this.getToken;
      const {
        user: { kit: barcode },
      } = this.getUserKitData;
      const body = { barcode, value };
      try {
        const response = await api.changeAllowDNAConnect(body, token);
        const { status } = response;
        if (status === 200) {
          this.updateUserKitData(value);
        }
      } catch (e) {
        const {
          response: { status },
        } = e;
        if (status === 401) {
          this.$store.commit("SET_LOGOUT");
          this.$router.push("/login");
          return false;
        }
        console.log(e);
      }
    },
    updateUserKitData(allowConnectPet) {
      const { getUserKitData } = this;
      const { user } = getUserKitData;
      const newUserValues = { ...user, ...{ allowConnectPet } };
      const newValues = {
        ...getUserKitData,
        ...{ user: { ...newUserValues } },
      };
      this.$store.commit("SET_USER_KIT_DATA", newValues);
      if (this.$route.name === "dna-connect") {
        this.$router.go();
      }
    },
  },
};
