import { render, staticRenderFns } from "./UserAvatar.vue?vue&type=template&id=c9fc4a04"
import script from "./UserAvatar.vue?vue&type=script&lang=js"
export * from "./UserAvatar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports