<template>
  <v-container>
    <DeleteAccount />
  </v-container>
</template>
<script>
import DeleteAccount from "@/components/delete/DeleteAccount.vue";

export default {
  name: "DeleteAccountView",

  components: {
    DeleteAccount,
  },
};
</script>
