<template>
  <AvatarComponent :values="values" />
</template>

<script>
import { avatarMixin } from "@/mixins/avatarMixin";
import AvatarComponent from "./AvatarComponent";

export default {
  name: "UserAvatar",
  components: {
    AvatarComponent,
  },
  props: {
    size: {
      type: String,
      default: "40",
    },
    noShadow: {
      type: Boolean,
      default: false,
    },
  },

  mixins: [avatarMixin],
};
</script>
