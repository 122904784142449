<template>
  <v-container class="text-center pt-8 pt-md-12">
    <h1 class="text-h5 my-6">
      {{ $t("private.pagelogin.title") }}
    </h1>
    <v-row justify="center" @click="resetErrors">
      <v-col cols="10" sm="6" md="5" lg="4" xl="3">
        <v-form ref="form" @submit.prevent="submit" class="koko-form">
          <TextField
            id="koko-login__field--email"
            label="private.createaccount.pageTexts.email"
            :disabled="fieldsDisabled"
            :set-value="setValue"
            :rules="[requiredRules, emailRules]"
          />
          <PasswordField
            id="koko-login__field--password"
            :disabled="fieldsDisabled"
            :set-value="setValue"
            :rules="[requiredRules, passwordRules]"
          />
          <aside v-if="loginError" class="mt-n2 mb-n10">
            <p v-for="error in errors" :key="error" class="red--text pb-2">
              {{ $t(error) }}
            </p>
          </aside>
          <aside class="pa-7 px-xl-12">
            <v-btn
              id="koko-login__button--submit"
              :loading="loading"
              :disabled="disabled"
              color="primary"
              large
              depressed
              class="px-12"
              @click="login()"
            >
              {{ $t("private.pagelogin.buttonsubmit") }}
            </v-btn>
          </aside>
        </v-form>
        <section>
          <p v-if="linkToResendActivation" class="mt-4">
            <router-link
              to="/resend-activate-email"
              id="koko-login__link--resend-activate-email"
            >
              {{ $t("private.pagelogin.resendActivatedEmail") }}
            </router-link>
          </p>
          <p class="mt-7">
            <router-link
              class="koko-login-link"
              to="/forgot-password"
              id="koko-login__link--forgot-password"
            >
              {{ $t("private.pagelogin.forgotPassword") }}
            </router-link>
          </p>
          <p class="mt-0">
            <router-link
              to="/new-account"
              id="koko-login__link--new-account"
              class="koko-login-link"
            >
              {{ $t("private.pagelogin.createnewaccount") }}
            </router-link>
          </p>
        </section>
      </v-col>
      <v-col cols="12" class="pt-2 px-2 pb-6">
        <v-btn
          id="koko-login__button--return"
          color="primary"
          small
          text
          class="pa-5"
          :href="kokoPageURl"
        >
          {{ $t("private.pagelogin.goBackKokogenetics") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import api from "@/services";
import { mapGetters } from "vuex";
import { formRulesMixin } from "@/mixins/formRulesMixin";
import TextField from "@/components/form/TextField";
import PasswordField from "@/components/form/PasswordField";

export default {
  name: "LoginForm",
  components: {
    TextField,
    PasswordField,
  },
  mixins: [formRulesMixin],

  data() {
    return {
      commonFieldId: "koko-login__field--",
      token: null,
      userAccount: null,
      userData: null,
      loading: false,
      disabled: true,
      fieldsDisabled: false,
      loginError: false,
      email: "",
      password: "",
      invalidFields: false,
      errors: [],
      showPassword: false,
      linkToResendActivation: false,
      submitted: false,
    };
  },

  created() {
    if (this.$route.query.lang) {
      const languageFromLink = this.$route.query.lang;
      const { locale } = this.$i18n;
      const loadedlanguage =
        this.$languagesToLoad.includes(languageFromLink) &&
        languageFromLink !== locale;

      if (loadedlanguage) {
        this.$i18n.locale = languageFromLink;
      }
    }
    if (this.isLoggedIn) {
      this.$router.push("/");
    }
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),

    kokoPageURl() {
      const { locale } = this.$i18n;
      const defaultLocale = "es";
      const URL_PAGE = "https://www.kokogenetics.com/";
      return defaultLocale === locale ? URL_PAGE : `${URL_PAGE}${locale}/`;
    },
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    });
  },

  methods: {
    hasFilledAllFields() {
      return [this.email, this.password].every((e) => Boolean(e));
    },

    setValue(value, id) {
      const { mailRegEx, commonFieldId } = this;
      const prop = id.replace(commonFieldId, "");
      this[prop] = value;
      const hasFilledAllFields = this.hasFilledAllFields();
      this.disabled = !hasFilledAllFields;
      if (!hasFilledAllFields) {
        return false;
      }
      const passwordOK = this.password.length > 4;
      const emailOK = mailRegEx.test(this.email);
      const allFieldsOK = [passwordOK, emailOK].every((e) => e === true);
      this.disabled = !allFieldsOK;
    },

    async login() {
      this.loading = true;
      this.disabled = true;
      this.fieldsDisabled = true;
      const { email, password } = this;
      try {
        this.$store.commit("SET_EMAIL", email);
        const response = await api.getAccess(email, password);
        const { status, headers } = response;
        if (status === 200) {
          const token = headers.getAuthorization();
          this.token = token;
          this.$store.commit("SET_TOKEN", token);
          this.getUserKitData();
        }
      } catch (e) {
        this.showLoginError(e);
      }
    },
    async getUserKitData() {
      const { token } = this;
      try {
        const response = await api.getUserKitData(token);
        const { status, data } = response;
        if (status === 200) {
          this.$store.commit("SET_USER_KIT_DATA", data);
          const { user } = data;
          this.userAccount = user;
          this.getUserData();
        }
      } catch (e) {
        if (e.response.status === 406) {
          // Pending activate account
          this.linkToResendActivation = true;
        }
        this.showLoginError(e);
      }
    },
    async getUserData() {
      const { userAccount, token } = this;
      const { account_id } = userAccount;
      try {
        const response = await api.getUserData(account_id, token);
        const { status, data } = response;
        if (status === 200) {
          this.$store.commit("SET_USER_DATA", data);
          this.$store.commit("SET_LOGIN_SUCCESS", true);
          this.$router.push("/");
        }
      } catch (e) {
        this.showLoginError(e);
      }
    },

    showLoginError(AxiosError) {
      const {
        response: {
          data: { error: errorCode },
        },
      } = AxiosError;

      const errorEquivs = {
        ERR_ACCOUNT_PENDING_TO_ACTIVATED: "accountnotactivated",
      };

      const errorKey = errorEquivs[errorCode]
        ? errorEquivs[errorCode]
        : "invalidcredentials";

      const errorMessage = this.$i18n.t(`private.error.${errorKey}`);
      this.showErrors(errorMessage);
    },

    showErrors(errorMessage) {
      this.loginError = true;
      this.errors = [errorMessage];
      this.loading = false;
      this.disabled = false;
      this.fieldsDisabled = false;
    },

    resetErrors() {
      this.loginError = false;
      this.errors = [];
    },
  },
};
</script>
<style>
.koko-login-link {
  display: block;
  line-height: 1.2;
  text-wrap: balance;
}
</style>
