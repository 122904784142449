<template>
  <v-container class="pt-0">
    <v-row justify="space-between" class="mt-0">
      <v-col cols="auto" class="pt-0">
        <KokoBreadcrumbs :items="items" class="py-0 px-2" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" class="px-2 pt-2">
        <h1 class="text-h4 line--1-2 text-center">
          {{ getText("name") }}
        </h1>
      </v-col>
    </v-row>
    <v-row justify="center" class="py-12" v-if="loading">
      <v-col cols="11" sm="10" class="pt-3">
        <p class="text-body-2 text--disabled text-center mt-2 mb-n14">
          {{ $t("private.common.loadingText") }}
        </p>
        <v-skeleton-loader class="my-4" type="image" />
      </v-col>
    </v-row>
    <v-row justify="center" class="pb-12" v-if="results">
      <v-col cols="11" sm="10" class="pt-3">
        <section
          v-html="getText('description')"
          class="text-center koko-u__v-html"
        />
        <v-divider class="my-4" />
        <h6 class="text-subtitle-1 text-center mt-8 mb-2">
          {{ $t("private.common.yourResult") }}
        </h6>
        <p v-if="itemStatus" class="text-h6 text-center mt-0 mb-4 line--1-2">
          {{ $t(itemStatus) }}
        </p>

        <aside v-if="hasInfo" class="d-none d-sm-block">
          <TableTechReport :info="info" idKey="traitId" type="traits" />
        </aside>
        <aside v-if="hasInfo" class="d-block d-sm-none">
          <MobileTableTechReport :info="info" idKey="traitId" type="traits" />
        </aside>
      </v-col>
      <v-col v-if="imageExists" cols="11" sm="10" class="pt-0">
        <figure class="px-6 pb-6 pb-sm-12 pt-0 pt-sm-12">
          <img class="koko-summary__img my-0" :src="imageExists" />
        </figure>
      </v-col>

      <v-col
        v-for="(section, s) in sections"
        :key="`section_${s}`"
        cols="11"
        sm="10"
        :class="setSectionClass(section)"
      >
        <h4 v-if="section.title" class="text-h6 mb-1">
          {{ $t(section.title) }}
        </h4>
        <section v-html="getText(section.key)" class="koko-u__v-html" />
      </v-col>
      <v-col v-if="technicalCitations" cols="11" sm="10">
        <h4 class="text-h6 mb-1">
          {{ $t("private.results.imputation.bibliography.title") }}
        </h4>
        <section
          class="koko-u__v-html koko-u__v-html--caption"
          v-html="technicalCitations"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/services";
import { mapGetters } from "vuex";
import TableTechReport from "@/components/table/TableTechReport";
import MobileTableTechReport from "@/components/table/MobileTableTechReport";
import KokoBreadcrumbs from "@/components/breadcrumbs/KokoBreadcrumbs";

export default {
  name: "HealthDetailComponent",
  components: {
    TableTechReport,
    MobileTableTechReport,
    KokoBreadcrumbs,
  },
  props: {
    idTrait: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    data: null,
    info: null,
    results: null,
    noResults: false,
    loading: true,
    technicalCitations: null,
    technicalDescription: null,
    itemStatus: null,
    sections: [
      { title: "private.common.definition", key: "overview" },
      { title: "private.common.geneticbasis", key: "geneticbasis" },
      {
        title: "private.common.distinctivefeatures",
        key: "distinctivefeatures",
      },
    ],
  }),

  created() {
    this.setDefaultValues();
  },

  mounted() {
    this.$nextTick(() => {
      window.scrollTo(0, 0);
    });
  },

  computed: {
    ...mapGetters(["getToken", "getUserKitData", "getTraitsItems"]),

    items() {
      const items = [
        {
          text: this.$i18n.t("private.pagelistkit.traits"),
          disabled: false,
          href: "/results/traits",
          id: "koko-breadcrumbs_item--1",
        },
        {
          text: this.getText("name"),
          disabled: true,
          id: "koko-breadcrumbs_item--2",
        },
      ];
      return items;
    },
    imageExists() {
      const { idTrait } = this;
      const imageName = `${idTrait}.webp`;
      try {
        const imgFile = require(`../../../public/img/traits/${imageName}`);
        return imgFile;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    hasInfo() {
      const { info } = this;
      return Boolean(info.length);
    },
  },
  methods: {
    setDefaultValues() {
      const token = this.getToken;
      const {
        user: { kit: barcode },
      } = this.getUserKitData;
      this.getResultsTraitsReport(token, barcode);
    },

    async getResultsTraitsReport(token, barcode) {
      const { locale } = this.$i18n;
      try {
        const response = await api.getResultsTraitsReport(token, barcode);
        const { data } = response;
        if (data) {
          this.info = data;
          this.$i18n.mergeLocaleMessage(locale, data);
          this.getTechnicalReportsTexts(data);
        }
      } catch (e) {
        const {
          response: { status },
        } = e;
        if (status === 401) {
          this.$store.commit("SET_LOGOUT");
          this.$router.push("/login");
          return false;
        }
        console.log(e);
      }
    },
    async getTechnicalReportsTexts(data) {
      const info = data.filter((e) => e.traitId === Number(this.idTrait));
      const infoIds = info.reduce(
        (i, e) => [...i, [`${e.traitId}.${e.idReport}.${e.name}`]],
        []
      );
      const { locale: lang } = this.$i18n;
      const idList = data.map((e) => `${e.traitId}`);
      const area = "traits";
      const fullIdsList = [...idList, ...infoIds].flat();
      try {
        const citations = await api.getItemsAndReport(area, lang, fullIdsList);
        const { data } = citations;
        if (data) {
          this.$i18n.mergeLocaleMessage(lang, data);
          this.info = info;
          this.setDisplayData(data, lang);
        }
      } catch (err) {
        this.noResults = true;
        console.error(err);
      }
    },

    setDisplayData(data, lang) {
      const { idTrait } = this;
      const items = this.getTraitsItems;
      const hasItem = items[lang].find((e) => e.id === Number(idTrait));
      if (hasItem) {
        this.itemStatus = hasItem.actions;
      }
      this.loading = false;
      this.results = true;
      const citationsKey = `technicalreport.traits.${idTrait}.citations`;
      const technicalCitations = data[citationsKey];
      this.technicalCitations = technicalCitations;
      console.log("technicalCitations", technicalCitations);
    },

    getText(type) {
      const id = this.idTrait;
      return this.$i18n.t(`item.traits.${id}.${type}`);
    },

    setSectionClass(section) {
      const id = this.idTrait;
      const hasContent = this.$i18n.te(`item.traits.${id}.${section.key}`);
      return hasContent ? "pt-3" : "d-none";
    },
  },
};
</script>
