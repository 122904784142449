<template>
  <v-data-iterator
    :page="page"
    :items="itemsList"
    :items-per-page="itemsPerPage"
    loading
    hide-default-footer
    must-sort
  >
    <template #default="props">
      <section class="koko-dna__list">
        <DNAConnectItem
          v-for="(item, i) in props.items"
          :key="`item__${i}`"
          :item="item"
        />
      </section>
    </template>
    <template #footer>
      <v-row class="koko-dna__pagination" align="center" justify="center">
        <v-pagination
          v-if="numberOfPages > 1"
          v-model="page"
          :length="numberOfPages"
          circle
          flat
        />
      </v-row>
    </template>
  </v-data-iterator>
</template>

<script>
import DNAConnectItem from "./DNAConnectItem";
export default {
  name: "DNAConnectList",
  components: {
    DNAConnectItem,
  },
  props: {
    list: {
      type: Array,
      default: () => null,
    },
  },
  data() {
    return {
      itemsPerPage: 10,
      page: 1,
    };
  },
  watch: {
    page() {
      window.scrollTo(0, 0);
    },
  },
  computed: {
    itemsList() {
      const { list, page } = this;
      const itemsList = list.map((e, i) => ({
        ...e,
        itemID: `${page - 1}${i + 1}`,
      }));
      return itemsList;
    },
    numberOfPages() {
      const { list, itemsPerPage } = this;
      return Math.ceil(list.length / itemsPerPage);
    },
  },
};
</script>
<style lang="scss">
.koko-dna__pagination {
  flex-grow: 0;
  margin-top: 1rem;
}
</style>
