<template>
  <v-row justify="center">
    <v-col class="d-flex flex-column justify-center align-center pt-0 pb-6">
      <h4 class="text-h5 mb-2">
        {{ $t("private.peoplefinder.profile") }}
      </h4>
      <UserAvatar size="150" class="my-4" />
      <h2 class="text-h4 text-center mt-2 mb-5 px-4">
        {{ petName }}
      </h2>
      <label class="text-body-2 v-label">
        {{ $t("private.createaccount.pageTexts.gender") }}
      </label>
      <p>{{ getUserSex }}</p>

      <label class="text-body-2 v-label">
        {{ $t("private.createaccount.pageTexts.user") }}
      </label>
      <p class="text-subtitle-1">
        {{ getUserFullName }}
      </p>

      <v-btn
        color="primary"
        depressed
        :to="`/update-profile`"
        small
        class="mt-2 overflow-ellipsis"
        id="koko-profile__btn--edit"
      >
        {{ $t("private.peoplefinder.editprofile") }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import UserAvatar from "@/components/avatar/UserAvatar";

export default {
  name: "UserProfile",
  components: {
    UserAvatar,
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters(["getUserData", "getToken"]),

    petName() {
      const {
        getUserData: { petName },
      } = this;
      return petName;
    },

    getUserFullName() {
      const {
        getUserData: { firstName, lastName },
      } = this;
      return `${firstName} ${lastName}`;
    },

    getUserSex() {
      const {
        getUserData: { sex },
      } = this;
      const keyCapitalized = sex.charAt(0).toUpperCase() + sex.slice(1);
      const composedKey = `private.createaccount.pageTexts.gender${keyCapitalized}`;
      return this.$i18n.t(composedKey);
    },
  },
};
</script>
