<template>
  <DNAConnectComponent />
</template>

<script>
import DNAConnectComponent from "@/components/dnaconnect/DNAConnectComponent";

export default {
  name: "DNAConnectView",
  components: { DNAConnectComponent },
};
</script>
