<template>
  <section v-if="breedsList">
    <details
      v-for="(breed, b) in breeds"
      :key="`breed_${b}`"
      class="koko-details koko-details--large mb-2"
      :id="itemID(b)"
    >
      <summary class="koko-summary">
        {{ breed.name }}
      </summary>
      <div>
        <figure v-if="imageExists(breed.id)">
          <img class="koko-summary__img" :src="imageExists(breed.id)" />
        </figure>

        <article
          class="koko-summary__contents px-2"
          v-for="(section, s) in sections"
          :key="`breed_${b}_section-${s}`"
        >
          <h4
            v-if="section.title"
            class="text-subtitle-1 my-2 font-weight-bold line--1-2"
          >
            {{ $t(section.title) }}
          </h4>
          <p class="mb-6 text-body-2">
            {{ $t(`item.breed.${breed.id}.${section.key}`) }}
          </p>
        </article>
      </div>
    </details>
  </section>
</template>
<script>
export default {
  name: "BreedsDetails",
  props: {
    breeds: {
      type: Array,
      default: () => null,
    },
  },
  data() {
    return {
      breedsList: true,
      commonID: "koko-breeds__item--",
      sections: [
        { key: "maintext" },
        {
          key: "generaldetails",
          title: "private.common.general.details",
        },
        {
          key: "history",
          title: "private.common.breed.history",
        },
        {
          key: "characteristics",
          title: "private.common.breed.characteristics",
        },
        {
          key: "healthproblems",
          title: "private.common.breed.healthproblems",
        },
      ],
    };
  },

  methods: {
    itemID(order) {
      const { commonID } = this;
      const specificID = order + 1;
      return `${commonID}${specificID}`;
    },

    imageExists(id) {
      try {
        const imageName = `${id}.webp`;
        const imgFile = require(`../../../public/img/breeds/${imageName}`);
        return imgFile;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
  },
};
</script>
