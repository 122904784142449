<template>
  <article :class="itemClass" @click="toggleChat(true)" :id="containerID">
    <v-row justify="center" class="ma-0 pa-1">
      <v-col cols="12" sm="auto" class="pl-3 pl-sm-4 py-0 pt-sm-2">
        <aside
          @click.stop="toggleProfile(true)"
          :title="profileTitle"
          :id="avatarID"
        >
          <AvatarComponent :values="avatarValues" />
        </aside>
      </v-col>
      <v-col>
        <section class="koko-dna__item-header">
          <h4 class="koko-dna__item-name">
            {{ petName }}
          </h4>
          <v-icon v-if="item.blocked" small color="warning" class="ml-1 mt-n05"
            >mdi-lock</v-icon
          >
          <span
            v-if="hasPendingMessages"
            :id="pendingID"
            class="koko-dna__pending-msg"
            :title="pendingMsgTitle"
          >
            {{ pendingMessages }}
          </span>
        </section>
        <p class="koko-dna__item-value mb-1 line--1-3">
          {{ kinshipValue }}
        </p>
        <article
          class="koko-dna__item-kinship mb-1 line--1-3"
          v-html="kinshipTitle"
        />
      </v-col>
      <DNAConnectItemMenu
        :menu-props="menuProps"
        @update-blocked="toggleBlocked(item)"
      />
    </v-row>
    <DNAConnectRoom
      :item="item"
      :show="showChat"
      @close-chat="toggleChat(false)"
    />
    <DNAConnectProfile
      :item="item"
      :show="showProfile"
      @close-profile="toggleProfile(false)"
    />
  </article>
</template>
<script>
import { mapGetters } from "vuex";
import AvatarComponent from "@/components/avatar/AvatarComponent";
import DNAConnectItemMenu from "./DNAConnectItemMenu";
import DNAConnectRoom from "./DNAConnectRoom";
import DNAConnectProfile from "./DNAConnectProfile";
export default {
  name: "DNAConnectItem",
  components: {
    AvatarComponent,
    DNAConnectItemMenu,
    DNAConnectRoom,
    DNAConnectProfile,
  },
  props: {
    item: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      showMenu: true,
      showChat: false,
      showProfile: false,
      hasPendingMessages: false,
      pendingMessages: 0,
      hasOpened: false,
      commonID: "koko-dna__item--",
    };
  },
  created() {
    this.getUserPendingMessages();
  },
  computed: {
    ...mapGetters(["getToken", "getBlockedUsers", "getReadingMessages"]),

    menuProps() {
      const {
        item,
        showMenu,
        itemID: menuID,
        toggleChat: openChat,
        toggleProfile: openProfile,
      } = this;

      return { item, showMenu, menuID, openChat, openProfile };
    },

    itemID() {
      const {
        item: { itemID },
      } = this;
      return itemID;
    },

    avatarID() {
      const { itemID } = this;
      const commonID = "koko-dna__item-avatar--";
      return `${commonID}${itemID}`;
    },

    pendingID() {
      const { itemID } = this;
      const commonID = "koko-dna__pending-messages--";
      return `${commonID}${itemID}`;
    },

    containerID() {
      const { commonID, itemID } = this;
      return `${commonID}${itemID}`;
    },

    itemClass() {
      const {
        showMenu,
        item: { blocked },
      } = this;
      const className = "koko-dna__item pt-4 pt-lg-2";
      const styleOptions = { showMenu, blocked };
      const styleValues = {
        blocked: "has-blocked",
        showMenu: "koko-dna__item--active",
      };

      const itemClass = Object.entries(styleOptions).map((e) =>
        e[1] ? styleValues[e[0]] : ""
      );

      return [className, ...itemClass].join(" ").trimEnd();
    },

    profileTitle() {
      return this.$i18n.t("private.peoplefinder.profile");
    },

    pendingMsgTitle() {
      return this.$i18n.t("private.peoplefinder.messages.name");
    },

    avatarValues() {
      const {
        petName,
        item: {
          profilePic: { value: picName },
        },
      } = this;
      const imageExists = Boolean(picName);
      const image = imageExists ? picName : "";
      const initials = petName.trim().slice(0, 1).toUpperCase();
      return { size: "40", initials, imageExists, image };
    },
    petName() {
      const {
        item: {
          petName: { value },
        },
      } = this;
      return value || "Anonymous Pet";
    },

    kinshipValue() {
      const {
        item: {
          kinship: { value },
        },
      } = this;
      return this.$i18n.t(value);
    },

    kinshipTitle() {
      const {
        petName,
        item: {
          kinship: { title },
        },
      } = this;

      return this.$i18n
        .t(title)
        .replace("{name}", `<strong>${petName}</strong>`);
    },

    hasReadingMessages() {
      const {
        getReadingMessages,
        item: { id },
      } = this;
      const readingMessages = getReadingMessages ?? [];
      return readingMessages.includes(id);
    },
  },

  methods: {
    getUserPendingMessages() {
      const {
        hasReadingMessages,
        item: { pendingMessages },
      } = this;
      if (hasReadingMessages) {
        this.hasPendingMessages = false;
        return false;
      }
      this.hasPendingMessages = Boolean(pendingMessages);
      this.pendingMessages = pendingMessages;
    },

    toggleBlocked(item) {
      const { getBlockedUsers } = this;
      const { blocked, id } = item;
      const updatedItem = { id, blocked: !blocked };
      item.blocked = !blocked;
      const blockedUsersList = [
        ...getBlockedUsers.filter((e) => e.id !== id),
        updatedItem,
      ];
      this.$store.commit("SET_BLOCKED_USERS", blockedUsersList);
    },

    updateReadingMessages() {
      const {
        getReadingMessages,
        item: { id },
      } = this;

      const readingMessages = getReadingMessages ?? [];
      const payload = [...new Set([...readingMessages, id])];
      this.$store.commit("SET_READING_MESSAGES", payload);
    },

    toggleChat(showChat) {
      const {
        hasOpened,
        item: { blocked },
      } = this;
      if (blocked) {
        return false;
      }
      const { showMenu } = this;
      if (showChat && !hasOpened) {
        this.hasPendingMessages = false;
        this.hasOpened = true;
        this.updateReadingMessages();
      }
      if (showMenu) {
        this.showChat = showChat;
      }
    },

    toggleProfile(showProfile) {
      const { showMenu } = this;
      if (showMenu) {
        this.showProfile = showProfile;
      }
    },
  },
};
</script>
<style>
.koko-dna__item {
  --pending-top: -0.5rem;
  --pending-left: -2rem;
  --after-color: rgba(0, 0, 0, 0.12);
}
.koko-dna__item--active {
  cursor: pointer;
  transition: background-color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.koko-dna__item--active.has-blocked {
  cursor: default;
}

.has-blocked .koko-dna__item-header,
.has-blocked .koko-dna__item-value,
.has-blocked .koko-dna__item-kinship {
  user-select: none;
}

.koko-dna__item--active:not(.has-blocked):hover {
  background-color: #e3eef9;
  color: hsl(210, 50%, 46%);
  --after-color: rgba(25, 118, 210, 0.25);
}

.koko-dna__item::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--after-color);
}
.koko-dna__list .koko-dna__item:last-child:after {
  display: none;
}
.koko-dna__item-header {
  display: flex;
  position: relative;
}

.koko-dna__pending-msg {
  display: inline-block;
  width: auto;
  background-color: #00bcd4;
  background-color: hsla(240, 66%, 15%, 0.5);
  background-color: rgb(33, 150, 243);
  box-shadow: 0 0 0 1px white;
  position: absolute;
  left: var(--pending-left);
  font-size: 0.8rem;
  top: var(--pending-top);
  padding: 0.3rem 0.45rem;
  line-height: 1;
  border-radius: 12px;
  color: white;
  user-select: none;
  z-index: 1;
}

.koko-dna__item-name {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.3;
  color: rgb(13, 13, 63);
  padding: 0 0 0.2rem;
  letter-spacing: 0.005em;
}

.koko-dna__item-value {
  font-weight: 500;
  color: rgba(13, 13, 63, 0.75);
  font-size: 1rem;
  padding-bottom: 0.1em;
}
.koko-dna__item-kinship {
  font-size: 0.95rem;
  line-height: 1.3;
  letter-spacing: 0.01em;
}

@media (max-width: 600px) {
  .koko-dna__item {
    --pending-top: -3.5rem;
    --pending-left: 1.5rem;
  }
}
</style>
