<template>
  <v-row justify="center">
    <v-col cols="12" class="pa-0">
      <KokoBreadcrumbs :items="items" />
    </v-col>
    <v-col cols="12">
      <h2 class="text-h6 mb-0 text-center">
        {{ $t("private.pageeditaccount.updateEmail") }}
      </h2>
    </v-col>
    <v-col cols="11" sm="6" lg="4">
      <TextField
        id="koko-email__update--old-email"
        class="mb-2"
        :value="userEmail"
        disabled
        label="private.account.oldemail"
      />
      <v-form :ref="formRef" lazy-validation>
        <TextField
          id="koko-email__update--email"
          :value="email"
          class="mb-2"
          label="private.account.newmail"
          :disabled="fieldsDisabled"
          :set-value="setValue"
          :rules="[requiredRules, emailRules, emailMatchRules]"
        />

        <TextField
          id="koko-email__update--email2"
          :value="email2"
          label="private.account.repeatmail"
          :disabled="fieldsDisabled"
          :set-value="setValue"
          :rules="[requiredRules, emailRules, emailMatchRules]"
        />
      </v-form>
    </v-col>
    <v-col cols="12" class="pt-0 text-center">
      <v-btn
        id="koko-email__update--send"
        :loading="loading"
        :disabled="disabled"
        color="primary"
        large
        depressed
        @click="changeEmail"
      >
        {{ $t("private.pageeditaccount.update") }}
      </v-btn>
    </v-col>
    <v-col
      v-if="error"
      cols="12"
      md="4"
      class="mt-1 text-center"
      id="koko-email__update--error"
    >
      <p
        v-for="(error, e) in errors"
        :key="`error_${e}`"
        class="my-0 text-caption red--text line--1-2"
      >
        {{ $t(error) }}
      </p>
    </v-col>
  </v-row>
</template>

<script>
import api from "@/services";
import { mapGetters } from "vuex";
import { formRulesMixin } from "@/mixins/formRulesMixin";
import TextField from "@/components/form/TextField";
import KokoBreadcrumbs from "@/components/breadcrumbs/KokoBreadcrumbs";

export default {
  name: "UpdateEmail",
  components: {
    TextField,
    KokoBreadcrumbs,
  },
  mixins: [formRulesMixin],

  data() {
    return {
      commonID: "koko-email__update--",
      demoKit: "KGEUNAAAAAA1",
      formRef: "emailForm",
      loading: false,
      disabled: true,
      fieldsDisabled: false,
      error: false,
      emailMatch: false,
      errors: [],
      email: "",
      email2: "",
    };
  },

  computed: {
    ...mapGetters([
      "getToken",
      "getUserKitData",
      "getUserData",
      "getLoginWithToken",
    ]),

    userEmail() {
      const {
        user: { email },
      } = this.getUserKitData;
      return email;
    },

    hasFilledEmails() {
      const newEmails = [this.email, this.email2];
      return [...newEmails].every((i) => Boolean(i));
    },

    emailMatchRules() {
      if (!this.hasFilledEmails) {
        return true;
      }
      return (
        this.email === this.email2 || this.$i18n.t("private.error.confirmemail")
      );
    },
    defaultError() {
      return this.$i18n.t("private.user.validate-change-email.error");
    },
    items() {
      const items = [
        {
          text: this.$i18n.t("private.header.menus.settings"),
          disabled: false,
          to: "/settings",
          id: "koko-breadcrumbs_item--1",
        },
        {
          text: this.$i18n.t("private.pageeditaccount.updateEmail"),
          disabled: true,
          id: "koko-breadcrumbs_item--2",
        },
      ];
      return items;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setDefaultValues();
    });
  },

  methods: {
    setDefaultValues() {
      const { getLoginWithToken } = this;
      this.fieldsDisabled = Boolean(getLoginWithToken);
    },

    checkPasswordMatching() {
      const { password1, password2 } = this;
      const newPasswords = [password1, password2];
      const hasFilledPasswords = [...newPasswords].every((i) => Boolean(i));
      if (!hasFilledPasswords) {
        return true;
      }
      return newPasswords[0] === newPasswords[1];
    },

    setValue(value, id) {
      const { commonID } = this;
      const prop = id.replace(commonID, "");
      this[prop] = value;

      if (!this.hasFilledEmails) {
        return (this.disabled = true);
      }
      this.disabled = this.email !== this.email2;
    },

    logout() {
      this.$store.commit("SET_LOGOUT");
      this.$router.push("/login");
    },

    setNewEmail() {
      const userKitData = this.getUserKitData;
      userKitData.user.email = this.email;
      this.$store.commit("SET_USER_KIT_DATA", userKitData);
    },

    resetForm(formRef) {
      this.loading = false;
      this.disabled = false;
      this.fieldsDisabled = false;
      this.email = "";
      this.email2 = "";
      this.$refs[formRef].resetValidation();
    },

    async changeEmail() {
      const {
        demoKit,
        email,
        formRef,
        getToken,
        getUserKitData: {
          user: { account_id, kit },
        },
      } = this;
      this.fieldsDisabled = true;
      this.disabled = true;
      this.loading = true;

      if (kit === demoKit) {
        this.resetForm(formRef);
        return false;
      }

      this.setNewEmail();

      try {
        const response = await api.changeEmail(account_id, email, getToken);
        const { status } = response;
        if (status === 200) {
          this.$router.push("/settings");
        }
        if (status === 401) {
          this.logout();
        }
      } catch (e) {
        if (e.response.status === 401) {
          this.logout();
        }
        this.errors = [];
        this.errors.push(this.defaultError);
        this.errors.push(e.response.data.message);
        this.error = true;
        this.resetForm(formRef);
      }
      return true;
    },
  },
};
</script>
