<template>
  <aside>
    <p v-if="title" class="subtitle-1 mb-5 text-left">
      {{ $t("private.welcome.common.customersupport") }}
    </p>

    <v-row v-for="(btn, b) in buttons" :key="`support--${b}`" class="px-2">
      <v-btn text color="primary" :href="btn.href" :id="`${commomID}${btn.id}`">
        <v-icon small class="mr-3" color="blue-grey lighten-1">
          {{ btn.icon }}
        </v-icon>
        <span :class="btn.spanClass">{{ btn.text }}</span>
      </v-btn>
    </v-row>
  </aside>
</template>
<script>
export default {
  name: "SupportComponent",
  props: {
    title: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      commomID: "koko-support__btn--",
      buttons: [
        {
          href: "tel:+34-960-090-596",
          text: "+ 34 960 090 596",
          icon: "mdi-phone",
          id: "phone",
          spanClass: "",
        },
        {
          href: "mailto:info@kokogenetics.com",
          text: "info@kokogenetics.com",
          icon: "mdi-email",
          id: "email",
          spanClass: "text-lowercase",
        },
        {
          href: "https://www.kokogenetics.com",
          text: "www.kokogenetics.com",
          icon: "mdi-chat",
          id: "chat",
          spanClass: "text-lowercase",
        },
      ],
    };
  },
};
</script>
