<template>
  <RegisterForm />
</template>
<script>
import { mapGetters } from "vuex";
import RegisterForm from "@/components/register/RegisterForm";

export default {
  name: "RegisterView",
  components: {
    RegisterForm,
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
  created() {
    if (this.isLoggedIn) {
      this.$router.push("/");
    }
  },
};
</script>
