<template>
  <HealthDetailComponent v-if="isAdvanced" :id-health="idHealth" />
</template>

<script>
import { mapGetters } from "vuex";
import HealthDetailComponent from "@/components/health/HealthDetailComponent";

export default {
  name: "HealthViewDetail",
  props: {
    idHealth: {
      type: String,
      default: "",
    },
  },
  components: {
    HealthDetailComponent,
  },
  computed: {
    ...mapGetters(["getUserKitData"]),
    isAdvanced() {
      const {
        user: { typeKitId, typeKitName },
      } = this.getUserKitData;
      return typeKitId === "1" && typeKitName === "Advanced";
    },
  },
  created() {
    if (!this.isAdvanced) {
      this.$router.push("/");
    }
  },
};
</script>
