<template>
  <aside class="koko-header__user-section">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      content-class="koko-user__menu"
      offset-y
      left
      max-width="250px"
    >
      <template #activator="{ attrs, on }">
        <v-sheet class="mb-n2 d-flex justify-end align-center">
          <v-btn
            icon
            class="box-shadow--none"
            :id="`${commonID}open`"
            v-bind="attrs"
            v-on="on"
          >
            <UserAvatar size="40" />
          </v-btn>
        </v-sheet>
      </template>
      <HeaderUser :close-menu="closeMenu" />
      <v-list nav>
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item
            v-for="item in menuList"
            :key="item.id"
            :to="item.to"
            exact
          >
            <v-list-item-title link :id="getItemId(item)">
              {{ getTranslated(item) }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout()" :id="`${commonID}logout`">
            {{ $t("private.pagehome.menus.exit") }}
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </aside>
</template>
<script>
import { mapGetters } from "vuex";
import UserAvatar from "@/components/avatar/UserAvatar";
import HeaderUser from "@/components/user/HeaderUser";

export default {
  name: "DesktopMenu",
  components: {
    HeaderUser,
    UserAvatar,
  },
  props: {
    menuList: {
      type: Array,
      default: () => [],
    },
    openDrawer: {
      type: Function,
      default: () => [],
    },
  },
  data() {
    return {
      languages: [],
      menu: false,
      selectedItem: null,
      commonID: "koko-menu-desktop__item--",
    };
  },
  computed: {
    ...mapGetters(["getUserData", "getUserKitData", "getUILanguage"]),
  },

  methods: {
    closeMenu() {
      this.menu = false;
    },
    getItemId(item) {
      const { commonID } = this;
      const { title: key } = item;
      const idElem = key.split(".").pop();
      return `${commonID}${idElem}`;
    },

    getTranslated(item) {
      const { title: key } = item;
      return this.$i18n.t(key);
    },
    logout() {
      this.$store.commit("SET_LOGOUT");
      this.$router.push("/login");
    },
  },
};
</script>
<style>
.v-menu__content {
  width: 300px;
}
.box-shadow--none {
  box-shadow: none !important;
}

.koko-app .box-shadow--none.v-btn:before,
.koko-app .box-shadow--none.v-btn .v-ripple__container {
  display: none !important;
}
</style>
