<template>
  <v-container class="pb-9 px-6 px-lg-8">
    <section class="koko-footer__columns">
      <FooterColumn
        v-for="(col, c) in cols"
        :key="`col_${c}`"
        class="mt-2"
        :content="col"
      />
    </section>
  </v-container>
</template>
<script>
import footerSections from "@/data/footersections.json";
import FooterColumn from "./FooterColumn";

export default {
  name: "FooterLinks",
  components: {
    FooterColumn,
  },
  data: () => ({
    cols: null,
  }),
  mounted() {
    this.$nextTick(() => {
      this.setSections();
    });
  },
  methods: {
    setSections() {
      const cols = footerSections;
      this.cols = cols;
    },
  },
};
</script>
<style>
.koko-footer__columns {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
}
</style>
