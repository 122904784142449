<template>
  <v-row justify="center" class="pb-6">
    <v-col cols="12" class="px-1 py-0">
      <KokoBreadcrumbs :items="items" class="pt-2" />
    </v-col>
    <v-col cols="12" class="text-center pt-0">
      <h2 class="text-h5 pb-4 text-center">
        {{ $t("private.peoplefinder.editprofile") }}
      </h2>
      <label class="cursor--pointer" for="koko-profile__update--image">
        <UserAvatar size="150" class="my-6 mx-auto" />
      </label>
    </v-col>
    <v-col cols="11" sm="6" lg="4">
      <v-file-input
        id="koko-profile__update--image"
        class="koko-input--file"
        :rules="imageRules"
        persistent-placeholder
        filled
        height="64"
        accept="image/png, image/jpeg, image/bmp"
        :placeholder="chooseImage"
        append-icon="mdi-camera"
        prepend-icon=""
        :label="imageLabel"
        @change="updateFile"
        :disabled="loadingImage"
      />

      <TextField
        id="koko-profile__update--firstName"
        class="mb-2"
        :value="userFirstName"
        :set-value="setValue"
        :disabled="fieldsDisabled"
        label="private.createaccount.pageTexts.ownerName"
        :rules="[requiredRules]"
      />
      <TextField
        id="koko-profile__update--lastName"
        class="mb-2"
        :value="userLastName"
        :set-value="setValue"
        :disabled="fieldsDisabled"
        label="private.createaccount.pageTexts.ownerSurname"
        :rules="[requiredRules]"
      />
      <TextField
        id="koko-profile__update--petName"
        class="mb-2"
        :value="userPetName"
        :set-value="setValue"
        :disabled="fieldsDisabled"
        label="private.createaccount.pageTexts.petname"
        :rules="[requiredRules]"
      />
    </v-col>
    <v-col cols="12" class="pt-0 text-center">
      <v-btn
        :loading="loading"
        :disabled="disabled"
        color="primary"
        large
        depressed
        @click="updateUserProfile"
        id="koko-profile__update--btn"
      >
        {{ $t("private.pageeditaccount.update") }}
      </v-btn>
    </v-col>
    <v-col
      v-if="error"
      cols="12"
      md="4"
      class="mt-1 text-center"
      id="koko-profile__update--error"
    >
      <p
        v-for="(error, e) in errors"
        :key="`error_${e}`"
        class="mb-1 text-caption red--text line--1-2"
      >
        {{ $t(error) }}
      </p>
    </v-col>
  </v-row>
</template>

<script>
import api from "@/services";
import { mapGetters } from "vuex";
import { formRulesMixin } from "@/mixins/formRulesMixin";
import TextField from "@/components/form/TextField";
import UserAvatar from "@/components/avatar/UserAvatar";
import KokoBreadcrumbs from "@/components/breadcrumbs/KokoBreadcrumbs";

export default {
  name: "UpdateProfile",
  components: {
    TextField,
    UserAvatar,
    KokoBreadcrumbs,
  },
  mixins: [formRulesMixin],
  data() {
    return {
      userImageAvatar: null,
      updatedImage: false,
      commonID: "koko-profile__update--",
      demoKit: "KGEUNAAAAAA1",
      file: null,
      formRef: "passwordForm",
      loading: false,
      disabled: false,
      fieldsDisabled: false,
      error: false,
      passwordMatch: false,
      errors: [],
      firstName: "",
      lastName: "",
      barcode: "",
      petName: "",
      values: {},
      token: "",
      imageRules: [
        (value) =>
          !value ||
          value.size < 5000000 ||
          "Avatar size should be less than 5 MB!",
      ],
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.setDefaultValues();
    });
  },

  computed: {
    ...mapGetters([
      "getToken",
      "getUserKitData",
      "getUserData",
      "getLoginWithToken",
      "getImageAvatar",
    ]),

    imageLabel() {
      return this.$i18n.t("private.common.photo");
    },
    chooseImage() {
      return this.$i18n.t("private.common.choose-image");
    },

    imageExists() {
      const {
        getUserKitData: {
          user: { profilePic },
        },
      } = this;

      if (!profilePic) {
        return false;
      }
      return profilePic;
    },

    userAccountId() {
      const {
        getUserKitData: {
          user: { account_id },
        },
      } = this;
      return Number(account_id);
    },

    userFirstName() {
      const {
        getUserData: { firstName },
      } = this;
      return firstName;
    },

    userLastName() {
      const {
        getUserData: { lastName },
      } = this;
      return lastName;
    },

    userPetName() {
      const {
        getUserKitData: {
          user: { petName },
        },
      } = this;
      return petName;
    },

    defaultError() {
      return this.$i18n.t("private.user.validate-change-email.error");
    },

    afterErrorsMsg() {
      return this.$i18n.t("private.common.rawData.error.msg2");
    },
    items() {
      const items = [
        {
          text: this.$i18n.t("private.header.menus.settings"),
          disabled: false,
          to: "/settings",
          id: "koko-breadcrumbs_item--1",
        },
        {
          text: this.$i18n.t("private.peoplefinder.editprofile"),
          disabled: true,
          id: "koko-breadcrumbs_item--2",
        },
      ];
      return items;
    },
    loadingImage() {
      const { getLoginWithToken, loading } = this;
      if (getLoginWithToken) {
        return true;
      }
      return loading;
    },
  },

  methods: {
    updateFile(file) {
      const {
        getUserKitData: {
          user: { profilePic },
        },
      } = this;
      if (!file) {
        this.$store.commit("SET_IMAGE_AVATAR", profilePic);
        return false;
      }
      this.file = file;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.$store.commit("SET_IMAGE_AVATAR", e.target.result);
        this.updatedImage = true;
      };
      reader.readAsDataURL(file);
    },

    setDefaultValues() {
      const {
        getToken,
        getLoginWithToken,
        getImageAvatar,
        userFirstName: firstName,
        userLastName: lastName,
        getUserKitData: {
          user: { kit: barcode, petName },
        },
      } = this;
      this.userImageAvatar = getImageAvatar;
      this.firstName = firstName;
      this.lastName = lastName;
      this.barcode = barcode;
      this.token = getToken;
      this.petName = petName;
      this.values = {
        ...this.getUserData,
        ...{ firstName, lastName },
        ...{ race: 0, room: 0 },
      };
      this.fieldsDisabled = getLoginWithToken;
      this.disabled = getLoginWithToken;
    },

    hasFilledFields() {
      const fields = [this.firstName, this.lastName, this.petName];
      return [...fields].every((i) => Boolean(i));
    },

    setValue(value, id) {
      const { commonID } = this;
      const prop = id.replace(commonID, "");
      this[prop] = value;
      this.disabled = !this.hasFilledFields();
    },

    logout() {
      this.$store.commit("SET_LOGOUT");
      this.$router.push("/login");
    },

    updateUserData() {
      const {
        firstName,
        lastName,
        userAccountId: accountId,
        values: oldValues,
      } = this;
      const values = { ...oldValues, ...{ firstName, lastName } };
      this.$store.commit("SET_USER_DATA", values);
      this.$store.commit("SET_UPDATE_AVATAR");
      return { ...values, ...{ accountId } };
    },

    async updateUserProfile() {
      const { token, barcode, demoKit } = this;
      this.disabled = true;
      this.fieldsDisabled = true;
      this.loading = true;
      const newValues = this.updateUserData();

      /* if (barcode === "BARCODE0009") {
          this.$router.push("profile");
          return;
        } */

      if (barcode === demoKit) {
        this.updatePetName();
        this.uploadImage();
        return false;
      }

      try {
        const response = await api.updateUserProfile(barcode, newValues, token);
        const { status } = response;
        if (status === 200) {
          this.updatePetName();
          this.uploadImage();
        }
        if (status === 401) {
          this.logout();
        }
      } catch (e) {
        if (e.response.status === 401) {
          this.logout();
        }
        this.errors.push(this.defaultError);
        this.errors.push(e.response.data.message);
        this.error = true;
        this.loading = false;
        this.disabled = false;
        this.fieldsDisabled = false;
      }
      return true;
    },

    updateUserKitData(petName) {
      const oldValues = this.getUserKitData;
      const oldUserValues = this.getUserKitData.user;
      const newUserValues = { ...oldUserValues, ...{ petName } };
      const newValues = { ...oldValues, ...{ user: { ...newUserValues } } };
      this.$store.commit("SET_USER_KIT_DATA", newValues);
    },

    async updatePetName() {
      const { token, barcode, petName, demoKit } = this;
      this.updateUserKitData(petName);
      if (barcode === demoKit) {
        return false;
      }
      try {
        const response = await api.updatePetName(barcode, petName, token);
        const { status } = response;
        if (status === 403) {
          this.logout();
        }
      } catch (e) {
        if (e.response.status === 403) {
          this.logout();
        }
        this.errors.push(this.defaultError);
        this.errors.push(e.response.data.message);
        this.error = true;
        this.loading = false;
        this.disabled = false;
        this.fieldsDisabled = false;
      }
      return true;
    },

    async uploadImage() {
      const { barcode, file, token, demoKit } = this;
      if (!file) {
        this.$router.push("/settings");
        return false;
      }

      if (barcode === demoKit) {
        this.loading = false;
        this.disabled = false;
        this.fieldsDisabled = false;
        return false;
      }

      const fileFile = new File([file], file.name);
      try {
        const response = await api.uploadPic(barcode, fileFile, token);
        const { status } = response;
        if (status === 200) {
          const imageUrl = URL.createObjectURL(file);
          if (imageUrl) {
            this.$store.commit("SET_IMAGE_AVATAR", imageUrl);
          }

          this.$router.push("/settings");
        }
      } catch (error) {
        this.hasError = true;
        this.isCompleted = true;
        this.showErrorMessage(error.response.data.message);
      }
    },
  },
};
</script>

<style>
.koko-profile__figure {
  display: block;
  overflow: hidden;
  width: 100%;
  position: relative;
  height: auto;
}
.koko-profile__image {
  display: block;
  width: 100%;
  position: relative;
  height: auto;
}
</style>
