import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const defaultState = {
  loading: false,
  loginSuccess: false,
  loginError: false,
  loginErrorMessage: null,
  userKitData: null,
  userData: null,
  token: null,
  UILanguage: null,
  healthItems: null,
  healthReports: null,
  imageAvatar: null,
  traitsItems: null,
  breedsItems: null,
  userEmail: null,
  blockedUsers: null,
  readingMessages: null,
  familyTree: null,
  loginWithToken: null,
  avatarUpdated: false,
  userCountry: null,
  userCountryState: null,
  statesList: null,
  loadedlanguages: null,
  sampleType: null,
};
const mutations = {
  SET_TOKEN(state, payload) {
    state.token = payload;
  },
  SET_LOGIN_SUCCESS(state, payload) {
    state.loading = false;
    state.loginSuccess = payload;
  },
  SET_LOGOUT(state) {
    state.loading = false;
    state.loginSuccess = false;
    state.loginError = false;
    state.loginErrorMessage = null;
    state.userKitData = null;
    state.userData = null;
    state.token = null;
    state.UILanguage = null;
    state.healthItems = null;
    state.healthReports = null;
    state.imageAvatar = null;
    state.traitsItems = null;
    state.breedsItems = null;
    state.userEmail = null;
    state.blockedUsers = null;
    state.readingMessages = null;
    state.familyTree = null;
    state.loginWithToken = null;
    state.avatarUpdated = false;
    state.userCountry = null;
    state.userCountryState = null;
    state.statesList = null;
    state.loadedlanguages = null;
    state.sampleType = null;
  },
  SET_USER_KIT_DATA(state, payload) {
    state.userKitData = payload;
  },
  SET_USER_DATA(state, payload) {
    state.userData = payload;
  },
  SET_UI_LANGUAGE(state, payload) {
    state.UILanguage = payload;
  },
  SET_HEALTH_ITEMS(state, payload) {
    state.healthItems = payload;
  },
  SET_TRAITS_ITEMS(state, payload) {
    state.traitsItems = payload;
  },
  SET_HEALTH_REPORTS(state, payload) {
    state.healthReports = payload;
  },
  SET_IMAGE_AVATAR(state, payload) {
    state.imageAvatar = payload;
  },
  SET_UPDATE_AVATAR(state) {
    state.avatarUpdated = true;
  },
  SET_BREEDS_ITEMS(state, payload) {
    state.breedsItems = payload;
  },
  SET_EMAIL(state, payload) {
    state.userEmail = payload;
  },
  SET_BLOCKED_USERS(state, payload) {
    state.blockedUsers = payload;
  },
  SET_READING_MESSAGES(state, payload) {
    state.readingMessages = payload;
  },
  SET_FAMILY_TREE(state, payload) {
    state.familyTree = payload;
  },
  SET_LOGIN_WITH_TOKEN(state) {
    state.loginWithToken = true;
  },
  SET_USER_COUNTRY(state, payload) {
    state.userCountry = payload;
  },
  SET_USER_COUNTRY_STATE(state, payload) {
    state.userCountryState = payload;
  },
  SET_STATES_LIST(state, payload) {
    state.statesList = payload;
  },
  SET_LOADED_LANGUAGES(state) {
    state.loadedlanguages = true;
  },
  SET_SAMPLE_TYPE(state, payload) {
    state.sampleType = payload.sampleType;
  },
};

const actions = {
  updateLanguages({ commit }) {
    commit("SET_LOADED_LANGUAGES");
  },

  changeLanguage({ commit }, { userLanguage }) {
    const userKitData = this.getters.getUserKitData;
    if (userKitData) {
      userKitData.user["defaultLanguage"] = userLanguage;
      const newUserKitdata = { ...userKitData, defaultLanguage: userLanguage };
      commit("SET_USER_KIT_DATA", newUserKitdata);
      return true;
    }
    commit("SET_UI_LANGUAGE", userLanguage);
  },
  setSampleType({ commit }, { sampleType }) {
    commit("SET_SAMPLE_TYPE", { sampleType });
  },
};
const getters = {
  isLoggedIn: (state) => state.loginSuccess,
  hasLoginErrored: (state) => state.loginError,
  getUserEmail: (state) => state.userEmail,
  isLoading: (state) => state.loading,
  getUserAccountId: (state) => state.userAccountId,
  getToken: (state) => state.token,
  hasResults: (state) => state.hasResults,
  getUILanguage: (state) => state.UILanguage,
  getUserKitData: (state) => state.userKitData,
  getUserData: (state) => state.userData,
  getHealthItems: (state) => state.healthItems,
  getTraitsItems: (state) => state.traitsItems,
  getHealthReports: (state) => state.healthReports,
  getImageAvatar: (state) => state.imageAvatar,
  getBreedsItems: (state) => state.breedsItems,
  getBlockedUsers: (state) => state.blockedUsers,
  getReadingMessages: (state) => state.readingMessages,
  getFamilyTree: (state) => state.familyTree,
  getLoginWithToken: (state) => state.loginWithToken,
  getAvatarUpdated: (state) => state.avatarUpdated,
  getUserCountry: (state) => state.userCountry,
  getUserCountryState: (state) => state.userCountryState,
  getStatesList: (state) => state.statesList,
  getLoadedlanguages: (state) => state.loadedlanguages,
  getSampleType: (state) => state.sampleType,
};

export default new Vuex.Store({
  state: defaultState,
  getters,
  mutations,
  actions,
  modules: {},
  plugins: [createPersistedState()],
});
