<template>
  <aside :id="id">
    <p
      v-if="showLabel"
      class="text-right mb-0 text-caption text--secondary pr-1"
    >
      {{ languagesLabel }}
    </p>

    <v-select
      :label="selectName"
      filled
      :items="items"
      item-text="name"
      item-value="value"
      return-object
      single-line
      hide-details
      :dense="dense"
      @change="onChangeLanguage"
    />
  </aside>
</template>

<script>
import { setI18nLanguage } from "@/lang";
import languagesList from "./languagesList.json";
import { mapGetters } from "vuex";
export default {
  name: "LanguageSelector",
  props: {
    id: {
      type: String,
      default: "koko-header__select--language",
    },
    dense: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
      default: "",
    },
    setValue: {
      type: Function,
      default: () => null,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: "koko-form__textfield",
    },
    label: {
      type: String,
      default: "",
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    counter: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    errorValue: {
      type: Boolean,
      default: false,
    },
    errorMsg: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    defaultLocale: "es",
    languages: [],
    select: {},
    selectName: "",
  }),
  computed: {
    ...mapGetters(["getUserKitData", "getUILanguage"]),

    languagesLabel() {
      return this.$i18n.t("private.component.label.language.text");
    },

    items() {
      const items = Object.entries(languagesList).map(([value, name]) => ({
        name,
        value,
      }));
      return this.sortItems(items);
    },

    defaultLanguage() {
      const { locale } = this.$i18n;
      const name = this.$i18n.t(
        `private.component.combo.language.text.${locale}`
      );
      return { name, value: locale };
    },
  },
  created() {
    const defaultLanguage = this.getUserKitData?.user?.defaultLanguage;
    const lang = defaultLanguage || navigator.language.split("-")[0];
    this.$i18n.locale = lang;
    this.$store.commit("SET_UI_LANGUAGE", lang);
  },

  mounted() {
    this.$nextTick(() => {
      this.getLanguageLabel();
    });
  },

  watch: {
    getUILanguage() {
      this.getLanguageLabel();
    },
  },

  methods: {
    checkLanguageExists(lang) {
      return Boolean(languagesList[lang]);
    },

    getLanguageLabel() {
      const {
        defaultLanguage: { value },
      } = this;
      const { locale } = this.$i18n;
      const {
        query: { lang },
      } = this.$route;

      const languages = [lang, value, locale];
      const validlanguage = [
        ...new Set(
          languages.reduce(
            (i, e) => [...i, this.checkLanguageExists(e) ? e : null],
            []
          )
        ),
      ].find((e) => e);
      this.setLanguagelabel(validlanguage);
    },

    setLanguagelabel(language) {
      this.selectName = languagesList[language];
      this.$i18n.locale = language;
      this.$store.commit("SET_UI_LANGUAGE", language);
    },

    sortItems(items) {
      const orderedLangs = Object.keys(languagesList);
      return items.sort((a, b) => {
        return orderedLangs.indexOf(a.value) - orderedLangs.indexOf(b.value);
      });
    },

    onChangeLanguage(lang) {
      const { value } = lang;
      setI18nLanguage(value);
      this.$store.commit("SET_UI_LANGUAGE", value);
      this.$store.dispatch("changeLanguage", { userLanguage: value });
      this.$vuetify.lang.current = value;
      if (this.setValue) {
        this.setValue(value);
      }
    },
  },
};
</script>
