import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { i18n, loadLanguageAsync } from "./lang";
import vuetify from "./plugins/vuetify";

import Donut from "vue-css-donut-chart";
import "vue-css-donut-chart/dist/vcdonut.css";

Vue.use(Donut);

Vue.config.productionTip = false;

Vue.prototype.$languagesToLoad = [
  "en",
  "es",
  "de",
  "pt",
  "it",
  "fr",
  "nl",
  "pl",
  "sv",
  "fi",
];

Vue.prototype.$languagesToLoad.forEach((lang) => {
  loadLanguageAsync(lang);
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
