<template>
  <v-container class="pb-10">
    <section>
      <h1 class="text-h5 text-center mb-6">
        {{ $t("private.welcome.welcome") }}
      </h1>
      <v-row justify="center">
        <v-col cols="12" sm="9" md="8" lg="6">
          <StepsComponent :steps="steps" step="1" />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="11" sm="9" md="8" lg="6" class="pb-6">
          <h4
            class="text-subtitle-1 text-center text-wrap--balance mb-2 line--1-2 pt-2"
          >
            {{ $t("private.welcome.introduction") }}
          </h4>
          <SampleSelector :onSelectSample="onSelectSample" />
        </v-col>
      </v-row>
      <KitInstructions :instructions="instructions" />
      <v-row justify="center">
        <v-col cols="11" sm="9" md="6">
          <h4 class="text-subtitle-1 line--1-2">
            {{ $t(`${pageKey}.doubts`) }}
          </h4>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="11" sm="9" md="6">
          <SupportComponent />
        </v-col>
      </v-row>
      <v-row justify="center" v-if="instructions">
        <v-col cols="11" sm="9" md="6" class="text-center">
          <v-btn
            large
            to="/new-account/welcome"
            class="ma-4"
            color="primary"
            dark
            depressed
            id="koko-welcome__step1--next"
          >
            {{ $t("private.createaccount.pageTexts.next") }}
          </v-btn>
        </v-col>
      </v-row>
    </section>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import StepsComponent from "@/components/steps/StepsComponent";
import SampleSelector from "@/components/selectors/SampleSelector";
import SupportComponent from "@/components/support/SupportComponent";
import KitInstructions from "@/components/instructions/KitInstructions";

export default {
  name: "WelcomeComponent",
  components: {
    SampleSelector,
    StepsComponent,
    SupportComponent,
    KitInstructions,
  },
  data: () => ({
    sampleType: null,
    instructions: null,
    pageKey: "private.welcome.common",
    data: null,
    loading: true,
    kitReceived: false,
    hasResults: false,
    stepsKeys: ["salivacollection", "registerkit", "sendsample"],
    swabInstructions: {
      msgKey: "private.welcome.common.instruction",
      stepsLength: 10,
      svgKey: "swab",
      cautions: [
        "private.welcome.common.caution.beforetakesample",
        "private.welcome.common.caution.salivastimulation",
        "private.welcome.common.caution.donttouch",
        "private.welcome.common.caution.aftertakesample",
        "private.welcome.common.caution.donteat",
      ],
    },
    rapiDriSwabInstructions: {
      alertKey: "private.welcome.common.instructions",
      msgKey: "private.welcome.driswab.instruction",
      stepsLength: 7,
      svgKey: "iso-",
      cautions: [
        "private.welcome.driswab.caution.beforetakesample",
        "private.welcome.driswab.caution.salivastimulation",
        "private.welcome.common.caution.donttouch",
        "private.welcome.driswab.caution.aftertakesample",
        "private.welcome.driswab.caution.donteat",
      ],
    },
  }),
  computed: {
    ...mapGetters(["getSampleType"]),

    localeLang() {
      const { locale } = this.$i18n;
      return locale;
    },

    steps() {
      const { stepsKeys, pageKey } = this;
      const processKey = `${pageKey}.proccess`;
      return stepsKeys.map((e) => ({ msg: `${processKey}.${e}` }));
    },
  },
  mounted() {
    this.$nextTick(() => {
      const { getSampleType } = this;
      if (getSampleType) {
        this.onSelectSample(getSampleType);
        return false;
      }
      this.logout();
      this.$store.commit("SET_UI_LANGUAGE", this.localeLang);
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    });
  },

  watch: {
    getSampleType(newValue) {
      if (newValue) {
        this.onSelectSample(newValue);
      }
    },
  },

  methods: {
    logout() {
      this.$store.commit("SET_LOGOUT");
    },

    onSelectSample(sampleType) {
      const { pageKey } = this;
      const instructions = this[`${sampleType}Instructions`];
      this.instructions = { pageKey, ...instructions };
    },
  },
};
</script>
