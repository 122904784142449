<template>
  <v-row
    justify="center"
    class="pb-8 pt-md-8 px-6 px-md-0"
    ref="footerAdresses"
  >
    <v-col cols="12" v-if="hasItems" sm="6" class="pa-0">
      <FooterDetails :item="item1" />
      <FooterDetails :item="item3" />
    </v-col>
    <v-col cols="12" v-if="hasItems" sm="6" class="pa-0">
      <FooterDetails :item="item2" />
      <FooterDetails :item="item4" />
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
import distributors from "@/data/distributors.json";
import FooterDetails from "@/components/footer/FooterDetails";

export default {
  name: "FooterAdresses",
  components: {
    FooterDetails,
  },

  data: () => ({
    observer: null,
    element: null,
    hasItems: false,
    item1: null,
    item2: null,
    item3: null,
    item4: null,
    commonID: "koko-footer__address--",
  }),

  computed: {
    ...mapGetters(["getUILanguage"]),
  },

  watch: {
    getUILanguage() {
      this.setList();
    },
  },
  mounted() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.setList();
        }
      });
    });
    const element = this.$refs.footerAdresses;
    if (element) {
      observer.observe(element);
      this.observer = observer;
      this.element = element;
    }
  },

  beforeDestroy() {
    const { observer, element } = this;
    if (observer && element) {
      observer.unobserve(element);
    }
  },

  methods: {
    setList() {
      const continents = [...distributors].map((continent) =>
        this.sortCountriesByName(continent)
      );
      const items = continents.forEach((continent, index) => {
        this[`item${index + 1}`] = continent;
      });
      this.hasItems = true;
      return items;
    },

    sortCountriesByName(continent) {
      const { countries, name } = continent;
      const sortedByName = countries
        .reduce(
          (i, country) => [
            ...i,
            {
              name: this.getName(country.name),
              data: this.getData(country),
            },
          ],
          []
        )
        .sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
      return {
        name: this.getName(name),
        countries: sortedByName,
      };
    },

    getData(country) {
      const { data } = country;
      return data.reduce(
        (i, e) => [...i, { name: this.getName(e.name), href: e.href }],
        []
      );
    },

    getName(name) {
      return this.$i18n.te(name) ? this.$i18n.t(name) : name;
    },
  },
};
</script>
<style>
details summary::-webkit-details-marker {
  display: none;
}
</style>
