<template>
  <v-select
    :value="value"
    :items="items"
    :class="className"
    :loading="loading"
    :disabled="loading"
    :rules="rules"
    :label="translatedLabel"
    :hint="hint"
    :persistent-hint="persistentHint"
    dense
    filled
    return-object
    @change="setValue($event)"
  />
</template>
<script>
export default {
  name: "SelectField",
  props: {
    setValue: {
      type: Function,
      default: () => null,
    },
    hint: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    className: {
      type: String,
      default: "koko-form__select",
    },
    label: {
      type: String,
      default: "private.createaccount.pageTexts.state",
    },
    value: {
      type: Object,
      default: () => null,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    persistentHint() {
      return Boolean(this.hint);
    },
    translatedLabel() {
      return this.$i18n.t(this.label);
    },
  },
};
</script>
