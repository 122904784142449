<template>
  <svg style="display: none">
    <symbol id="pinterest" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M9.04 21.54c.96.29 1.93.46 2.96.46a10 10 0 0 0 10-10A10 10 0 0 0 12 2 10 10 0 0 0 2 12c0 4.25 2.67 7.9 6.44 9.34-.09-.78-.18-2.07 0-2.96l1.15-4.94s-.29-.58-.29-1.5c0-1.38.86-2.41 1.84-2.41.86 0 1.26.63 1.26 1.44 0 .86-.57 2.09-.86 3.27-.17.98.52 1.84 1.52 1.84 1.78 0 3.16-1.9 3.16-4.58 0-2.4-1.72-4.04-4.19-4.04-2.82 0-4.48 2.1-4.48 4.31 0 .86.28 1.73.74 2.3.09.06.09.14.06.29l-.29 1.09c0 .17-.11.23-.28.11-1.28-.56-2.02-2.38-2.02-3.85 0-3.16 2.24-6.03 6.56-6.03 3.44 0 6.12 2.47 6.12 5.75 0 3.44-2.13 6.2-5.18 6.2-.97 0-1.92-.52-2.26-1.13l-.67 2.37c-.23.86-.86 2.01-1.29 2.7v-.03Z"
      />
    </symbol>
    <symbol id="twitter" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="m2.882 3.862 7.075 9.46-7.12 7.692H4.44l6.233-6.734 5.037 6.734h5.453l-7.474-9.992 6.628-7.16h-1.603l-5.74 6.202-4.639-6.202H2.882Zm2.356 1.18h2.506l11.062 14.792h-2.505L5.238 5.043Z"
      />
    </symbol>
    <symbol id="instagram" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z"
      />
    </symbol>
    <symbol id="linkedin" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="m6.50495,8.38681l-4.05896,0c-0.18014,0 -0.32611,0.14603 -0.32611,0.32611l0,13.0397c0,0.18014 0.14597,0.32611 0.32611,0.32611l4.05896,0c0.18014,0 0.32611,-0.14597 0.32611,-0.32611l0,-13.0397c0,-0.18008 -0.14597,-0.32611 -0.32611,-0.32611zm-2.02801,-6.48235c-1.47689,0 -2.67841,1.20021 -2.67841,2.67547c0,1.47591 1.20152,2.67658 2.67841,2.67658c1.47571,0 2.67625,-1.20074 2.67625,-2.67658c0.00007,-1.47526 -1.20054,-2.67547 -2.67625,-2.67547zm12.35226,6.15826c-1.63022,0 -2.83533,0.70081 -3.56627,1.49711l0,-0.84691c0,-0.18008 -0.14597,-0.32611 -0.32611,-0.32611l-3.88716,0c-0.18014,0 -0.32611,0.14603 -0.32611,0.32611l0,13.0397c0,0.18014 0.14597,0.32611 0.32611,0.32611l4.05009,0c0.18014,0 0.32611,-0.14597 0.32611,-0.32611l0,-6.45163c0,-2.17404 0.59052,-3.02102 2.10602,-3.02102c1.65051,0 1.78167,1.35779 1.78167,3.13287l0,6.33984c0,0.18014 0.14597,0.32611 0.32611,0.32611l4.05159,0c0.18014,0 0.32611,-0.14597 0.32611,-0.32611l0,-7.1525c0,-3.23273 -0.61641,-6.53746 -5.18815,-6.53746z"
      />
    </symbol>
    <symbol id="facebook" viewBox="0 0 24 24">
      <path
        stroke="null"
        fill="currentColor"
        d="m16.97241,2.05519l0,3.97792l-1.98896,0c-0.68619,0 -0.99448,0.80553 -0.99448,1.49172l0,2.4862l2.98344,0l0,3.97792l-2.98344,0l0,7.95585l-3.97792,0l0,-7.95585l-2.98344,0l0,-3.97792l2.98344,0l0,-3.97792a3.97792,3.97792 0 0 1 3.97792,-3.97792l2.98344,0z"
      />
    </symbol>
  </svg>
</template>
<script>
export default {
  name: "SocialIcons",
};
</script>
<style>
.tmg-c-social__icon {
  width: 20px;
  height: 20px;
  opacity: 0.75;
  mix-blend-mode: multiply;
}
</style>
