<template>
  <v-text-field
    :id="id"
    :value="value"
    :class="className"
    :label="translatedLabel"
    :error="errorValue"
    :error-messages="errorMsg"
    :counter="counter"
    :maxlength="maxlength"
    :disabled="disabled"
    :readonly="readonly"
    :rules="rules"
    :type="type"
    :placeholder="placeholder"
    :hint="hint"
    :persistent-hint="persistentHint"
    persistent-placeholder
    filled
    @input="setValue($event, id)"
  />
</template>
<script>
export default {
  name: "TextField",
  props: {
    id: {
      type: String,
      default: "",
    },
    hint: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    setValue: {
      type: Function,
      default: () => null,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: "koko-form__textfield",
    },
    label: {
      type: String,
      default: "",
    },
    maxlength: {
      type: String,
      default: "",
    },
    counter: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    errorValue: {
      type: Boolean,
      default: false,
    },
    errorMsg: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    persistentHint() {
      return Boolean(this.hint);
    },
    translatedLabel() {
      return this.$i18n.t(this.label);
    },
  },
};
</script>
