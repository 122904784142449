import { mapGetters } from "vuex";

export const avatarMixin = {
  computed: {
    ...mapGetters(["getUserData", "getUserKitData", "getImageAvatar"]),
    values() {
      const { size, noShadow, imageExists, initials, image } = this;
      return { size, noShadow, imageExists, initials, image };
    },

    initials() {
      const {
        getUserKitData: {
          user: { petName },
        },
      } = this;
      const petNameFixed = petName ?? "P";
      return petNameFixed.split("")[0].toUpperCase();
    },

    image() {
      const { getImageAvatar, getUserKitData } = this;

      if (getImageAvatar) {
        return getImageAvatar;
      }
      if (getUserKitData) {
        return getUserKitData.user.profilePic;
      }
      return "";
    },

    imageExists() {
      const {
        getImageAvatar,
        getUserKitData: {
          user: { profilePic },
        },
      } = this;
      return Boolean(getImageAvatar) || Boolean(profilePic) || false;
    },
  },
};
