<template>
  <v-container>
    <v-row justify="center" v-if="loadingTree">
      <v-col cols="12" sm="11" md="10" lg="8">
        <p class="text-body-2 text--disabled text-center">
          {{ $t("private.common.loadingText") }}
        </p>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="userTreeExists">
      <v-col cols="12" class="py-8">
        <h2 class="text-h5 text-center">
          {{ $t("private.common.familytree.mainTitle") }}
        </h2>
      </v-col>
    </v-row>
    <v-row justify="center" class="koko-family-tree" v-if="userTreeExists">
      <v-col cols="12" class="px-md-7">
        <section
          class="koko-family-tree__section koko-family-tree__section--user"
          :style="`--parents-height: ${parentsHeight}px; --grandparents-height: ${grandParentsHeight}px`"
        >
          <FamilyTreeItem
            :userValues="values"
            :item="items"
            style="grid-column-start: 1; grid-column-end: 3"
          />

          <h4
            class="koko-family-tree__relations koko-family-tree__relations--parents"
          >
            {{ $t("private.common.parents") }}
          </h4>
          <h4
            class="koko-family-tree__relations koko-family-tree__relations--grandparents"
          >
            {{ $t("private.common.grandparents") }}
          </h4>
          <h4
            class="koko-family-tree__relations koko-family-tree__relations--greatgrandparents"
          >
            {{ $t("private.common.greatgrandparents") }}
          </h4>
          <section
            v-for="(parent, a) in items.parents"
            :key="`parents-${a}`"
            class="koko-family-tree__section koko-family-tree__section--parent"
          >
            <FamilyTreeItem
              :item="parent"
              :inputFor="`parents-${a}`"
              :listMinHeight="parentsListHeight"
            />
            <input
              type="checkbox"
              checked="checked"
              class="koko-family-tree__input"
              :id="`parents-${a}`"
            />
            <section
              v-for="(grandparent, b) in parent.parents"
              :key="`grandparents-${a}-${b}`"
              class="koko-family-tree__section koko-family-tree__section--grandparent"
            >
              <FamilyTreeItem
                :item="grandparent"
                row="3"
                :inputFor="`grandparents-${a}-${b}`"
                :listMinHeight="grandParentsListHeight"
              />
              <input
                type="checkbox"
                checked="checked"
                class="koko-family-tree__input"
                :id="`grandparents-${a}-${b}`"
              />

              <section
                v-for="(greatGrandparent, c) in grandparent.parents"
                :key="`greatGrandparents-${c}`"
                class="koko-family-tree__section koko-family-tree__section--greatgrandparent"
              >
                <FamilyTreeItem :item="greatGrandparent" row="4" />
              </section>
            </section>
          </section>
        </section>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/services";
import { mapGetters } from "vuex";
import { avatarMixin } from "@/mixins/avatarMixin";
import FamilyTreeItem from "./FamilyTreeItem";

export default {
  name: "FamilyTree",
  components: {
    FamilyTreeItem,
  },
  props: {
    profilePic: {
      type: String,
      default: "",
    },
  },
  mixins: [avatarMixin],
  data: () => ({
    loadingTree: true,
    userTreeExists: false,
    items: null,
    parentsListHeight: 0,
    parentsHeight: 0,
    grandParentsListHeight: 0,
    grandParentsHeight: 280,
  }),
  created() {
    this.getUserDNATree();
  },

  computed: {
    ...mapGetters(["getToken", "getFamilyTree"]),
  },

  methods: {
    async getUserDNATree() {
      const { getFamilyTree, getToken: token } = this;

      if (getFamilyTree) {
        this.setFamilyTree(getFamilyTree);
        return false;
      }

      const {
        user: { kit: barcode },
      } = this.getUserKitData;

      try {
        const response = await api.getDNATree({ barcode, token });
        const {
          status,
          data: { results: items },
        } = response;

        if (status === 200) {
          this.userTreeExists = true;
          this.loadingTree = false;
          this.items = items;
          const { parents } = items;
          this.getParentsBreedsLength(parents);
          this.getGrandParentsBreedsLength(parents);
          this.$store.commit("SET_FAMILY_TREE", { items });
        }
      } catch (e) {
        this.loadingTree = false;
        this.$store.commit("SET_FAMILY_TREE", { items: null });
        console.log(e);
      }
    },

    setFamilyTree(familyTree) {
      this.loadingTree = false;
      const { items } = familyTree;
      if (!items) {
        return false;
      }
      this.items = items;
      const { parents } = items;
      this.getParentsBreedsLength(parents);
      this.getGrandParentsBreedsLength(parents);
      this.userTreeExists = true;
    },

    getBreedsHeight(parents) {
      const PARAGRAPH_LINE_HEIGHT = 22;
      const breedsLengths = Object.entries(parents).reduce(
        (i, e) => [...i, e[1].breeds.length],
        []
      );
      return Math.max(...breedsLengths) * PARAGRAPH_LINE_HEIGHT;
    },

    getParentsBreedsLength(parents) {
      const PARENTS_ROW_HEIGHT = 180;
      const listHeight = this.getBreedsHeight(parents);
      this.parentsListHeight = listHeight;
      this.parentsHeight = listHeight + PARENTS_ROW_HEIGHT;
    },

    getGrandParentsBreedsLength(parents) {
      const GRANDPARENTS_ROW_HEIGHT = 170;
      const grandParents = [...Object.entries(parents)].reduce(
        (i, e) => [...i, ...e[1].parents],
        []
      );

      const listHeight = this.getBreedsHeight(grandParents);
      this.grandParentsListHeight = listHeight;
      this.grandParentsHeight = listHeight + GRANDPARENTS_ROW_HEIGHT;
    },

    getName(popcode) {
      return this.$i18n.t(`item.breed.${popcode}.name`);
    },
  },
};
</script>
