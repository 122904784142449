<template>
  <v-container class="d-flex d-md-none px-2">
    <v-row>
      <v-col cols="6">
        <KokoLogo class="mt-3" />
      </v-col>
      <v-col cols="6" class="d-flex justify-end">
        <aside
          class="koko-header__mobile-btn--open"
          @click.stop="toggleShowed()"
        >
          <v-icon class="mr-3">mdi-menu</v-icon>
          <UserAvatar />
        </aside>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import KokoLogo from "@/components/logo/KokoLogo";
import UserAvatar from "@/components/avatar/UserAvatar";

export default {
  name: "MobileHeader",

  components: {
    KokoLogo,
    UserAvatar,
  },
  props: {
    toggleShowed: {
      type: Function,
      default: () => null,
    },
  },
};
</script>
