<template>
  <HomeComponent />
</template>

<script>
import HomeComponent from "@/components/home/HomeComponent";

export default {
  name: "HomeView",
  components: {
    HomeComponent,
  },
};
</script>
