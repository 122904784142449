<template>
  <v-row justify="center">
    <v-col cols="10" md="6 pt-6">
      <SettingsAccountCard />
      <SettingsProfileCard />
      <SettingsDNAConnectCard />
      <SettingsDownloadRawData />
      <SettingsLanguageCard />
    </v-col>
  </v-row>
</template>
<script>
import SettingsAccountCard from "@/components/settings/SettingsAccountCard";
import SettingsProfileCard from "@/components/settings/SettingsProfileCard";
import SettingsLanguageCard from "@/components/settings/SettingsLanguageCard";
import SettingsDNAConnectCard from "@/components/settings/SettingsDNAConnectCard";
import SettingsDownloadRawData from "@/components/settings/SettingsDownloadRawData";

export default {
  name: "SettingsCardList",
  components: {
    SettingsAccountCard,
    SettingsProfileCard,
    SettingsLanguageCard,
    SettingsDNAConnectCard,
    SettingsDownloadRawData,
  },
};
</script>
<style>
.koko-settings__card {
  filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.3));
}
.v-sheet.v-card.koko-settings__card {
  border-radius: 5px;
  box-shadow: 0 1.5px 3px rgba(0, 0, 0, 0.1);
}
</style>
