<template>
  <v-row class="px-4 pt-6 pb-md-6" justify="end">
    <v-col cols="12" md="3" class="koko-footer__logo-container">
      <KokoLogo grayscale class="px-2" />
    </v-col>
    <v-col cols="12" md="9" class="koko-footer__message-container">
      <p class="koko-footer__message">
        {{ $t("private.pagehome.footer.text") }}
      </p>
      <p class="koko-footer__message text-right pt-2 pr-6">
        {{ $t("private.pagehome.footer.team") }}
      </p>
    </v-col>
  </v-row>
</template>
<script>
import KokoLogo from "@/components/logo/KokoLogo";

export default {
  name: "FooterMessage",
  components: {
    KokoLogo,
  },
};
</script>
