<template>
  <v-text-field
    :id="id"
    :value="value"
    :class="className"
    :label="translatedLabel"
    :placeholder="placeholder"
    filled
    @input="setValue($event, id)"
  />
</template>
<script>
export default {
  name: "TextFieldOptional",
  props: {
    value: {
      type: String,
      default: "",
    },
    hint: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    setValue: {
      type: Function,
      default: () => null,
    },
    className: {
      type: String,
      default: "koko-form__textfield",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    persistentHint() {
      return Boolean(this.hint);
    },
    translatedLabel() {
      const { label } = this;
      const translatedText = this.$t(label);
      return `* ${translatedText}`;
    },
  },
};
</script>
