<template>
  <v-row justify="center">
    <v-col cols="10" sm="6" md="5" lg="4">
      <div class="bloque-formulario-acceso bloque-formulario-login">
        <h1>{{ $t("private.pageresendactivate.title") }}</h1>
        <div v-if="error">
          <br />
          <p
            v-for="(err, index) in errors"
            :key="index"
            style="color: red"
            v-html="$t(err)"
          />
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import api from "@/services";
import { mapGetters } from "vuex";

export default {
  name: "ReActivateAccount",
  data() {
    return {
      error: false,
      errors: [],
    };
  },
  computed: {
    ...mapGetters(["getUserEmail"]),
  },
  created() {
    api
      .resendActivationEmailTo(this.getUserEmail)
      .then(() => {
        console.log("Re-send activation email");
      })
      .catch((error) => {
        this.errors.push(error);
        this.error = true;
      });
  },
};
</script>
