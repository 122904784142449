<template>
  <v-container>
    <KinshipProfile />
  </v-container>
</template>

<script>
import KinshipProfile from "@/components/profile/KinshipProfile";

export default {
  name: "KinshipProfileView.",
  components: { KinshipProfile },
};
</script>
