<template>
  <aside class="results-steps">
    <h2 class="text-h5 text-center pt-0 mb-8">
      {{ $t(`private.pagehome.noResults.title`) }}
    </h2>
    <ul :class="getListClass">
      <li :class="getClass">
        {{ getText("title1") }}

        <p class="results-steps__caption">
          {{ getText("caption1") }}
        </p>
      </li>
      <li v-if="!hasKitReceived" :class="getClass">
        {{ getText("title2") }}
        <p class="results-steps__caption">
          {{ getText("caption2") }}
        </p>
      </li>
      <li v-if="hasKitReceived" :class="getClass">
        <i18n tag="span" :path="sampleReceivedDateTitlePath">
          <template #date>
            <b>{{ kitReceivedDate }}</b>
          </template>
        </i18n>
        <i18n
          tag="p"
          class="results-steps__caption"
          :path="sampleReceivedDateMsgPath"
        >
          <template #start>
            <b>{{ dateStartReceived }}</b>
          </template>
          <template #end>
            <b>{{ dateFinishReceived }}</b>
          </template>
        </i18n>
      </li>
      <li :class="callRateItemClass">
        {{ $t(message) }}
      </li>
    </ul>
    <article v-if="lowCallRate" class="text-sm-center" v-html="htmlText" />
  </aside>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "ResultsSteps",
  props: {
    values: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      sampleReceivedDateTitlePath: "private.pagehome.sampleReceived.date.title",
      sampleReceivedDateMsgPath: "private.pagehome.sampleReceived.date.msg",
    };
  },

  computed: {
    ...mapGetters(["getUserKitData"]),

    hasKitReceived() {
      const {
        getUserKitData: {
          user: { kitReceived },
        },
      } = this;
      return Boolean(JSON.parse(kitReceived));
    },

    kitReceivedDate() {
      const {
        getUserKitData: {
          user: { kitReceivedDate },
        },
      } = this;
      return this.getLocaleDate(kitReceivedDate);
    },

    dateFinishReceived() {
      const {
        getUserKitData: {
          user: { date_finish_received },
        },
      } = this;
      return this.getLocaleDate(date_finish_received);
    },

    dateStartReceived() {
      const {
        getUserKitData: {
          user: { date_start_received },
        },
      } = this;
      return this.getLocaleDate(date_start_received);
    },

    htmlText() {
      const {
        values: { htmlText },
      } = this;
      return htmlText;
    },

    lowCallRate() {
      const {
        values: { lowCallRate },
      } = this;
      return lowCallRate;
    },

    kitReceived() {
      const {
        values: { kitReceived },
      } = this;
      return kitReceived;
    },

    message() {
      const {
        values: { lowCallRate, lowCallRateText },
      } = this;
      const availableText = "private.pagehome.resultsAvailable.title";
      return lowCallRate ? `${lowCallRateText}` : availableText;
    },

    callRateItemClass() {
      const { lowCallRate } = this;
      const defaultItemClass = "results-steps__item";
      return lowCallRate
        ? `${defaultItemClass} ${defaultItemClass}--error`
        : defaultItemClass;
    },

    getClass() {
      const { kitReceived } = this;
      const defClass = "results-steps__item";
      return kitReceived
        ? `${defClass} results-steps__item--visited`
        : defClass;
    },
    getListClass() {
      const { kitReceived: visited, lowCallRate: error } = this;
      const defaultClass = "results-steps__list";
      const styleOptions = { visited, error };
      const aditionalClasses = Object.entries(styleOptions)
        .filter((e) => e[1])
        .reduce((i, a) => [...i, `${defaultClass}--${a[0]}`], []);
      return [defaultClass, ...aditionalClasses].join(" ");
    },
  },
  methods: {
    getLocaleDate(string) {
      const { locale } = this.$i18n;
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return new Date(string).toLocaleDateString(locale, options).trim();
    },

    getText(type) {
      const baseKey = `private.pagehome`;
      const keysList = [
        {
          type: "title1",
          key: "sampleNotReceived.title",
          rawKey: "sampleNotReceived.rawData.title",
        },
        {
          type: "caption1",
          key: "sampleNotReceived.msg1",
          rawKey: "sampleNotReceived.rawData.msg1",
        },
        {
          type: "title2",
          key: "sampleReceived.title",
          rawKey: "sampleReceived.rawData.title",
        },
        {
          type: "caption2",
          key: "sampleReceived.msg1",
          rawKey: "sampleReceived.rawData.msg1",
        },
      ];

      const keyName = "key";
      const keyObj = keysList.find((e) => e.type === type);
      const keyToTranslate = `${baseKey}.${keyObj[keyName]}`;
      return this.$t(keyToTranslate);
    },
  },
};
</script>

<style>
.results-steps {
  --active-color: var(--primary-color);
  --hue: 210;
  --sat: 79%;
  --lum: 50%;
  --error-hue: 0;
  --error-sat: 100%;
  --error-lum: 66%;
  --error-color: hsl(var(--error-hue), var(--error-sat), var(--error-lum));

  background-color: none;
  counter-reset: step;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
  letter-spacing: 0.03em;
}

.results-steps__list {
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  padding: 0;
  display: grid;
  position: relative;
  grid-template-columns: repeat(auto-fit, minmax(6em, 1fr));
  grid-auto-columns: 1fr;
  grid-gap: 3rem;
}

.results-steps__item {
  text-align: center;
  padding: 3.5rem 0 2rem;
  font-size: 0.9em;
  line-height: 1.15;
  hyphens: none;
  position: relative;
  color: hsla(240, 40%, 85%);
  letter-spacing: 0;
}

.results-steps__item b {
  font-weight: 400;
}

.results-steps__caption {
  font-size: 0.8em;
  margin: 0 auto;
  display: block;
  width: 39ch;
  max-width: 100%;
  line-height: 1.2;
  letter-spacing: 0.02em;
  padding-top: 0.25em;
  color: hsl(240, 20%, 65%);
}

.results-steps__item::before {
  position: absolute;
  top: 1.3em;
  right: calc(50% + 1.5rem);
  width: 100%;
  height: 0.15em;
  display: block;
  background-color: var(--active-color);
  opacity: 0.3;
  content: "";
}

.results-steps__item::after {
  position: absolute;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1;
  top: 0;
  left: calc(50% - 1em);
  width: 2em;
  height: 2em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  counter-increment: step;
  content: counter(step);
  color: white;
  justify-content: center;
  background-color: hsl(131, 45%, 90%);
  box-shadow: 0 0 0 3px white;
}

.results-steps__item:first-child,
.results-steps__item--visited {
  color: hsl(240, 66%, 15%);
}

.results-steps__item:first-child::after,
.results-steps__item--visited::after,
.results-steps__item--visited::before {
  background-color: var(--active-color);
  color: white;
  opacity: 1;
}

.results-steps__item:first-child::before {
  display: none;
}

@media (max-width: 640px) {
  .results-steps {
    --last-pass-color: hsl(131, 45%, 90%);
  }
  .results-steps__list {
    display: flex;
    flex-direction: column;
    padding-bottom: 3rem;
  }
  .results-steps__list::before {
    height: calc(100% - 6rem);
    width: 0.2rem;
    left: 2.43rem;
    top: 2rem;
    display: block;
    content: "";
    background-color: var(--wait-color);
    position: absolute;
  }
  .results-steps__list--visited::before {
    background-image: linear-gradient(
      var(--active-color) 50%,
      var(--last-pass-color) 50%
    );
  }
  .results-steps__list--error {
    --last-pass-color: var(--error-color);
  }

  .results-steps__item {
    padding: 0.35rem 2rem 0 2.75rem;
    text-align: left;
  }
  .results-steps__item::before {
    display: none;
  }

  .results-steps__item::after {
    font-size: 1rem;
    left: 0;
  }
  .results-steps__caption {
    padding-left: 0.1em;
    margin: 0;
  }
}

.results-steps__item--error::after,
.results-steps__item--error::before {
  background-color: var(--error-color);
  opacity: 1;
  color: white;
}

.results-steps__item--error {
  color: var(--error-color);
}
</style>
