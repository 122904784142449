import Vue from "vue";
import VueI18n from "vue-i18n";
import api from "@/services";

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  silentFallbackWarn: true,
  silentTranslationWarn: true,
});

export const loadedLanguages = [];

export function setI18nLanguage(lang) {
  i18n.locale = lang;
  i18n.fallbackLocale = lang;
  document.querySelector("html").setAttribute("lang", lang);
  return lang;
}

export async function loadLanguageAsync(lang) {
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve();
  }
  const response = await api.getTextsInLanguage(lang);
  const msgs = response.data;
  loadedLanguages.push(lang);
  i18n.setLocaleMessage(lang, msgs);
  i18n.mergeLocaleMessage(lang, msgs);
  api.getItemTextsInLanguage(lang).then((itemResponse) => {
    const itemsMsgs = itemResponse.data;
    i18n.mergeLocaleMessage(lang, itemsMsgs);
  });
}
