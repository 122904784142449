<template>
  <section>
    <v-card class="mb-6 koko-settings__card">
      <v-card-title class="text-subtitle-1 px-5 pb-0 pt-3">
        DNA Connect
      </v-card-title>

      <v-card-text class="px-4 pt-1 pb-4">
        <label :class="labelClass">
          <input
            type="checkbox"
            name="finder"
            :disabled="disabledCheckbox"
            v-model="checkDNAConnect"
            @change="toggleAllowDNAConnect"
          />
          <span class="text-body-2 line--1-2">{{
            $t("private.form.acceptdnaconncet")
          }}</span>
        </label>
      </v-card-text>
    </v-card>
    <DNAConnectDialog
      :show="showDialogConnect"
      :confirm="setDNAConnect"
      :cancel="cancelDialog"
    />
    <DNAConnectDismissDialog
      :show="showDismissDialog"
      :confirm="setDNAConnect"
      :cancel="cancelDismissDialog"
    />
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import { dnaConnectMixin } from "@/mixins/dnaConnectMixin";
import DNAConnectDialog from "@/components/dnaconnect/DNAConnectDialog";
import DNAConnectDismissDialog from "@/components/dnaconnect/DNAConnectDismissDialog";

export default {
  name: "SettingsDNAConnectCard",
  components: {
    DNAConnectDialog,
    DNAConnectDismissDialog,
  },
  mixins: [dnaConnectMixin],

  data() {
    return {
      checkDNAConnect: false,
      showDialogConnect: false,
      showDismissDialog: false,
    };
  },
  computed: {
    ...mapGetters(["getToken", "getUserKitData", "getLoginWithToken"]),

    disabledCheckbox() {
      return this.getLoginWithToken;
    },

    labelClass() {
      const { getLoginWithToken } = this;
      const defaultClass = "form-label";
      return getLoginWithToken
        ? `${defaultClass} ${defaultClass}--disabled`
        : defaultClass;
    },

    allowDNAConnect() {
      const {
        user: { allowConnectPet },
      } = this.getUserKitData;

      return allowConnectPet;
    },
  },
  created() {
    this.checkDNAConnect = this.allowDNAConnect;
  },

  methods: {
    toggleAllowDNAConnect() {
      const { checkDNAConnect } = this;
      this.showDismissDialog = !checkDNAConnect;
      this.showDialogConnect = checkDNAConnect;
    },

    cancelDialog() {
      this.showDialogConnect = false;
      this.checkDNAConnect = this.allowDNAConnect;
    },
    cancelDismissDialog() {
      this.showDismissDialog = false;
      this.checkDNAConnect = this.allowDNAConnect;
    },

    setDNAConnect(value) {
      this.changeAllowDNAConnect(value);
      this.showDialogConnect = false;
    },
  },
};
</script>
