<template>
  <v-app-bar app absolute flat color="white" height="86">
    <v-container class="pb-0 px-4 px-lg-5 pt-lg-6">
      <v-toolbar flat>
        <v-row align="center" justify="space-between" class="py-0 ma-0s">
          <v-col class="d-flex pl-0 pr-4" cols="4" md="auto">
            <v-toolbar-title>
              <KokoLogo />
            </v-toolbar-title>
          </v-col>
          <v-col class="d-flex justify-end" cols="8" md="3">
            <LanguageSelector />
          </v-col>
        </v-row>
      </v-toolbar>
    </v-container>
  </v-app-bar>
</template>

<script>
import LanguageSelector from "@/components/language/LanguageSelector";
import KokoLogo from "@/components/logo/KokoLogo";

export default {
  name: "SimpleHeader",
  components: {
    LanguageSelector,
    KokoLogo,
  },

  data() {
    return {};
  },
  computed: {
    localeLang() {
      const { locale } = this.$i18n;
      return locale;
    },
  },

  methods: {},
};
</script>
