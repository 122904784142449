<template>
  <a
    :href="itemURL"
    class="koko-footer__section-link"
    target="_blank"
    :id="itemID"
  >
    {{ $t(item.title) }}
  </a>
</template>
<script>
export default {
  name: "FoterLinkItem",
  props: {
    item: {
      type: Object,
      default: () => null,
    },
  },
  data: () => ({
    DOMAIN: `https://www.kokogenetics.com`,
  }),
  computed: {
    hasProp() {
      const { item } = this;
      const externals = ["shop", "blog"];
      return Object.keys(item).find((e) => externals.includes(e));
    },

    itemID() {
      const { pageURL, hasProp } = this;
      const commonId = "koko-footer__link";
      if (hasProp) {
        return `${commonId}--${hasProp}`;
      }
      const specificID = pageURL.split("/").pop().split("-").join("_");
      return `${commonId}--${specificID}`;
    },

    itemURL() {
      const { pageURL, hasProp } = this;
      if (hasProp) {
        return this[`${hasProp}Url`];
      }
      return pageURL;
    },

    pageURL() {
      const { locale } = this.$i18n;
      const {
        DOMAIN,
        item: { urls },
      } = this;
      if (!urls) {
        return null;
      }
      const url = urls[locale] ?? urls["en"];
      const lang = locale === "es" ? "" : `/${locale}`;
      return `${DOMAIN}${lang}${url}`;
    },

    blogUrl() {
      const {
        item: { url, locales },
      } = this;
      const { locale } = this.$i18n;
      const languageDefault = !locales.includes(locale) ? "en" : locale;
      const localeSlug = languageDefault === "es" ? "" : `${languageDefault}/`;
      return `${url}${localeSlug}`;
    },

    shopUrl() {
      const {
        item: { url },
      } = this;
      const { locale } = this.$i18n;
      return `${url}${locale}/`;
    },
  },
  methods: {},
};
</script>
